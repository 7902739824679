import React from 'react';

type VisibilityToggleProps = {
  visible: boolean;
  icon: string;
  alt: string;
  handleVisibilityChange: (checked: boolean) => void;
};

const VisibilityToggle: React.FC<VisibilityToggleProps> = ({
  visible,
  icon,
  alt,
  handleVisibilityChange,
}) => {
  return (
    <div
      onClick={() => handleVisibilityChange(!visible)}
      style={{
        width: '24px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: '4px',
        userSelect: 'none',
      }}
    >
      {visible && (
        <img
          src={`img/icons/${icon}.svg`}
          alt={alt}
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      )}
    </div>
  );
};

export default VisibilityToggle;
