import { Point } from './Point';

export class Vertex {
  constructor(
    public readonly base: Point,
    public readonly controlLeft: Point | null,
    public readonly controlRight: Point | null,
    public readonly linkedHandles: boolean = true,
  ) {}

  clone(): Vertex {
    return new Vertex(
      this.base.clone(),
      this.controlLeft?.clone() ?? null,
      this.controlRight?.clone() ?? null,
      this.linkedHandles,
    );
  }
}
