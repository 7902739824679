// TopBar.tsx

import React, { useState, useEffect, useRef } from 'react';
import { ArtboardViewModel } from '../viewmodel/ArtboardViewModel';

interface TopBarProps {
  artboardViewModel: ArtboardViewModel;
  rightPanelOpen: boolean;
  onExportSVG: () => void;
  onClear: () => void;
  onPlaceImage: () => void;
  onCollapseRightPanel: () => void;
}

const TopBar: React.FC<TopBarProps> = ({
  artboardViewModel,
  rightPanelOpen,
  onExportSVG,
  onClear,
  onPlaceImage,
  onCollapseRightPanel,
}) => {
  const [openMenu, setOpenMenu] = useState<string | null>(null);
  const topBarRef = useRef<HTMLDivElement>(null);

  const handleMenuClick = (menuName: string) => {
    setOpenMenu(openMenu === menuName ? null : menuName);
  };

  const handleMenuItemClick = (action: () => void) => {
    action();
    setOpenMenu(null);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (topBarRef.current && !topBarRef.current.contains(event.target as Node)) {
      setOpenMenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div
      ref={topBarRef}
      style={{
        width: '100%',
        height: '30px',
        background: '#535353',
        borderBottom: '2px solid #2d2d2d',
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 10,
      }}
    >
      <img
        style={{
          height: '30px',
          padding: '5px 8px',
          boxSizing: 'border-box',
        }}
        src="vs-toplogo.svg"
      />

      {/* File Menu */}
      <div style={{ position: 'relative' }}>
        <button
          onClick={() => handleMenuClick('File')}
          style={{
            height: '30px',
            backgroundColor: 'transparent',
            color: 'white',
            border: 'none',
            padding: '5px 10px',
            cursor: 'pointer',
          }}
          onMouseEnter={(e) => {
            (e.target as HTMLButtonElement).style.backgroundColor = '#3d3d3d';
          }}
          onMouseLeave={(e) => {
            (e.target as HTMLButtonElement).style.backgroundColor = 'transparent';
          }}
        >
          File
        </button>
        {openMenu === 'File' && (
          <div
            style={{
              position: 'absolute',
              top: '30px',
              left: 0,
              backgroundColor: '#ffffff',
              border: '1px solid #ccc',
              boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
              width: '150px',
            }}
          >
            <button
              onClick={() => handleMenuItemClick(onExportSVG)}
              style={{
                display: 'block',
                width: '100%',
                padding: '10px 20px',
                backgroundColor: 'transparent',
                border: 'none',
                textAlign: 'left',
                cursor: 'pointer',
              }}
              onMouseEnter={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor = '#f0f0f0';
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor = 'transparent';
              }}
            >
              Export as SVG
            </button>
            <div
              style={{
                borderBottom: '1px solid #ccc',
                margin: '5px 0',
              }}
            ></div>
            <button
              onClick={() => handleMenuItemClick(onPlaceImage)}
              style={{
                display: 'block',
                width: '100%',
                padding: '10px 20px',
                backgroundColor: 'transparent',
                border: 'none',
                textAlign: 'left',
                cursor: 'pointer',
              }}
              onMouseEnter={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor = '#f0f0f0';
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor = 'transparent';
              }}
            >
              Import Image / Place
            </button>
          </div>
        )}
      </div>

      {/* Edit Menu */}
      <div style={{ position: 'relative' }}>
        <button
          onClick={() => handleMenuClick('Edit')}
          style={{
            height: '30px',
            backgroundColor: 'transparent',
            color: 'white',
            border: 'none',
            padding: '5px 10px',
            cursor: 'pointer',
          }}
          onMouseEnter={(e) => {
            (e.target as HTMLButtonElement).style.backgroundColor = '#3d3d3d';
          }}
          onMouseLeave={(e) => {
            (e.target as HTMLButtonElement).style.backgroundColor = 'transparent';
          }}
        >
          Edit
        </button>
        {openMenu === 'Edit' && (
          <div
            style={{
              position: 'absolute',
              top: '30px',
              left: 0,
              backgroundColor: '#ffffff',
              border: '1px solid #ccc',
              boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
              width: '150px',
            }}
          >
            <button
              onClick={() => handleMenuItemClick(artboardViewModel.copySelectedObject)}
              style={{
                display: 'block',
                width: '100%',
                padding: '10px 20px',
                backgroundColor: 'transparent',
                border: 'none',
                textAlign: 'left',
                cursor: 'pointer',
              }}
              onMouseEnter={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor = '#f0f0f0';
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor = 'transparent';
              }}
            >
              Copy
            </button>
            <button
              onClick={() => handleMenuItemClick(artboardViewModel.pasteFromClipboard)}
              style={{
                display: 'block',
                width: '100%',
                padding: '10px 20px',
                backgroundColor: 'transparent',
                border: 'none',
                textAlign: 'left',
                cursor: 'pointer',
              }}
              onMouseEnter={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor = '#f0f0f0';
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor = 'transparent';
              }}
            >
              Paste
            </button>
            <div
              style={{
                borderBottom: '1px solid #ccc',
                margin: '5px 0',
              }}
            ></div>
            <button
              onClick={() =>
                handleMenuItemClick(() => {
                  onClear();
                })
              }
              style={{
                display: 'block',
                width: '100%',
                padding: '10px 20px',
                backgroundColor: 'transparent',
                border: 'none',
                textAlign: 'left',
                cursor: 'pointer',
              }}
              onMouseEnter={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor = '#f0f0f0';
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor = 'transparent';
              }}
            >
              Delete / Clear
            </button>
          </div>
        )}
      </div>

      {/* Spacer to fill the rest of the bar */}
      <div style={{ flex: 1 }}></div>
      <div
        style={{
          height: '100%',
          boxSizing: 'border-box',
          padding: '6px',
          marginRight: '8px',
        }}
        onClick={onCollapseRightPanel}
      >
        <img
          style={{ height: '100%' }}
          src={`img/icons/right_${rightPanelOpen ? 'open' : 'closed'}.svg`}
        />
      </div>
    </div>
  );
};

export default TopBar;
