// PathProperties.tsx

import React from 'react';
import { ArtboardViewModel } from '../../viewmodel/ArtboardViewModel';
import { Path } from '../../lib/geometry/Path';
import { CapType } from '../../lib/geometry/CapType';
import { CornerType } from '../../lib/geometry/CornerType';
import PercentEntryWidget from '../components/PercentEntryWidget';
import NumberEntryWidget from '../components/NumberEntryWidget';

interface PathPropertiesProps {
  artboardViewModel: ArtboardViewModel;
  selectedPath: Path;
  onOpenColorSelector: (isStroke: boolean) => void;
}

const PathProperties: React.FC<PathPropertiesProps> = ({
  artboardViewModel,
  selectedPath,
  onOpenColorSelector,
}) => {
  return (
    <div
      style={{
        userSelect: 'none',
        fontSize: '12px',
        color: 'white',
        width: '100%',
        maxWidth: '100%',
        boxSizing: 'border-box',
        overflow: 'hidden',
      }}
    >
      {/* Fill Color Picker */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
          gap: '10px',
        }}
      >
        <label style={{ whiteSpace: 'nowrap', width: '50px', textAlign: 'right' }}>Fill:</label>
        <div
          style={{
            width: '32px',
            height: '32px',
            boxSizing: 'border-box',
            border: '1px solid black',
            backgroundColor: selectedPath.fill,
          }}
          onClick={() => onOpenColorSelector(false)}
        ></div>
      </div>

      {/* Stroke Color Picker */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
          gap: '10px',
        }}
      >
        <label style={{ whiteSpace: 'nowrap', width: '50px', textAlign: 'right' }}>Stroke:</label>
        <div
          style={{
            width: '32px',
            height: '32px',
            boxSizing: 'border-box',
            border: '1px solid black',
            backgroundColor: selectedPath.strokeColor,
          }}
          onClick={() => onOpenColorSelector(true)}
        ></div>
      </div>

      {/* Stroke Width */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
          gap: '10px',
        }}
      >
        <label style={{ whiteSpace: 'nowrap', width: '50px', textAlign: 'right' }}>Weight:</label>
        <NumberEntryWidget
          initialValue={selectedPath.strokeWidth}
          onChange={(n) => artboardViewModel.updateStrokeWidth(n)}
        />
      </div>

      {/* Cap Type */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
          gap: '10px',
        }}
      >
        <label style={{ whiteSpace: 'nowrap', width: '50px', textAlign: 'right' }}>Cap:</label>
        <div style={{ display: 'flex', gap: '5px', flex: 1 }}>
          <div
            style={{
              background: selectedPath.cap == CapType.BUTT ? '#333333' : 'transparent',
              width: '32px',
              height: '32px',
              boxSizing: 'border-box',
              padding: '4px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #666666',
            }}
            onClick={() => artboardViewModel.updateCapType(CapType.BUTT)}
          >
            <img src="img/icons/cap_butt.svg" style={{ height: '100%' }} />
          </div>
          <div
            style={{
              background: selectedPath.cap == CapType.ROUND ? '#333333' : 'transparent',
              width: '32px',
              height: '32px',
              boxSizing: 'border-box',
              padding: '4px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #666666',
            }}
            onClick={() => artboardViewModel.updateCapType(CapType.ROUND)}
          >
            <img src="img/icons/cap_round.svg" style={{ height: '100%' }} />
          </div>
          <div
            style={{
              background: selectedPath.cap == CapType.SQUARE ? '#333333' : 'transparent',
              width: '32px',
              height: '32px',
              boxSizing: 'border-box',
              padding: '4px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #666666',
            }}
            onClick={() => artboardViewModel.updateCapType(CapType.SQUARE)}
          >
            <img src="img/icons/cap_projecting.svg" style={{ height: '100%' }} />
          </div>
        </div>
      </div>

      {/* Corner Type */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
          gap: '10px',
        }}
      >
        <label style={{ whiteSpace: 'nowrap', width: '50px', textAlign: 'right' }}>Corner:</label>
        <div style={{ display: 'flex', gap: '5px', flex: 1 }}>
          <div
            style={{
              background: selectedPath.corner == CornerType.MITER ? '#333333' : 'transparent',
              width: '32px',
              height: '32px',
              boxSizing: 'border-box',
              padding: '4px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #666666',
            }}
            onClick={() => artboardViewModel.updateCornerType(CornerType.MITER)}
          >
            <img src="img/icons/corner_miter.svg" style={{ height: '100%' }} />
          </div>
          <div
            style={{
              background: selectedPath.corner == CornerType.ROUND ? '#333333' : 'transparent',
              width: '32px',
              height: '32px',
              boxSizing: 'border-box',
              padding: '4px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #666666',
            }}
            onClick={() => artboardViewModel.updateCornerType(CornerType.ROUND)}
          >
            <img src="img/icons/corner_round.svg" style={{ height: '100%' }} />
          </div>
          <div
            style={{
              background: selectedPath.corner == CornerType.BEVEL ? '#333333' : 'transparent',
              width: '32px',
              height: '32px',
              boxSizing: 'border-box',
              padding: '4px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #666666',
            }}
            onClick={() => artboardViewModel.updateCornerType(CornerType.BEVEL)}
          >
            <img src="img/icons/corner_bevel.svg" style={{ height: '100%' }} />
          </div>
        </div>
      </div>

      {/* Fill Opacity */}
      <div style={{ marginBottom: '10px', display: 'flex', gap: '10px', alignItems: 'center' }}>
        <label style={{ display: 'block', marginBottom: '5px', width: '50px', textAlign: 'right' }}>
          Opacity (Fill):
        </label>
        <PercentEntryWidget
          initialValue={selectedPath.fillOpacity * 100}
          onChange={(n) => artboardViewModel.updateFillOpacity(n / 100)}
        />
      </div>

      {/* Stroke Opacity */}
      <div style={{ marginBottom: '10px', display: 'flex', gap: '10px', alignItems: 'center' }}>
        <label style={{ display: 'block', marginBottom: '5px', width: '50px', textAlign: 'right' }}>
          Opacity (Stroke):
        </label>
        <PercentEntryWidget
          initialValue={selectedPath.strokeOpacity * 100}
          onChange={(n) => artboardViewModel.updateStrokeOpacity(n / 100)}
        />
      </div>
    </div>
  );
};

export default PathProperties;
