// src/components/Slider.tsx

import React, { useRef, useEffect, useState } from 'react';
import chroma from 'chroma-js';

interface SliderProps {
  colorValues: any;
  onChange: (newColor: string, newColorValues: any) => void;
  selectedProperty: string;
}

const Slider: React.FC<SliderProps> = ({ colorValues, onChange, selectedProperty }) => {
  const sliderCanvasRef = useRef<HTMLCanvasElement>(null);
  const indicatorHeight = 10;
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (colorValues && colorValues.hex) {
      drawSlider();
    }
  }, [colorValues, selectedProperty]);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const drawSlider = () => {
    const canvas = sliderCanvasRef.current;
    if (!canvas || !colorValues) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const width = canvas.width;
    const height = canvas.height;

    // Create image data for the slider gradient
    const imageData = ctx.createImageData(width, height);
    const data = imageData.data;

    for (let y = 0; y < height; y++) {
      const ratioY = y / (height - 1);
      let r = colorValues.R;
      let g = colorValues.G;
      let b = colorValues.B2;
      let h = colorValues.H;
      let s = colorValues.S;
      let v = colorValues.B;

      switch (selectedProperty) {
        case 'H':
          h = 360 - ratioY * 360;
          [r, g, b] = chroma.hsv(h, 1, 1).rgb();
          break;
        case 'S':
          s = 1 - ratioY;
          [r, g, b] = chroma.hsv(h, s, 1).rgb();
          break;
        case 'B':
          v = 1 - ratioY;
          [r, g, b] = chroma.hsv(h, 1, v).rgb();
          break;
        case 'R':
          r = (1 - ratioY) * 255;
          break;
        case 'G':
          g = (1 - ratioY) * 255;
          break;
        case 'B2':
          b = (1 - ratioY) * 255;
          break;
        default:
          break;
      }

      // Clamp values to [0, 255]
      r = Math.max(0, Math.min(255, r));
      g = Math.max(0, Math.min(255, g));
      b = Math.max(0, Math.min(255, b));

      for (let x = 0; x < width; x++) {
        const index = (y * width + x) * 4;
        data[index] = r;
        data[index + 1] = g;
        data[index + 2] = b;
        data[index + 3] = 255;
      }
    }

    ctx.putImageData(imageData, 0, 0);
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    handleSliderChange(e);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isDragging) {
      handleSliderChange(e);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleSliderChange = (e: MouseEvent | React.MouseEvent) => {
    const canvas = sliderCanvasRef.current;
    if (!canvas || !colorValues) return;

    const rect = canvas.getBoundingClientRect();
    const height = canvas.height;
    let y = e.clientY - rect.top;

    y = Math.max(0, Math.min(y, height - 1));

    const ratioY = y / (height - 1);

    const newColorValues = { ...colorValues };

    switch (selectedProperty) {
      case 'H':
        newColorValues.H = 360 - ratioY * 360;
        break;
      case 'S':
        newColorValues.S = 1 - ratioY;
        break;
      case 'B':
        newColorValues.B = 1 - ratioY;
        break;
      case 'R':
        newColorValues.R = (1 - ratioY) * 255;
        break;
      case 'G':
        newColorValues.G = (1 - ratioY) * 255;
        break;
      case 'B2':
        newColorValues.B2 = (1 - ratioY) * 255;
        break;
      default:
        break;
    }

    // Clamp values
    newColorValues.H = Math.max(0, Math.min(360, newColorValues.H || 0));
    newColorValues.S = Math.max(0, Math.min(1, newColorValues.S || 0));
    newColorValues.B = Math.max(0, Math.min(1, newColorValues.B || 0));
    newColorValues.R = Math.max(0, Math.min(255, newColorValues.R || 0));
    newColorValues.G = Math.max(0, Math.min(255, newColorValues.G || 0));
    newColorValues.B2 = Math.max(0, Math.min(255, newColorValues.B2 || 0));

    // Update hex and HSB/RGB values
    let newColor: chroma.Color;
    if (['H', 'S', 'B'].includes(selectedProperty)) {
      newColor = chroma.hsv(newColorValues.H, newColorValues.S, newColorValues.B);
      const rgb = newColor.rgb();
      newColorValues.R = rgb[0];
      newColorValues.G = rgb[1];
      newColorValues.B2 = rgb[2];
    } else {
      newColor = chroma(newColorValues.R, newColorValues.G, newColorValues.B2);
      const hsv = newColor.hsv();
      newColorValues.H = hsv[0] || 0;
      newColorValues.S = hsv[1] || 0;
      newColorValues.B = hsv[2] || 0;
    }
    newColorValues.hex = newColor.hex().toUpperCase();

    onChange(newColorValues.hex, newColorValues);
  };

  const canvasHeight = 300; // Same as the canvas height
  let valueRatio = 0;

  switch (selectedProperty) {
    case 'H':
      valueRatio = 1 - colorValues.H / 360;
      break;
    case 'S':
      valueRatio = 1 - colorValues.S;
      break;
    case 'B':
      valueRatio = 1 - colorValues.B;
      break;
    case 'R':
      valueRatio = 1 - colorValues.R / 255;
      break;
    case 'G':
      valueRatio = 1 - colorValues.G / 255;
      break;
    case 'B2':
      valueRatio = 1 - colorValues.B2 / 255;
      break;
    default:
      break;
  }

  const indicatorPosition = valueRatio * canvasHeight - 10;

  return (
    <div
      style={{
        position: 'relative',
        width: '30px',
        height: `${canvasHeight}px`,
        marginLeft: '20px',
        boxSizing: 'border-box',
      }}
    >
      {/* Left Indicator */}
      <div
        style={{
          position: 'absolute',
          top: `${indicatorPosition}px`,
          right: '-10px',
          width: '10px',
          height: `${indicatorHeight}px`,
        }}
      >
        <svg width="10" height="10">
          <polygon points="10,0 0,5 10,10" fill="lightgrey" />
        </svg>
      </div>
      {/* Right Indicator */}
      <div
        style={{
          position: 'absolute',
          top: `${indicatorPosition}px`,
          left: '-10px',
          width: '10px',
          height: `${indicatorHeight}px`,
        }}
      >
        <svg width="10" height="10">
          <polygon points="0,0 10,5 0,10" fill="lightgrey" />
        </svg>
      </div>
      <canvas
        ref={sliderCanvasRef}
        width={30}
        height={canvasHeight}
        style={{ cursor: 'pointer' }}
        onMouseDown={handleMouseDown}
      ></canvas>
    </div>
  );
};

export default Slider;
