// ArtboardTool.ts

import { Tool, ToolContext } from './Tool';
import { Dimensions } from '../math/Dimensions';
import { Point } from '../math/Point';
import { NormalizedTouchEvent } from '../../ui/input/MouseTouch';

type HandleType =
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right';

type DraggingState = null | {
  handle: HandleType;
  startMousePos: Point;
  startDimensions: Dimensions;
  startScaleOffset: {
    x: number;
    y: number;
    scaleX: number;
  };
  oppositeEdgeScreenPos: {
    x?: number;
    y?: number;
  };
};

export class ArtboardTool implements Tool {
  private draggingState: DraggingState = null;

  onMouseDown(event: NormalizedTouchEvent, context: ToolContext): void {
    const { canvas, artboardViewModel } = context;

    // Get the mouse position in artboard coordinates
    const mousePos = this.getMousePosInArtboard(event, context);

    // Get the artboard dimensions
    const artboardDimensions = artboardViewModel.state.boardDimensions;

    // Determine which handle (if any) the mouse is over
    const handle = this.getHandleUnderMouse(mousePos, artboardDimensions);

    if (handle) {
      const scaleOffset = artboardViewModel.state.scaleOffset;
      const rect = canvas.getBoundingClientRect();

      // Calculate xOffset and yOffset used in transformations
      const xOffset = (rect.width / scaleOffset.scaleX - artboardDimensions.w) / 2;
      const yOffset = (rect.height / scaleOffset.scaleX - artboardDimensions.h) / 2;

      // Determine the opposite edge(s) and their positions in artboard coordinates
      const oppositeEdges = this.getOppositeEdges(handle, artboardDimensions);

      // Compute the screen positions of the opposite edges
      const oppositeEdgeScreenPos: { x?: number; y?: number } = {};

      if (oppositeEdges.x !== undefined) {
        const artboardX = oppositeEdges.x;
        const screenX = scaleOffset.x + (xOffset + artboardX) * scaleOffset.scaleX;
        oppositeEdgeScreenPos.x = screenX;
      }

      if (oppositeEdges.y !== undefined) {
        const artboardY = oppositeEdges.y;
        const screenY = scaleOffset.y + (yOffset + artboardY) * scaleOffset.scaleX;
        oppositeEdgeScreenPos.y = screenY;
      }

      // Start dragging
      this.draggingState = {
        handle,
        startMousePos: mousePos,
        startDimensions: artboardDimensions,
        startScaleOffset: {
          x: scaleOffset.x,
          y: scaleOffset.y,
          scaleX: scaleOffset.scaleX,
        },
        oppositeEdgeScreenPos,
      };
    }
  }

  onMouseMove(event: NormalizedTouchEvent, context: ToolContext): boolean {
    if (!this.draggingState) {
      return false; // No need to re-render overlay
    }

    const { artboardViewModel, canvas } = context;
    const { handle, startMousePos, startDimensions, startScaleOffset, oppositeEdgeScreenPos } =
      this.draggingState;

    // Get the current mouse position in artboard coordinates
    const mousePos = this.getMousePosInArtboard(event, context);

    // Calculate the difference
    const dx = mousePos.x - startMousePos.x;
    const dy = mousePos.y - startMousePos.y;

    // Update the artboard dimensions based on the handle being dragged
    let newWidth = startDimensions.w;
    let newHeight = startDimensions.h;

    switch (handle) {
      case 'right':
        newWidth = startDimensions.w + dx;
        break;
      case 'bottom':
        newHeight = startDimensions.h + dy;
        break;
      case 'bottom-right':
        newWidth = startDimensions.w + dx;
        newHeight = startDimensions.h + dy;
        break;
      case 'left':
        newWidth = startDimensions.w - dx;
        break;
      case 'top':
        newHeight = startDimensions.h - dy;
        break;
      case 'top-left':
        newWidth = startDimensions.w - dx;
        newHeight = startDimensions.h - dy;
        break;
      case 'top-right':
        newWidth = startDimensions.w + dx;
        newHeight = startDimensions.h - dy;
        break;
      case 'bottom-left':
        newWidth = startDimensions.w - dx;
        newHeight = startDimensions.h + dy;
        break;
    }

    // Ensure minimum size
    const minWidth = 100;
    const minHeight = 100;

    if (newWidth < minWidth) {
      newWidth = minWidth;
    }
    if (newHeight < minHeight) {
      newHeight = minHeight;
    }

    // Update the artboard size
    artboardViewModel.updateArtboardSize(new Dimensions(newWidth, newHeight));

    // Now adjust pan offsets to keep opposite edges at the same screen positions
    const scaleX = startScaleOffset.scaleX;
    const rect = canvas.getBoundingClientRect();

    // Calculate xOffset and yOffset used in transformations
    const xOffset = (rect.width / scaleX - newWidth) / 2;
    const yOffset = (rect.height / scaleX - newHeight) / 2;

    // Get the new screen positions of the opposite edges
    const oppositeEdges = this.getOppositeEdges(handle, new Dimensions(newWidth, newHeight));
    let newPanX = startScaleOffset.x;
    let newPanY = startScaleOffset.y;

    if (oppositeEdges.x !== undefined && oppositeEdgeScreenPos.x !== undefined) {
      const artboardX = oppositeEdges.x;
      const newScreenX = startScaleOffset.x + (xOffset + artboardX) * scaleX;
      const deltaPanX = oppositeEdgeScreenPos.x - newScreenX;
      newPanX += deltaPanX;
    }

    if (oppositeEdges.y !== undefined && oppositeEdgeScreenPos.y !== undefined) {
      const artboardY = oppositeEdges.y;
      const newScreenY = startScaleOffset.y + (yOffset + artboardY) * scaleX;
      const deltaPanY = oppositeEdgeScreenPos.y - newScreenY;
      newPanY += deltaPanY;
    }

    artboardViewModel.updatePanX(newPanX);
    artboardViewModel.updatePanY(newPanY);

    return true; // Need to re-render overlay
  }

  onMouseUp(event: NormalizedTouchEvent, context: ToolContext): void {
    this.draggingState = null;
  }

  drawOverlay(context: ToolContext, ctx: CanvasRenderingContext2D): void {
    const { canvas, artboardViewModel } = context;

    const artboardDimensions = artboardViewModel.state.boardDimensions;
    const scaleOffset = artboardViewModel.state.scaleOffset;

    const rect = canvas.getBoundingClientRect();

    // Apply the same transformations as in MainCanvas
    ctx.save();
    ctx.translate(scaleOffset.x, scaleOffset.y);
    ctx.scale(scaleOffset.scaleX, scaleOffset.scaleX);

    // Center the artboard within the canvas
    const xOffset = (rect.width / scaleOffset.scaleX - artboardDimensions.w) / 2;
    const yOffset = (rect.height / scaleOffset.scaleX - artboardDimensions.h) / 2;
    ctx.translate(xOffset, yOffset);

    // Draw the artboard outline as a dashed line
    ctx.strokeStyle = '#0000FF';
    ctx.lineWidth = 2 / scaleOffset.scaleX; // Adjust for scale
    ctx.setLineDash([5 / scaleOffset.scaleX, 5 / scaleOffset.scaleX]); // Dashed line
    ctx.strokeRect(0, 0, artboardDimensions.w, artboardDimensions.h);
    ctx.setLineDash([]); // Reset line dash

    // Draw resize handles
    const handleSize = 10 / scaleOffset.scaleX; // Adjust for scale
    const halfHandleSize = handleSize / 2;

    // Positions of handles
    const handles = [
      { x: 0, y: 0, cursor: 'nw-resize', name: 'top-left' },
      { x: artboardDimensions.w / 2, y: 0, cursor: 'n-resize', name: 'top' },
      { x: artboardDimensions.w, y: 0, cursor: 'ne-resize', name: 'top-right' },
      {
        x: artboardDimensions.w,
        y: artboardDimensions.h / 2,
        cursor: 'e-resize',
        name: 'right',
      },
      {
        x: artboardDimensions.w,
        y: artboardDimensions.h,
        cursor: 'se-resize',
        name: 'bottom-right',
      },
      {
        x: artboardDimensions.w / 2,
        y: artboardDimensions.h,
        cursor: 's-resize',
        name: 'bottom',
      },
      { x: 0, y: artboardDimensions.h, cursor: 'sw-resize', name: 'bottom-left' },
      { x: 0, y: artboardDimensions.h / 2, cursor: 'w-resize', name: 'left' },
    ];

    ctx.fillStyle = '#FFFFFF';
    ctx.strokeStyle = '#0000FF';

    for (const handle of handles) {
      ctx.save();
      ctx.translate(handle.x - halfHandleSize, handle.y - halfHandleSize);
      ctx.fillRect(0, 0, handleSize, handleSize);
      ctx.strokeRect(0, 0, handleSize, handleSize);
      ctx.restore();
    }

    ctx.restore();
  }

  private getMousePosInArtboard(event: NormalizedTouchEvent, context: ToolContext): Point {
    const { canvas, artboardViewModel } = context;
    const rect = canvas.getBoundingClientRect();

    const scaleOffset = artboardViewModel.state.scaleOffset;

    // Get mouse position relative to canvas
    const mouseX = event.x - rect.left;
    const mouseY = event.y - rect.top;

    // Reverse transformations
    let x = mouseX;
    let y = mouseY;

    // Reverse pan
    x -= scaleOffset.x;
    y -= scaleOffset.y;

    // Reverse scale
    x /= scaleOffset.scaleX;
    y /= scaleOffset.scaleX; // Assuming scaleY = scaleX

    // Reverse artboard centering
    const artboardDimensions = artboardViewModel.state.boardDimensions;
    const xOffset = (rect.width / scaleOffset.scaleX - artboardDimensions.w) / 2;
    const yOffset = (rect.height / scaleOffset.scaleX - artboardDimensions.h) / 2;
    x -= xOffset;
    y -= yOffset;

    return new Point(x, y);
  }

  private getHandleUnderMouse(mousePos: Point, artboardDimensions: Dimensions): HandleType | null {
    const handleSize = 10; // Same as in drawOverlay

    const halfHandleSize = handleSize / 2;

    // Positions of handles
    const handles: Array<{ x: number; y: number; name: HandleType }> = [
      { x: 0, y: 0, name: 'top-left' },
      { x: artboardDimensions.w / 2, y: 0, name: 'top' },
      { x: artboardDimensions.w, y: 0, name: 'top-right' },
      {
        x: artboardDimensions.w,
        y: artboardDimensions.h / 2,
        name: 'right',
      },
      {
        x: artboardDimensions.w,
        y: artboardDimensions.h,
        name: 'bottom-right',
      },
      {
        x: artboardDimensions.w / 2,
        y: artboardDimensions.h,
        name: 'bottom',
      },
      { x: 0, y: artboardDimensions.h, name: 'bottom-left' },
      { x: 0, y: artboardDimensions.h / 2, name: 'left' },
    ];

    for (const handle of handles) {
      const hx = handle.x;
      const hy = handle.y;
      if (
        mousePos.x >= hx - halfHandleSize &&
        mousePos.x <= hx + halfHandleSize &&
        mousePos.y >= hy - halfHandleSize &&
        mousePos.y <= hy + halfHandleSize
      ) {
        return handle.name;
      }
    }

    return null;
  }

  private getOppositeEdges(
    handle: HandleType,
    artboardDimensions: Dimensions,
  ): { x?: number; y?: number } {
    switch (handle) {
      case 'right':
        return { x: 0 }; // Left edge at x=0
      case 'left':
        return { x: artboardDimensions.w }; // Right edge
      case 'top':
        return { y: artboardDimensions.h }; // Bottom edge
      case 'bottom':
        return { y: 0 }; // Top edge
      case 'top-right':
        return { x: 0, y: artboardDimensions.h }; // Left and bottom edges
      case 'top-left':
        return { x: artboardDimensions.w, y: artboardDimensions.h }; // Right and bottom edges
      case 'bottom-right':
        return { x: 0, y: 0 }; // Left and top edges
      case 'bottom-left':
        return { x: artboardDimensions.w, y: 0 }; // Right and top edges
      default:
        return {};
    }
  }
}
