export interface NormalizedTouchEvent {
  x: number;
  y: number;
  altKey: boolean;
  metaKey: boolean;
  shiftKey: boolean;
  controlKey: boolean;
}

export class MouseTouch {
  static getCoordinates = (event: MouseEvent | TouchEvent): NormalizedTouchEvent => {
    if ('touches' in event && event.touches.length > 0) {
      return {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
        altKey: event.altKey,
        metaKey: event.metaKey,
        shiftKey: event.shiftKey,
        controlKey: event.ctrlKey,
      };
    } else if ('clientX' in event) {
      return {
        x: event.clientX,
        y: event.clientY,
        altKey: event.altKey,
        metaKey: event.metaKey,
        shiftKey: event.shiftKey,
        controlKey: event.ctrlKey,
      };
    } else {
      return {
        x: -1,
        y: -1,
        altKey: event.altKey,
        metaKey: event.metaKey,
        shiftKey: event.shiftKey,
        controlKey: event.ctrlKey,
      };
    }
    throw new Error('Unsupported event type');
  };
}
