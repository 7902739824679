export enum CapType {
  BUTT,
  ROUND,
  SQUARE,
}

export const CapTypeUtils = {
  toCanvasLineCap(capType: CapType): CanvasLineCap {
    switch (capType) {
      case CapType.BUTT:
        return 'butt';
      case CapType.ROUND:
        return 'round';
      case CapType.SQUARE:
        return 'square';
      default:
        throw new Error('Invalid CapType');
    }
  },
};
