// SplashScreen.tsx

import React, { useState, useEffect } from 'react';
import { Dimensions } from '../lib/math/Dimensions';
import { usePageTracker } from '../hooks/AnalyticsHooks';

interface Props {
  version: string;
  onCreate: (dimensions: Dimensions) => void;
}

const SplashScreen: React.FC<Props> = ({ version, onCreate }) => {
  usePageTracker('splash_screen');

  const [width, setWidth] = useState<number>(600); // Default width
  const [height, setHeight] = useState<number>(400); // Default height

  // State to track screen width for responsiveness
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const setAppHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };

    setAppHeight();

    window.addEventListener('resize', setAppHeight);
    window.addEventListener('orientationchange', setAppHeight);

    return () => {
      window.removeEventListener('resize', setAppHeight);
      window.removeEventListener('orientationchange', setAppHeight);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  const isMobile = screenWidth < 768; // Define breakpoint for mobile

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: 'var(--app-height)', // Use CSS variable to handle address bar issue
        backgroundColor: '#606060',
        boxSizing: 'border-box',
        padding: isMobile ? '0px' : '20px',
        color: 'white',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          userSelect: 'none',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: 'center', // Center content horizontally
          border: '1px solid #2d2d2d',
          borderRadius: isMobile ? '0px' : '8px', // Remove rounded corners on mobile
          backgroundColor: '#535353',
          padding: isMobile ? '0px' : '30px',
          boxShadow: isMobile ? 'none' : '0 4px 8px rgba(0, 0, 0, 0.1)',
          width: isMobile ? '100%' : 'auto',
          height: isMobile ? '100%' : 'auto',
        }}
      >
        {/* Left Section */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: isMobile ? '0px' : '20px',
            marginBottom: isMobile ? '20px' : '0px',
            padding: isMobile ? '20px' : '50px',
            flex: 1,
            maxWidth: '400px',
            width: isMobile ? '100%' : 'auto',
          }}
        >
          <img
            src="vs-logo.png" // Replace with your logo URL
            alt="Vectorsaur Logo"
            style={{ width: '150px', height: '150px', marginBottom: '20px', filter: 'invert(1)' }}
          />
          <h1 style={{ fontSize: '24px', textAlign: 'center' }}>
            Vectorsaur<span style={{ fontSize: '12px', marginLeft: '8px' }}>{version}</span>
          </h1>
        </div>

        {/* Right Section */}
        <div
          style={{
            marginLeft: isMobile ? '0px' : '50px',
            marginRight: isMobile ? '0px' : '50px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', // Center horizontally
            justifyContent: 'center',
            flex: 1,
            maxWidth: '400px',
            width: isMobile ? '100%' : 'auto',
          }}
        >
          <div
            style={{
              padding: '10px',
              textAlign: 'start',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center', // Center on mobile
            }}
          >
            <h2 style={{ marginBottom: '10px', textAlign: 'center' }}>New Project</h2>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <label
                style={{
                  marginRight: '10px',
                  width: '80px',
                  textAlign: 'right',
                }}
              >
                Width:
              </label>
              <input
                type="number"
                value={width}
                onChange={(e) => setWidth(Number(e.target.value))}
                style={{
                  padding: '5px',
                  width: '80px',
                  boxSizing: 'border-box',
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <label
                style={{
                  marginRight: '10px',
                  width: '80px',
                  textAlign: 'right',
                }}
              >
                Height:
              </label>
              <input
                type="number"
                value={height}
                onChange={(e) => setHeight(Number(e.target.value))}
                style={{
                  padding: '5px',
                  width: '80px',
                  boxSizing: 'border-box',
                }}
              />
            </div>
            <button
              style={{
                backgroundColor: '#007bff', // Blue background
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                padding: '10px 20px',
                fontSize: '16px',
                cursor: 'pointer',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              }}
              onClick={() => onCreate(new Dimensions(width, height))}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;
