// Editor.tsx

import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { SvgRenderer } from '../lib/graphics/SvgRenderer';
import { ScaleOffset } from '../lib/math/ScaleOffset';
import { AnchorPointTool } from '../lib/tool/AnchorPointTool';
import { DirectSelectionTool } from '../lib/tool/DirectSelection';
import { EllipseTool } from '../lib/tool/EllipseTool';
import { PenTool } from '../lib/tool/PenTool';
import { RectangleTool } from '../lib/tool/RectangleTool';
import { SelectionTool } from '../lib/tool/SelectionTool';
import { ArtboardTool } from '../lib/tool/ArtboardTool';
import { ToolType, ToolContext } from '../lib/tool/Tool';
import { useArtboardViewModel } from '../viewmodel/ArtboardViewModel';
import MainCanvas from './MainCanvas';
import LayersPanel from './panel/LayersPanel';
import PropertiesPanel from './panel/PropertiesPanel';
import Toolbox from './toolbox/Toolbox';
import TopBar from './TopBar';
import { Path } from '../lib/geometry/Path';
import { Dimensions } from '../lib/math/Dimensions';
import { usePageTracker } from '../hooks/AnalyticsHooks';
import { AnalyticsUtil } from '../util/AnalyticsUtil';
import { MouseTouch } from './input/MouseTouch';
import useIsMobile from '../hooks/useIsMobile';
import useIsPortrait from '../hooks/useIsPortrait';
import { ColorValues, ColorValueUtil } from '../lib/color/ColorValues';
import DraggableWindow from './modal/DraggableWindow';
import ColorPicker from './color/ColorPicker';
import { EyedropperTool } from '../lib/tool/EyedropperTool';

interface Props {
  initialDimensions: Dimensions | null;
}

const Editor: React.FC<Props> = ({ initialDimensions }) => {
  usePageTracker('editor');
  const isMobile = useIsMobile();
  const isPortrait = useIsPortrait();
  const showBottomToolbar = isMobile && isPortrait;

  const artboardViewModel = useArtboardViewModel(initialDimensions ?? new Dimensions(800, 600));

  const [renderFrame, setRenderFrame] = useState<number>(0);
  const [mainRenderFrame, setMainRenderFrame] = useState<number>(0);
  const [currentPath, setCurrentPath] = useState<Path | null>(null);
  const [selectedToolType, setSelectedToolType] = useState<ToolType>(ToolType.DirectSelection);
  const [isRightPanelOpen, setIsRightPanelOpen] = useState(!isMobile);
  const [showWindow, setShowWindow] = useState<boolean>(false);

  // Initialize tool instances
  const toolInstances = useMemo(() => {
    return {
      [ToolType.Selection]: new SelectionTool(),
      [ToolType.DirectSelection]: new DirectSelectionTool(),
      [ToolType.AnchorPoint]: new AnchorPointTool(),
      [ToolType.Pen]: new PenTool(),
      [ToolType.Rectangle]: new RectangleTool(),
      [ToolType.Ellipse]: new EllipseTool(),
      [ToolType.Eyedropper]: new EyedropperTool(),
      [ToolType.Artboard]: new ArtboardTool(),
    };
  }, []);

  const selectedTool = toolInstances[selectedToolType];

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const overlayCanvasRef = useRef<HTMLCanvasElement>(null);

  // Function to switch tools
  const switchTool = useCallback(
    (toolType: ToolType) => {
      AnalyticsUtil.trackEvent('tool_selection', { category: 'Usage', label: ToolType[toolType] });

      if (selectedToolType === ToolType.Pen) {
        const penTool = selectedTool as PenTool;
        const context: ToolContext = {
          canvas: overlayCanvasRef.current!,
          mainCanvas: canvasRef.current!,
          artboardViewModel: artboardViewModel,
          currentPath: currentPath,
          setCurrentPath: setCurrentPath,
        };
        penTool.finalize(context);
      }
      setSelectedToolType(toolType);
    },
    [selectedToolType, selectedTool, artboardViewModel, currentPath],
  );

  // Function to download SVG
  const downloadSVG = () => {
    AnalyticsUtil.trackEvent('export_svg', { category: 'Usage', label: 'export_svg' });

    const renderer = new SvgRenderer(artboardViewModel);
    const svgContent = renderer.render();
    // Create a blob of the data
    const blob = new Blob([svgContent], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    // Create a link and trigger download
    const link = document.createElement('a');
    link.href = url;
    link.download = 'drawing.svg';
    document.body.appendChild(link);
    link.click();
    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // Function to place an image
  const onPlaceImage = () => {
    AnalyticsUtil.trackEvent('import_image', { category: 'Usage', label: 'import_image' });

    // Create an input element to select a file
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const image = new Image();
          image.onload = () => {
            // Create ImageObject and add it to artboard
            const imageObject = {
              image: image,
              scaleOffset: new ScaleOffset(),
              zIndex: 0, // Will be set in addObject
              opacity: 1,
              visible: true,
              locked: false,
              selected: true,
            };
            artboardViewModel.addObject(imageObject);
          };
          image.src = event.target?.result as string;
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  const handleResize = () => {
    setRenderFrame((prev) => prev + 1);
    setMainRenderFrame((prev) => prev + 1);
  };

  const handleColorSelected = (newColor: ColorValues) => {
    if (artboardViewModel.state.editingStroke) {
      artboardViewModel.updateGlobalStrokeColor(newColor);
      artboardViewModel.updateStrokeColor(newColor?.hex);
    } else {
      artboardViewModel.updateGlobalFillColor(newColor);
      artboardViewModel.updateFillColor(newColor?.hex);
    }
    setShowWindow(false);
  };

  const handleColorSwitched = (isStroke: boolean, useColor: boolean) => {
    if (isStroke) {
      artboardViewModel.updateGlobalUseStrokeColor(useColor);
      artboardViewModel.updateStrokeColor(
        useColor ? artboardViewModel.state.strokeColor.hex : 'transparent',
      );
    } else {
      artboardViewModel.updateGlobalUseFillColor(useColor);
      artboardViewModel.updateFillColor(
        useColor ? artboardViewModel.state.fillColor.hex : 'transparent',
      );
    }
  };

  const handleSwapColors = () => {
    const s = artboardViewModel.state;
    const stroke = s.useFillColor ? s.fillColor.hex : 'transparent';
    const fill = s.useStrokeColor ? s.strokeColor.hex : 'transparent';
    artboardViewModel.swapStrokeAndFill();
    artboardViewModel.updateStrokeColor(stroke);
    artboardViewModel.updateFillColor(fill);
  };

  const handleDefaultColors = () => {
    artboardViewModel.resetColors();
    artboardViewModel.updateStrokeColor(ColorValueUtil.BLACK.hex);
    artboardViewModel.updateFillColor(ColorValueUtil.WHITE.hex);
  };

  const handleWindowClosed = () => {
    setShowWindow(false);
  };

  const handleColorTypeChanged = (isStroke: boolean) => {
    artboardViewModel.updateGlobalColorEditingType(isStroke);
  };

  const handleWindowOpened = (isStroke: boolean) => {
    artboardViewModel.updateGlobalColorEditingType(isStroke);
    setShowWindow(true);
  };

  // Add hotkey support
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const target = event.target as HTMLElement;
      const tagName = target.tagName.toLowerCase();

      if (tagName === 'input' || tagName === 'textarea' || target.isContentEditable) {
        return; // Do not process hotkeys when typing in inputs
      }

      const key = event.key.toLowerCase();

      // Examples for Ctrl+E and Shift+E
      if (event.metaKey && key === 'e') {
        alert('example');
        event.preventDefault();
      } else if (event.shiftKey && key === 'e') {
        alert('example');
        event.preventDefault();
      } else {
        // Map hotkeys to tools
        switch (key.toLowerCase()) {
          case 'delete':
          case 'backspace':
            artboardViewModel.deleteSelectedObjects();
            event.preventDefault();
            break;
          case 'v':
            switchTool(ToolType.Selection);
            event.preventDefault();
            break;
          case 'a':
            switchTool(ToolType.DirectSelection);
            event.preventDefault();
            break;
          case 'p':
            switchTool(ToolType.Pen);
            event.preventDefault();
            break;
          case 'm':
            switchTool(ToolType.Rectangle);
            event.preventDefault();
            break;
          case 'l':
            switchTool(ToolType.Ellipse);
            event.preventDefault();
            break;
          case 'o':
            if (event.shiftKey) {
              switchTool(ToolType.Artboard);
              event.preventDefault();
            }
            break;
          case 'i':
            switchTool(ToolType.Eyedropper);
            event.preventDefault();
            break;
          case 'c':
            if (event.shiftKey) {
              switchTool(ToolType.AnchorPoint);
              event.preventDefault();
            }
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [switchTool, artboardViewModel]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Copy and paste handlers
  useEffect(() => {
    const handleCopy = (event: ClipboardEvent): void => {
      if (event.clipboardData) {
        if (artboardViewModel.getSelectedObjects().length > 0) {
          const selectedObject = artboardViewModel.getSelectedObjects()[0];
          const serializedData = JSON.stringify(selectedObject);
          event.clipboardData.setData('application/json', serializedData);
          event.clipboardData.setData('text/plain', serializedData);
          event.preventDefault();
        }
      }
    };

    const handlePaste = (event: ClipboardEvent): void => {
      artboardViewModel.pasteFromClipboard();
      // event.preventDefault();
    };

    window.addEventListener('copy', handleCopy as unknown as EventListener);
    window.addEventListener('paste', handlePaste as unknown as EventListener);

    return () => {
      window.removeEventListener('copy', handleCopy as unknown as EventListener);
      window.removeEventListener('paste', handlePaste as unknown as EventListener);
    };
  }, [artboardViewModel]);

  // Mouse and touch event handlers
  useEffect(() => {
    const canvas = overlayCanvasRef.current;
    const mainCanvas = canvasRef.current;
    if (!canvas || !mainCanvas) return;

    let touchActionPending = false;
    let touchActionTimeoutId: number | null = null;
    let touchEventQueue: TouchEvent[] = [];

    const processTouchEvent = (event: TouchEvent) => {
      const context: ToolContext = {
        canvas,
        mainCanvas,
        artboardViewModel,
        currentPath,
        setCurrentPath,
      };
      const e = MouseTouch.getCoordinates(event);

      // Determine which event type it is
      if (event.type === 'touchstart') {
        selectedTool.onMouseDown(e, context);
      } else if (event.type === 'touchmove') {
        if (selectedTool.onMouseMove(e, context)) {
          setRenderFrame((previous) => previous + 1);
        }
      } else if (event.type === 'touchend' || event.type === 'touchcancel') {
        selectedTool.onMouseUp(e, context);
      }
    };

    // Mouse event handlers
    const handleMouseDown = (event: MouseEvent) => {
      const context: ToolContext = {
        canvas,
        mainCanvas,
        artboardViewModel,
        currentPath,
        setCurrentPath,
      };
      const e = MouseTouch.getCoordinates(event);
      selectedTool.onMouseDown(e, context);
    };

    const handleMouseMove = (event: MouseEvent) => {
      const context: ToolContext = {
        canvas,
        mainCanvas,
        artboardViewModel,
        currentPath,
        setCurrentPath,
      };
      const e = MouseTouch.getCoordinates(event);
      if (selectedTool.onMouseMove(e, context)) {
        setRenderFrame((previous) => previous + 1);
      }
    };

    const handleMouseUp = (event: MouseEvent) => {
      const context: ToolContext = {
        canvas,
        mainCanvas,
        artboardViewModel,
        currentPath,
        setCurrentPath,
      };
      const e = MouseTouch.getCoordinates(event);
      selectedTool.onMouseUp(e, context);
    };

    // Touch event handlers
    const handleTouchStart = (event: TouchEvent) => {
      if (event.touches.length === 1) {
        touchActionPending = true;
        touchEventQueue = [];
        touchEventQueue.push(event);

        touchActionTimeoutId = window.setTimeout(() => {
          if (touchActionPending) {
            // Process the queued events
            for (const evt of touchEventQueue) {
              processTouchEvent(evt);
            }
            touchEventQueue = [];
          }
        }, 100);
      } else {
        touchActionPending = false;
        if (touchActionTimeoutId !== null) {
          clearTimeout(touchActionTimeoutId);
          touchActionTimeoutId = null;
        }
        touchEventQueue = [];
      }
    };

    const handleTouchMove = (event: TouchEvent) => {
      event.preventDefault(); // Prevent scrolling on touchmove

      if (event.touches.length > 1) {
        touchActionPending = false;
        if (touchActionTimeoutId !== null) {
          clearTimeout(touchActionTimeoutId);
          touchActionTimeoutId = null;
        }
        touchEventQueue = [];
      } else {
        if (touchActionPending) {
          touchEventQueue.push(event);
        } else {
          processTouchEvent(event);
        }
      }
    };

    const handleTouchEnd = (event: TouchEvent) => {
      if (event.touches.length === 0) {
        if (touchActionPending) {
          touchEventQueue.push(event);
          if (touchActionTimeoutId !== null) {
            clearTimeout(touchActionTimeoutId);
            touchActionTimeoutId = null;
          }
          // Process the queued events
          for (const evt of touchEventQueue) {
            processTouchEvent(evt);
          }
          touchEventQueue = [];
          touchActionPending = false;
        } else {
          processTouchEvent(event);
        }
      } else {
        // Still have active touches
        if (touchActionPending) {
          touchEventQueue.push(event);
        } else {
          processTouchEvent(event);
        }
      }
    };

    canvas.addEventListener('mousedown', handleMouseDown);
    canvas.addEventListener('mousemove', handleMouseMove);
    canvas.addEventListener('mouseup', handleMouseUp);

    canvas.addEventListener('touchstart', handleTouchStart);
    canvas.addEventListener('touchmove', handleTouchMove, { passive: false });
    canvas.addEventListener('touchend', handleTouchEnd);
    canvas.addEventListener('touchcancel', handleTouchEnd);

    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown);
      canvas.removeEventListener('mousemove', handleMouseMove);
      canvas.removeEventListener('mouseup', handleMouseUp);

      canvas.removeEventListener('touchstart', handleTouchStart);
      canvas.removeEventListener('touchmove', handleTouchMove);
      canvas.removeEventListener('touchend', handleTouchEnd);
      canvas.removeEventListener('touchcancel', handleTouchEnd);

      if (touchActionTimeoutId !== null) {
        clearTimeout(touchActionTimeoutId);
        touchActionTimeoutId = null;
      }
    };
  }, [selectedTool, artboardViewModel, currentPath]);

  // Hover effect handlers
  useEffect(() => {
    const canvas = overlayCanvasRef.current;
    if (!canvas) return;

    const handleMouseMoveHover = (event: MouseEvent) => {
      const rect = canvas.getBoundingClientRect();
      const scaleOffset = artboardViewModel.state.scaleOffset;
      const artboardDimensions = artboardViewModel.state.boardDimensions;

      const point = ScaleOffset.screenToCanvasPoint(
        event.clientX,
        event.clientY,
        rect,
        scaleOffset,
        artboardDimensions,
      );

      const x = point.x;
      const y = point.y;

      const hoverThreshold = 10;
      let found = false;

      const objects = artboardViewModel.getObjects();

      for (let objIndex = 0; objIndex < objects.length; objIndex++) {
        const obj = objects[objIndex];
        if (artboardViewModel.isPath(obj)) {
          const path = obj as Path;
          const offset = path.scaleOffset;
          for (let vertexIndex = 0; vertexIndex < path.vertices.length; vertexIndex++) {
            const vertex = path.vertices[vertexIndex];
            const dx = x - (vertex.base.x + offset.x);
            const dy = y - (vertex.base.y + offset.y);
            const distance = Math.sqrt(dx * dx + dy * dy);

            if (distance <= hoverThreshold) {
              if (
                artboardViewModel.state.hoveredObjectIndex !== objIndex ||
                artboardViewModel.state.hoveredVertexIndex !== vertexIndex
              ) {
                artboardViewModel.updateHoveredObjectIndex(objIndex);
                artboardViewModel.updateHoveredVertexIndex(vertexIndex);
              }
              found = true;
              break;
            }
          }
          if (found) break;
        }
      }

      if (
        !found &&
        (artboardViewModel.state.hoveredObjectIndex !== null ||
          artboardViewModel.state.hoveredVertexIndex !== null)
      ) {
        artboardViewModel.updateHoveredObjectIndex(null);
        artboardViewModel.updateHoveredVertexIndex(null);
      }
    };

    const handleMouseLeave = () => {
      artboardViewModel.updateHoveredObjectIndex(null);
      artboardViewModel.updateHoveredVertexIndex(null);
    };

    canvas.addEventListener('mousemove', handleMouseMoveHover);
    canvas.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      canvas.removeEventListener('mousemove', handleMouseMoveHover);
      canvas.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [overlayCanvasRef, artboardViewModel]);

  // Canvas rendering
  useEffect(() => {
    const canvas = overlayCanvasRef.current;
    if (!canvas) return;

    const dpr = window.devicePixelRatio || 1;
    const rect = canvas.getBoundingClientRect();
    canvas.width = rect.width * dpr;
    canvas.height = rect.height * dpr;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.scale(dpr, dpr);

    ctx.clearRect(0, 0, rect.width, rect.height);

    const context: ToolContext = {
      canvas: overlayCanvasRef.current!,
      mainCanvas: canvasRef.current!,
      artboardViewModel: artboardViewModel,
      currentPath: currentPath,
      setCurrentPath: setCurrentPath,
    };

    selectedTool.drawOverlay(context, ctx);
  }, [overlayCanvasRef, selectedTool, artboardViewModel, currentPath, renderFrame]);

  return (
    <div
      style={{
        width: '100vw',
        height: '100dvh', // Use dynamic viewport height
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <TopBar
        rightPanelOpen={isRightPanelOpen}
        artboardViewModel={artboardViewModel}
        onExportSVG={downloadSVG}
        onClear={artboardViewModel.deleteSelectedObjects}
        onPlaceImage={onPlaceImage}
        onCollapseRightPanel={() => {
          setMainRenderFrame((prev) => prev + 1);
          setIsRightPanelOpen((prev) => !prev);
        }}
      />

      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            boxSizing: 'border-box',
            marginTop: '24px',
            minHeight: 0,
          }}
        >
          {!showBottomToolbar && (
            <Toolbox
              selectedToolType={selectedToolType}
              switchTool={switchTool}
              isColumn={true}
              isStrokeSelected={artboardViewModel.state.editingStroke}
              strokeColor={artboardViewModel.state.strokeColor}
              fillColor={artboardViewModel.state.fillColor}
              useStrokeColor={artboardViewModel.state.useStrokeColor}
              useFillColor={artboardViewModel.state.useFillColor}
              onSwitchColorType={handleColorTypeChanged}
              onOpenColorSelector={handleWindowOpened}
              onSwitchColor={handleColorSwitched}
              onSwapColors={handleSwapColors}
              onDefault={handleDefaultColors}
            />
          )}

          <div
            style={{
              flex: 1,
              position: 'relative',
              height: '100%',
              minHeight: 0,
            }}
          >
            <canvas
              ref={canvasRef}
              style={{
                touchAction: 'none',
                pointerEvents: 'auto',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 0,
              }}
            />

            <canvas
              ref={overlayCanvasRef}
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1,
              }}
            />

            <MainCanvas
              canvasRef={canvasRef}
              artboardViewModel={artboardViewModel}
              renderFrame={mainRenderFrame}
            />
          </div>

          {isRightPanelOpen && (
            <div
              style={{
                width: '250px',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                boxSizing: 'border-box',
                borderLeft: '2px solid #2d2d2d',
                flexShrink: 0,
                minHeight: 0,
              }}
            >
              <div
                style={{
                  flex: '1 1 0',
                  overflowY: 'auto',
                  boxSizing: 'border-box',
                  borderBottom: '2px solid #2d2d2d',
                  background: '#535353',
                  minHeight: 0,
                }}
              >
                <PropertiesPanel
                  artboardViewModel={artboardViewModel}
                  onOpenColorSelector={handleWindowOpened}
                />
              </div>
              <div
                style={{
                  flex: '1 1 0',
                  overflowY: 'auto',
                  boxSizing: 'border-box',
                  background: '#535353',
                  minHeight: 0,
                }}
              >
                <LayersPanel artboardViewModel={artboardViewModel} />
              </div>
            </div>
          )}
        </div>
        {showBottomToolbar && (
          <div
            style={{
              backgroundColor: '#535353',
              flexShrink: 0,
              boxSizing: 'border-box',
            }}
          >
            <Toolbox
              selectedToolType={selectedToolType}
              switchTool={switchTool}
              isColumn={false}
              isStrokeSelected={artboardViewModel.state.editingStroke}
              strokeColor={artboardViewModel.state.strokeColor}
              fillColor={artboardViewModel.state.fillColor}
              useStrokeColor={artboardViewModel.state.useStrokeColor}
              useFillColor={artboardViewModel.state.useFillColor}
              onSwitchColorType={handleColorTypeChanged}
              onOpenColorSelector={(isStroke) => handleWindowOpened(isStroke)}
              onSwitchColor={handleColorSwitched}
              onSwapColors={handleSwapColors}
              onDefault={handleDefaultColors}
            />
          </div>
        )}
      </div>
      {showWindow && (
        <DraggableWindow
          title={'Color Picker'}
          content={
            <ColorPicker
              previousColor={
                artboardViewModel.state.editingStroke
                  ? artboardViewModel.state.strokeColor ?? ColorValueUtil.BLACK
                  : artboardViewModel.state.fillColor ?? ColorValueUtil.BLACK
              }
              onColorSelected={handleColorSelected}
            />
          }
          onClose={handleWindowClosed}
        />
      )}
    </div>
  );
};

export default Editor;
