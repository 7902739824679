// SelectionTool.ts

import { Path } from '../geometry/Path';
import { Point } from '../math/Point';
import { Vertex } from '../math/Vertex';
import { ImageObject } from '../object/ImageObject';
import { Tool, ToolContext } from './Tool';
import { ScaleOffset } from '../math/ScaleOffset';
import { ArtboardObject } from '../ArtboardObject';
import { NormalizedTouchEvent } from '../../ui/input/MouseTouch';

type DraggingType = null | {
  objectIndex: number;
  initialMousePos: { x: number; y: number };
  initialBoundingBox: BoundingBox;
  initialHandlePositions: HandlePosition[];
  action: 'move' | 'resize';
  handle?: ResizeHandle;
  initialScaleOffset: ScaleOffset;
  initialVertices?: Vertex[];
  currentBoundingBox: BoundingBox;
};

type ResizeHandle = 'nw' | 'n' | 'ne' | 'e' | 'se' | 's' | 'sw' | 'w';

type BoundingBox = {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
};

type HandlePosition = {
  x: number;
  y: number;
  position: ResizeHandle;
};

export class SelectionTool implements Tool {
  private dragging: DraggingType = null;
  private handleSize = 8;

  onMouseDown(event: NormalizedTouchEvent, context: ToolContext): void {
    const { x, y } = this.getMousePos(event, context);
    const { artboardViewModel } = context;
    const selectedObjects = artboardViewModel.getSelectedObjects();

    if (selectedObjects.length > 0) {
      const obj = selectedObjects[0];
      // TODO fix
      const layer = artboardViewModel.findLayerFromObject(obj);
      if (layer?.locked) {
        this.dragging = null;
        artboardViewModel.clearAllSelected();
        return;
      }

      const handle = this.getHandleUnderMouse(event, context, obj);
      if (handle) {
        const boundingBox = this.getObjectBoundingBox(obj);
        const initialHandlePositions = this.getResizeHandles(boundingBox);

        this.dragging = {
          objectIndex: artboardViewModel.getObjects().indexOf(obj),
          initialMousePos: { x, y },
          initialBoundingBox: boundingBox,
          initialHandlePositions,
          action: 'resize',
          handle,
          initialScaleOffset: obj.scaleOffset,
          currentBoundingBox: boundingBox,
        };

        if (artboardViewModel.isPath(obj)) {
          this.dragging.initialVertices = obj.vertices.map((v) => v.clone());
        }

        return;
      }
    }

    // Selection logic
    const objects = artboardViewModel.getObjects();
    const objectData = objects
      .map((obj, index) => {
        if (artboardViewModel.isPath(obj)) {
          const path = obj as Path;
          const boundingBox = this.getObjectBoundingBox(path);

          return {
            object: obj,
            index,
            zIndex: obj.zIndex,
            boundingBox,
          };
        } else if (artboardViewModel.isImageObject(obj)) {
          const imageObj = obj as ImageObject;
          const boundingBox = this.getObjectBoundingBox(imageObj);

          return {
            object: obj,
            index,
            zIndex: obj.zIndex,
            boundingBox,
          };
        } else {
          return null;
        }
      })
      .filter(Boolean) as {
      object: Path | ImageObject;
      index: number;
      zIndex: number;
      boundingBox: BoundingBox;
    }[];

    objectData.sort((a, b) => b.zIndex - a.zIndex);

    for (const data of objectData) {
      // TODO fix
      const layer = artboardViewModel.findLayerFromObject(data.object);
      if (layer?.locked) continue;
      const { index, boundingBox } = data;
      const { minX, minY, maxX, maxY } = boundingBox;

      if (x >= minX && x <= maxX && y >= minY && y <= maxY) {
        artboardViewModel.clearAllSelected();
        artboardViewModel.updateSelected(index, true);

        this.dragging = {
          objectIndex: index,
          initialMousePos: { x, y },
          initialBoundingBox: boundingBox,
          initialHandlePositions: this.getResizeHandles(boundingBox),
          action: 'move',
          initialScaleOffset: artboardViewModel.getObjects()[index].scaleOffset,
          currentBoundingBox: boundingBox,
        };

        return;
      }
    }

    artboardViewModel.clearAllSelected();
    this.dragging = null;
  }

  onMouseMove(event: NormalizedTouchEvent, context: ToolContext): boolean {
    if (!this.dragging) return false;

    const { x, y } = this.getMousePos(event, context);
    const { artboardViewModel } = context;

    const objectIndex = this.dragging.objectIndex;
    const obj = artboardViewModel.getObjects()[objectIndex];

    if (this.dragging.action === 'move') {
      const dx = x - this.dragging.initialMousePos.x;
      const dy = y - this.dragging.initialMousePos.y;

      // Move the object
      const initialScaleOffset = this.dragging.initialScaleOffset;
      obj.scaleOffset = new ScaleOffset(
        initialScaleOffset.x + dx,
        initialScaleOffset.y + dy,
        initialScaleOffset.scaleX,
        initialScaleOffset.scaleY,
      );

      // Update current bounding box
      const boundingBox = this.getObjectBoundingBox(obj);
      this.dragging.currentBoundingBox = boundingBox;

      artboardViewModel.updateObjectAtIndex(objectIndex, obj);
    } else if (this.dragging.action === 'resize') {
      const handle = this.dragging.handle!;
      const { initialBoundingBox } = this.dragging;

      // Calculate new bounding box based on mouse movement
      const newBoundingBox = { ...initialBoundingBox };

      switch (handle) {
        case 'n':
          newBoundingBox.minY = y;
          break;
        case 'ne':
          newBoundingBox.minY = y;
          newBoundingBox.maxX = x;
          break;
        case 'e':
          newBoundingBox.maxX = x;
          break;
        case 'se':
          newBoundingBox.maxX = x;
          newBoundingBox.maxY = y;
          break;
        case 's':
          newBoundingBox.maxY = y;
          break;
        case 'sw':
          newBoundingBox.minX = x;
          newBoundingBox.maxY = y;
          break;
        case 'w':
          newBoundingBox.minX = x;
          break;
        case 'nw':
          newBoundingBox.minX = x;
          newBoundingBox.minY = y;
          break;
      }

      // Adjust newBoundingBox to maintain square proportions if shift key is pressed
      if (
        event.shiftKey &&
        (handle === 'nw' || handle === 'ne' || handle === 'se' || handle === 'sw')
      ) {
        const width = newBoundingBox.maxX - newBoundingBox.minX;
        const height = newBoundingBox.maxY - newBoundingBox.minY;

        const size = Math.max(Math.abs(width), Math.abs(height));

        switch (handle) {
          case 'ne':
            if (Math.abs(width) >= Math.abs(height)) {
              newBoundingBox.minY = newBoundingBox.maxY - size * Math.sign(height);
            } else {
              newBoundingBox.maxX = newBoundingBox.minX + size * Math.sign(width);
            }
            break;
          case 'se':
            if (Math.abs(width) >= Math.abs(height)) {
              newBoundingBox.maxY = newBoundingBox.minY + size * Math.sign(height);
            } else {
              newBoundingBox.maxX = newBoundingBox.minX + size * Math.sign(width);
            }
            break;
          case 'sw':
            if (Math.abs(width) >= Math.abs(height)) {
              newBoundingBox.maxY = newBoundingBox.minY + size * Math.sign(height);
            } else {
              newBoundingBox.minX = newBoundingBox.maxX - size * Math.sign(width);
            }
            break;
          case 'nw':
            if (Math.abs(width) >= Math.abs(height)) {
              newBoundingBox.minY = newBoundingBox.maxY - size * Math.sign(height);
            } else {
              newBoundingBox.minX = newBoundingBox.maxX - size * Math.sign(width);
            }
            break;
        }
      }

      // Update current bounding box
      this.dragging.currentBoundingBox = newBoundingBox;

      // Apply transformation to the object in real-time
      const initialWidth = initialBoundingBox.maxX - initialBoundingBox.minX;
      const initialHeight = initialBoundingBox.maxY - initialBoundingBox.minY;

      const newWidth = newBoundingBox.maxX - newBoundingBox.minX;
      const newHeight = newBoundingBox.maxY - newBoundingBox.minY;

      const sx = initialWidth !== 0 ? newWidth / initialWidth : 1;
      const sy = initialHeight !== 0 ? newHeight / initialHeight : 1;

      const initialScaleOffset = this.dragging.initialScaleOffset;

      // Adjust translation to include object's initial offset
      const tx =
        newBoundingBox.minX -
        (initialBoundingBox.minX - initialScaleOffset.x) * sx -
        initialScaleOffset.x;
      const ty =
        newBoundingBox.minY -
        (initialBoundingBox.minY - initialScaleOffset.y) * sy -
        initialScaleOffset.y;

      if (artboardViewModel.isImageObject(obj)) {
        // Update scale and position for images
        const newScaleX = initialScaleOffset.scaleX * sx;
        const newScaleY = initialScaleOffset.scaleY * sy;
        const newX = initialScaleOffset.x + tx;
        const newY = initialScaleOffset.y + ty;

        obj.scaleOffset = new ScaleOffset(newX, newY, newScaleX, newScaleY);
      } else if (artboardViewModel.isPath(obj)) {
        // Update vertices for paths
        const initialVertices = this.dragging.initialVertices!;

        obj.vertices = initialVertices.map((v) => {
          const newBaseX = v.base.x * sx + tx + initialScaleOffset.x;
          const newBaseY = v.base.y * sy + ty + initialScaleOffset.y;

          const newControlLeft = v.controlLeft
            ? new Point(
                v.controlLeft.x * sx + tx + initialScaleOffset.x,
                v.controlLeft.y * sy + ty + initialScaleOffset.y,
              )
            : null;

          const newControlRight = v.controlRight
            ? new Point(
                v.controlRight.x * sx + tx + initialScaleOffset.x,
                v.controlRight.y * sy + ty + initialScaleOffset.y,
              )
            : null;

          return new Vertex(
            new Point(newBaseX, newBaseY),
            newControlLeft,
            newControlRight,
            v.linkedHandles,
          );
        });

        // Update position
        obj.scaleOffset = new ScaleOffset(0, 0);
      }

      artboardViewModel.updateObjectAtIndex(objectIndex, obj);
    }

    // Return true to indicate that the canvas needs to be re-rendered
    return true;
  }

  onMouseUp(event: NormalizedTouchEvent, context: ToolContext): void {
    this.dragging = null;
  }

  drawOverlay(context: ToolContext, ctx: CanvasRenderingContext2D): void {
    const { canvas, artboardViewModel } = context;
    const selectedObjects = artboardViewModel.getSelectedObjects();

    if (selectedObjects.length == 0) return;

    const obj = selectedObjects[0];

    const scaleOffset = artboardViewModel.state.scaleOffset;
    const artboardDimensions = artboardViewModel.state.boardDimensions;

    const rect = canvas.getBoundingClientRect();

    ctx.save();

    ctx.translate(scaleOffset.x, scaleOffset.y);
    ctx.scale(scaleOffset.scaleX, scaleOffset.scaleX);

    const xOffset = (rect.width / scaleOffset.scaleX - artboardDimensions.w) / 2;
    const yOffset = (rect.height / scaleOffset.scaleX - artboardDimensions.h) / 2;

    ctx.translate(xOffset, yOffset);

    let boundingBox: BoundingBox;

    if (this.dragging && this.dragging.action === 'resize') {
      boundingBox = this.dragging.currentBoundingBox;
    } else {
      boundingBox = this.getObjectBoundingBox(obj);
    }

    // Draw the bounding box
    ctx.strokeStyle = '#0000FF';
    ctx.lineWidth = 1 / scaleOffset.scaleX;
    ctx.setLineDash([4 / scaleOffset.scaleX, 2 / scaleOffset.scaleX]);
    ctx.strokeRect(
      boundingBox.minX,
      boundingBox.minY,
      boundingBox.maxX - boundingBox.minX,
      boundingBox.maxY - boundingBox.minY,
    );
    ctx.setLineDash([]);

    // Draw resize handles
    const handles = this.getResizeHandles(boundingBox);
    ctx.fillStyle = '#FFFFFF';
    ctx.strokeStyle = '#000000';
    ctx.lineWidth = 1 / scaleOffset.scaleX;

    const handleSize = this.handleSize / scaleOffset.scaleX;

    handles.forEach((handle) => {
      ctx.beginPath();
      ctx.rect(handle.x - handleSize / 2, handle.y - handleSize / 2, handleSize, handleSize);
      ctx.fill();
      ctx.stroke();
    });

    ctx.restore();
  }

  private getMousePos(event: NormalizedTouchEvent, context: ToolContext): { x: number; y: number } {
    const rect = context.canvas.getBoundingClientRect();
    const scaleOffset = context.artboardViewModel.state.scaleOffset;
    const artboardDimensions = context.artboardViewModel.state.boardDimensions;

    const point = ScaleOffset.screenToCanvasPoint(
      event.x,
      event.y,
      rect,
      scaleOffset,
      artboardDimensions,
    );

    return { x: point.x, y: point.y };
  }

  private getObjectBoundingBox(obj: ArtboardObject): BoundingBox {
    if ('vertices' in obj) {
      const path = obj as Path;
      const boundingBox = this.calculateBezierBoundingBox(path);

      const minX = boundingBox.minX + path.scaleOffset.x;
      const minY = boundingBox.minY + path.scaleOffset.y;
      const maxX = boundingBox.maxX + path.scaleOffset.x;
      const maxY = boundingBox.maxY + path.scaleOffset.y;

      return { minX, minY, maxX, maxY };
    } else if ('image' in obj) {
      const imageObj = obj as ImageObject;
      const minX = imageObj.scaleOffset.x;
      const minY = imageObj.scaleOffset.y;
      const maxX = imageObj.scaleOffset.x + imageObj.image.width * imageObj.scaleOffset.scaleX;
      const maxY = imageObj.scaleOffset.y + imageObj.image.height * imageObj.scaleOffset.scaleY;

      return { minX, minY, maxX, maxY };
    } else {
      return { minX: 0, minY: 0, maxX: 0, maxY: 0 };
    }
  }

  private calculateBezierBoundingBox(path: Path): BoundingBox {
    const { vertices } = path;

    if (vertices.length === 0) {
      return { minX: 0, minY: 0, maxX: 0, maxY: 0 };
    }

    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    for (let i = 0; i < vertices.length; i++) {
      const vertex = vertices[i];
      const basePoint = vertex.base;

      minX = Math.min(minX, basePoint.x);
      minY = Math.min(minY, basePoint.y);
      maxX = Math.max(maxX, basePoint.x);
      maxY = Math.max(maxY, basePoint.y);

      let nextVertex: Vertex | null = null;
      if (i < vertices.length - 1) {
        nextVertex = vertices[i + 1];
      } else if (path.closed) {
        nextVertex = vertices[0];
      }

      if (nextVertex) {
        const p0 = vertex.base;
        const p1 = vertex.controlRight || vertex.base;
        const p2 = nextVertex.controlLeft || nextVertex.base;
        const p3 = nextVertex.base;

        const bbox = this.getCubicBezierBoundingBox(p0, p1, p2, p3);

        minX = Math.min(minX, bbox.minX);
        minY = Math.min(minY, bbox.minY);
        maxX = Math.max(maxX, bbox.maxX);
        maxY = Math.max(maxY, bbox.maxY);
      }
    }

    return { minX, minY, maxX, maxY };
  }

  private getCubicBezierBoundingBox(p0: Point, p1: Point, p2: Point, p3: Point): BoundingBox {
    const tValues = [];
    const xValues = [p0.x, p3.x];
    const yValues = [p0.y, p3.y];

    for (let i = 0; i <= 1; i++) {
      const b =
        6 * (i === 0 ? p0.x : p0.y) - 12 * (i === 0 ? p1.x : p1.y) + 6 * (i === 0 ? p2.x : p2.y);
      const a =
        -3 * (i === 0 ? p0.x : p0.y) +
        9 * (i === 0 ? p1.x : p1.y) -
        9 * (i === 0 ? p2.x : p2.y) +
        3 * (i === 0 ? p3.x : p3.y);
      const c = 3 * (i === 0 ? p1.x : p1.y) - 3 * (i === 0 ? p0.x : p0.y);

      if (Math.abs(a) < 1e-12) {
        if (Math.abs(b) < 1e-12) continue;
        const t = -c / b;
        if (t > 0 && t < 1) tValues.push(t);
        continue;
      }

      const b2ac = b * b - 4 * c * a;
      if (b2ac < 0) continue;
      const sqrtb2ac = Math.sqrt(b2ac);

      const t1 = (-b + sqrtb2ac) / (2 * a);
      if (t1 > 0 && t1 < 1) tValues.push(t1);

      const t2 = (-b - sqrtb2ac) / (2 * a);
      if (t2 > 0 && t2 < 1) tValues.push(t2);
    }

    for (let j = 0; j < tValues.length; j++) {
      const t = tValues[j];
      const mt = 1 - t;
      const x =
        mt * mt * mt * p0.x + 3 * mt * mt * t * p1.x + 3 * mt * t * t * p2.x + t * t * t * p3.x;
      const y =
        mt * mt * mt * p0.y + 3 * mt * mt * t * p1.y + 3 * mt * t * t * p2.y + t * t * t * p3.y;

      xValues.push(x);
      yValues.push(y);
    }

    const minX = Math.min(...xValues);
    const minY = Math.min(...yValues);
    const maxX = Math.max(...xValues);
    const maxY = Math.max(...yValues);

    return { minX, minY, maxX, maxY };
  }

  private getResizeHandles(boundingBox: BoundingBox): HandlePosition[] {
    const { minX, minY, maxX, maxY } = boundingBox;
    const midX = (minX + maxX) / 2;
    const midY = (minY + maxY) / 2;

    return [
      { x: minX, y: minY, position: 'nw' },
      { x: midX, y: minY, position: 'n' },
      { x: maxX, y: minY, position: 'ne' },
      { x: maxX, y: midY, position: 'e' },
      { x: maxX, y: maxY, position: 'se' },
      { x: midX, y: maxY, position: 's' },
      { x: minX, y: maxY, position: 'sw' },
      { x: minX, y: midY, position: 'w' },
    ];
  }

  private getHandleUnderMouse(
    event: NormalizedTouchEvent,
    context: ToolContext,
    obj: ArtboardObject,
  ): ResizeHandle | null {
    const { x, y } = this.getMousePos(event, context);
    const boundingBox = this.getObjectBoundingBox(obj);
    const handles = this.getResizeHandles(boundingBox);

    const scaleOffset = context.artboardViewModel.state.scaleOffset;
    const handleSize = this.handleSize / scaleOffset.scaleX;

    for (const handle of handles) {
      const hx = handle.x;
      const hy = handle.y;

      if (
        x >= hx - handleSize / 2 &&
        x <= hx + handleSize / 2 &&
        y >= hy - handleSize / 2 &&
        y <= hy + handleSize / 2
      ) {
        return handle.position as ResizeHandle;
      }
    }

    return null;
  }
}
