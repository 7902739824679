import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

interface PercentEntryWidgetProps {
  initialValue?: number;
  onChange: (value: number) => void;
}

const PercentEntryWidget: React.FC<PercentEntryWidgetProps> = ({ initialValue = 0, onChange }) => {
  const [value, setValue] = useState<number>(clamp(initialValue, 0, 100));
  const [inputValue, setInputValue] = useState<string>(formatValue(clamp(initialValue, 0, 100)));
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false); // New state for focus

  const wrapperRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // Store the last valid value to revert if input is invalid
  const lastValidValueRef = useRef<number>(clamp(initialValue, 0, 100));

  // Update inputValue when value changes
  useEffect(() => {
    setInputValue(formatValue(value));
    lastValidValueRef.current = value;
  }, [value]);

  // Handle clicks outside the popup and wrapper to close it
  useEffect(() => {
    if (!isPopupOpen) return;

    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(target) &&
        popupRef.current &&
        !popupRef.current.contains(target)
      ) {
        setIsPopupOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPopupOpen]);

  // Clamp a number between min and max
  function clamp(num: number, min: number, max: number): number {
    return Math.min(Math.max(num, min), max);
  }

  // Format the number with a % symbol
  function formatValue(num: number): string {
    return `${num}%`;
  }

  // Parse input string to number with stricter validation
  function parseInput(input: string): number | null {
    const regex = /^(\d{1,3})(%)?$/;
    const match = input.trim().match(regex);
    if (!match) return null;
    const num = parseInt(match[1], 10);
    if (isNaN(num) || num < 0 || num > 100) return null;
    return num;
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputBlur = () => {
    const parsedValue = parseInput(inputValue);
    if (parsedValue !== null) {
      setValue(parsedValue);
      onChange(parsedValue);
    } else {
      // Revert to last valid value
      setInputValue(formatValue(lastValidValueRef.current));
    }
    setIsInputFocused(false); // Update focus state
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const parsedValue = parseInput(inputValue);
      if (parsedValue !== null) {
        setValue(parsedValue);
        onChange(parsedValue);
      } else {
        // Revert to last valid value
        setInputValue(formatValue(lastValidValueRef.current));
      }
      setIsPopupOpen(false);
    }
  };

  const togglePopup = () => {
    setIsPopupOpen((prev) => !prev);
  };

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = clamp(parseInt(e.target.value, 10), 0, 100);
    setValue(newValue);
    onChange(newValue);
  };

  // Handle focus on the input field to close the popup if open
  const handleInputFocus = () => {
    if (isPopupOpen) {
      setIsPopupOpen(false);
    }
    setIsInputFocused(true); // Update focus state
  };

  // Calculate the position for the popup
  const [popupStyles, setPopupStyles] = useState<React.CSSProperties>({});

  useEffect(() => {
    if (isPopupOpen && inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      setPopupStyles({
        position: 'absolute',
        top: rect.bottom + window.scrollY + 2, // 5px below the input
        left: rect.left + window.scrollX,
        padding: '8px',
        boxSizing: 'border-box',
        background: '#666666',
        border: '1px solid #333',
        boxShadow: '0px 4px 8px rgba(0,0,0,0.2)',
        zIndex: 1000,
        borderRadius: '4px',
        width: '100px',
      });
    }
  }, [isPopupOpen]);

  // Render the popup using a portal
  const popup = isPopupOpen
    ? ReactDOM.createPortal(
        <div ref={popupRef} style={popupStyles}>
          <input
            type="range"
            min="0"
            max="100"
            value={value}
            onChange={handleSliderChange}
            style={{
              width: '100%',
              height: '25px',
              background: '#ddd',
              borderRadius: '5px',
              outline: 'none',
            }}
          />
          {/* Custom Slider Thumb */}
          <style></style>
        </div>,
        document.body,
      )
    : null;

  return (
    <div ref={wrapperRef} style={{ position: 'relative', display: 'inline-block' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyDown}
          onFocus={handleInputFocus} // Attached onFocus handler
          style={{
            height: '24px',
            width: '76px',
            boxSizing: 'border-box',
            backgroundColor: isInputFocused ? 'white' : '#666666',
            borderLeft: isInputFocused ? '1px solid blue' : '1px solid grey',
            borderTop: isInputFocused ? '1px solid blue' : '1px solid grey',
            borderBottom: isInputFocused ? '1px solid blue' : '1px solid grey',
            borderRight: 'none',
            color: isInputFocused ? 'black' : 'white',
          }}
        />
        <div
          onClick={togglePopup}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '24px',
            fontSize: '1.05rem',
            background: isPopupOpen ? '#333333' : '#666666',
            border: isInputFocused ? '1px solid blue' : '1px solid grey',
            cursor: 'pointer',
            boxSizing: 'border-box',
            padding: '2px 4px',
            color: 'white',
          }}
          aria-label="Open slider"
        >
          &#8594;
        </div>
      </div>
      {popup}
    </div>
  );
};

export default PercentEntryWidget;
