// MainCanvas.tsx

import React, { useEffect } from 'react';
import { Path } from '../lib/geometry/Path';
import { CapTypeUtils } from '../lib/geometry/CapType';
import { CornerTypeUtils } from '../lib/geometry/CornerType';
import { ImageObject } from '../lib/object/ImageObject';
import { ArtboardViewModel } from '../viewmodel/ArtboardViewModel';
import { MouseTouch } from './input/MouseTouch';

interface MainCanvasProps {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  artboardViewModel: ArtboardViewModel;
  renderFrame: number;
}

const MainCanvas: React.FC<MainCanvasProps> = ({ canvasRef, artboardViewModel, renderFrame }) => {
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const dpr = window.devicePixelRatio || 1;
    const rect = canvas.getBoundingClientRect();

    // Set the canvas size based on device pixel ratio
    canvas.width = rect.width * dpr;
    canvas.height = rect.height * dpr;

    // Reset transformations and scale for high DPI displays
    ctx.resetTransform();
    ctx.scale(dpr, dpr);

    // Clear the canvas
    ctx.clearRect(0, 0, rect.width, rect.height);

    // Apply pan and zoom transformations
    const scaleOffset = artboardViewModel.state.scaleOffset;
    ctx.save();
    ctx.translate(scaleOffset.x, scaleOffset.y);
    ctx.scale(scaleOffset.scaleX, scaleOffset.scaleX);

    // Center the artboard within the canvas
    const xOffset =
      (rect.width / scaleOffset.scaleX - artboardViewModel.state.boardDimensions.w) / 2;
    const yOffset =
      (rect.height / scaleOffset.scaleX - artboardViewModel.state.boardDimensions.h) / 2;

    ctx.translate(xOffset, yOffset);

    // Draw the artboard background
    ctx.fillStyle = '#FFFFFF'; // White background
    ctx.fillRect(
      0,
      0,
      artboardViewModel.state.boardDimensions.w,
      artboardViewModel.state.boardDimensions.h,
    );
    ctx.strokeStyle = '#000000';
    ctx.strokeRect(
      0,
      0,
      artboardViewModel.state.boardDimensions.w,
      artboardViewModel.state.boardDimensions.h,
    );

    // Get objects from layers
    const objects = artboardViewModel.getObjects();

    // Draw objects
    objects.forEach((object) => {
      // TODO fix this...
      const layer = artboardViewModel.findLayerFromObject(object);
      if (!layer?.visible) return;
      if ('vertices' in object) {
        // It's a Path
        const path = object as Path;
        const {
          vertices,
          fill,
          strokeWidth,
          scaleOffset,
          strokeColor,
          closed,
          cap,
          corner,
          opacity,
          fillOpacity,
          strokeOpacity,
        } = path;

        if (vertices.length > 0) {
          ctx.beginPath();
          const firstVertex = vertices[0];
          ctx.moveTo(firstVertex.base.x + scaleOffset.x, firstVertex.base.y + scaleOffset.y);

          for (let i = 1; i < vertices.length; i++) {
            const prevVertex = vertices[i - 1];
            const currVertex = vertices[i];

            // Use base point if control point is null
            const cp1 = prevVertex.controlRight || prevVertex.base;
            const cp2 = currVertex.controlLeft || currVertex.base;

            const cp1X = cp1.x + scaleOffset.x;
            const cp1Y = cp1.y + scaleOffset.y;
            const cp2X = cp2.x + scaleOffset.x;
            const cp2Y = cp2.y + scaleOffset.y;
            const baseX = currVertex.base.x + scaleOffset.x;
            const baseY = currVertex.base.y + scaleOffset.y;

            // Always use bezierCurveTo
            ctx.bezierCurveTo(cp1X, cp1Y, cp2X, cp2Y, baseX, baseY);
          }

          // If the path is closed, add a segment back to the starting point
          if (closed) {
            const lastVertex = vertices[vertices.length - 1];
            const cp1 = lastVertex.controlRight || lastVertex.base;
            const cp2 = firstVertex.controlLeft || firstVertex.base;

            const cp1X = cp1.x + scaleOffset.x;
            const cp1Y = cp1.y + scaleOffset.y;
            const cp2X = cp2.x + scaleOffset.x;
            const cp2Y = cp2.y + scaleOffset.y;
            const baseX = firstVertex.base.x + scaleOffset.x;
            const baseY = firstVertex.base.y + scaleOffset.y;

            ctx.bezierCurveTo(cp1X, cp1Y, cp2X, cp2Y, baseX, baseY);

            ctx.closePath();
          }

          ctx.fillStyle = fill || 'transparent';
          ctx.strokeStyle = strokeColor || '#000000';
          ctx.lineWidth = strokeWidth || 1;
          ctx.lineCap = CapTypeUtils.toCanvasLineCap(cap);
          ctx.lineJoin = CornerTypeUtils.toCanvasLineJoin(corner);

          ctx.globalAlpha = opacity * fillOpacity;
          ctx.fill();
          ctx.globalAlpha = opacity * strokeOpacity;
          ctx.stroke();
          ctx.globalAlpha = 1;
        }
      } else if ('image' in object) {
        // It's an ImageObject
        const imageObject = object as ImageObject;
        const { image, scaleOffset } = imageObject;

        ctx.globalAlpha = imageObject.opacity;
        ctx.drawImage(
          image,
          scaleOffset.x,
          scaleOffset.y,
          image.width * scaleOffset.scaleX,
          image.height * scaleOffset.scaleY,
        );
        ctx.globalAlpha = 1;
      }
    });

    ctx.restore();
  }, [canvasRef, artboardViewModel.state, renderFrame]);

  // Event handlers for zooming and panning
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    let isMouseDown = false;
    let startX = 0;
    let startY = 0;

    let isTwoFingerGesture = false;
    let initialDistance = 0;
    let initialMidpoint = { x: 0, y: 0 };
    let initialScale = 1;
    let initialPan = { x: 0, y: 0 };

    // Handle wheel events for zooming and panning
    const handleWheel = (event: WheelEvent) => {
      const scaleOffset = artboardViewModel.state.scaleOffset;
      const rect = canvas.getBoundingClientRect();

      // Check if the mouse is over the canvas
      const withinCanvas =
        event.clientX >= rect.left &&
        event.clientX <= rect.right &&
        event.clientY >= rect.top &&
        event.clientY <= rect.bottom;

      if (!withinCanvas) return; // Do nothing if not over canvas

      event.preventDefault(); // Prevent default browser behavior (e.g., page zooming)

      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;

      const isZooming = event.ctrlKey || event.metaKey;

      if (isZooming) {
        // Zoom towards the cursor position
        const delta = -event.deltaY;
        const zoomIntensity = 0.009; // Adjust as needed
        const zoom = Math.exp(delta * zoomIntensity);
        const newScale = scaleOffset.scaleX * zoom;

        // Clamp scale
        const clampedScale = Math.max(0.1, Math.min(newScale, 10));

        // Center the artboard within the canvas (both old and new scales)
        const xOffsetOld =
          (rect.width / scaleOffset.scaleX - artboardViewModel.state.boardDimensions.w) / 2;
        const yOffsetOld =
          (rect.height / scaleOffset.scaleX - artboardViewModel.state.boardDimensions.h) / 2;

        const xOffsetNew =
          (rect.width / clampedScale - artboardViewModel.state.boardDimensions.w) / 2;
        const yOffsetNew =
          (rect.height / clampedScale - artboardViewModel.state.boardDimensions.h) / 2;

        // Compute the canvas coordinates of the point under the cursor before zooming
        const canvasX = (mouseX - scaleOffset.x) / scaleOffset.scaleX - xOffsetOld;
        const canvasY = (mouseY - scaleOffset.y) / scaleOffset.scaleX - yOffsetOld;

        // Compute the new position of the point under the cursor after zooming
        const newScreenX = (canvasX + xOffsetNew) * clampedScale + scaleOffset.x;
        const newScreenY = (canvasY + yOffsetNew) * clampedScale + scaleOffset.y;

        // Compute the translation needed to keep the point under the cursor stationary
        const newTranslateX = scaleOffset.x + (mouseX - newScreenX);
        const newTranslateY = scaleOffset.y + (mouseY - newScreenY);

        // Update scale and translation
        artboardViewModel.updateZoomScale(clampedScale);
        artboardViewModel.updatePanX(newTranslateX);
        artboardViewModel.updatePanY(newTranslateY);
      } else {
        // Panning
        const newTranslateX = scaleOffset.x - event.deltaX;
        const newTranslateY = scaleOffset.y - event.deltaY;

        artboardViewModel.updatePanX(newTranslateX);
        artboardViewModel.updatePanY(newTranslateY);
      }
    };

    // Handle mouse down for panning
    const handleMouseDown = (event: MouseEvent) => {
      if (event.button !== 0) return; // Only respond to left-click
      isMouseDown = true;
      const p = MouseTouch.getCoordinates(event);
      startX = p.x;
      startY = p.y;
    };

    // Handle mouse move for panning
    const handleMouseMove = (event: MouseEvent) => {
      if (!isMouseDown) return;
      const p = MouseTouch.getCoordinates(event);
      const dx = p.x - startX;
      const dy = p.y - startY;

      const newTranslateX = artboardViewModel.state.scaleOffset.x + dx;
      const newTranslateY = artboardViewModel.state.scaleOffset.y + dy;

      artboardViewModel.updatePanX(newTranslateX);
      artboardViewModel.updatePanY(newTranslateY);

      startX = p.x;
      startY = p.y;
    };

    // Handle mouse up to stop panning
    const handleMouseUp = () => {
      isMouseDown = false;
    };

    // Handle touch start
    const handleTouchStart = (event: TouchEvent) => {
      if (event.touches.length === 1) {
        // Single touch, start panning
        // isMouseDown = true;
        // const touch = event.touches[0];
        // startX = touch.clientX;
        // startY = touch.clientY;
      } else if (event.touches.length === 2) {
        // Two-finger gesture, will be handled in touchmove
        isTwoFingerGesture = false; // Will be set to true in touchmove
        isMouseDown = false; // Disable single touch panning
      }
    };

    // Handle touch move
    const handleTouchMove = (event: TouchEvent) => {
      event.preventDefault(); // Prevent scrolling on touchmove
      if (event.touches.length === 2) {
        // Handle two-finger gesture for pan and zoom
        const touch1 = event.touches[0];
        const touch2 = event.touches[1];

        // Calculate the current distance between the two touches
        const currentDistance = Math.hypot(
          touch2.clientX - touch1.clientX,
          touch2.clientY - touch1.clientY,
        );

        // Calculate the current midpoint between the two touches
        const currentMidpoint = {
          x: (touch1.clientX + touch2.clientX) / 2,
          y: (touch1.clientY + touch2.clientY) / 2,
        };

        if (!isTwoFingerGesture) {
          // First time two fingers are detected, initialize the variables
          isTwoFingerGesture = true;
          initialDistance = currentDistance;
          initialMidpoint = currentMidpoint;
          initialScale = artboardViewModel.state.scaleOffset.scaleX;
          initialPan = {
            x: artboardViewModel.state.scaleOffset.x,
            y: artboardViewModel.state.scaleOffset.y,
          };
        } else {
          // Calculate scale factor
          const scaleFactor = currentDistance / initialDistance;
          const newScale = initialScale * scaleFactor;

          // Clamp scale
          const clampedScale = Math.max(0.1, Math.min(newScale, 10));

          // Calculate pan offset
          const dx = currentMidpoint.x - initialMidpoint.x;
          const dy = currentMidpoint.y - initialMidpoint.y;

          const newTranslateX = initialPan.x + dx;
          const newTranslateY = initialPan.y + dy;

          // Update scale and pan
          artboardViewModel.updateZoomScale(clampedScale);
          artboardViewModel.updatePanX(newTranslateX);
          artboardViewModel.updatePanY(newTranslateY);
        }
      }
    };

    // Handle touch end
    const handleTouchEnd = (event: TouchEvent) => {
      if (event.touches.length === 0) {
        // All touches removed
        isMouseDown = false;
        isTwoFingerGesture = false;
      } else if (event.touches.length < 2) {
        isTwoFingerGesture = false;
      }
    };

    // Add event listeners
    window.addEventListener('wheel', handleWheel, { passive: false });
    canvas.addEventListener('mousedown', handleMouseDown);
    canvas.addEventListener('mousemove', handleMouseMove);
    canvas.addEventListener('mouseup', handleMouseUp);
    canvas.addEventListener('mouseleave', handleMouseUp);

    canvas.addEventListener('touchstart', handleTouchStart, { passive: false });
    window.addEventListener('touchmove', handleTouchMove, { passive: false });
    canvas.addEventListener('touchend', handleTouchEnd);

    return () => {
      // Remove event listeners on cleanup
      window.removeEventListener('wheel', handleWheel);
      canvas.removeEventListener('mousedown', handleMouseDown);
      canvas.removeEventListener('mousemove', handleMouseMove);
      canvas.removeEventListener('mouseup', handleMouseUp);
      canvas.removeEventListener('mouseleave', handleMouseUp);

      canvas.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
      canvas.removeEventListener('touchend', handleTouchEnd);
    };
  }, [canvasRef, artboardViewModel.state]);

  return null;
};

export default MainCanvas;
