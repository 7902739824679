import { useState, useEffect } from 'react';

const useIsPortrait = (): boolean => {
  const getIsPortrait = () => window.matchMedia('(orientation: portrait)').matches;

  const [isPortrait, setIsPortrait] = useState(getIsPortrait);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(getIsPortrait());
    };

    const mediaQuery = window.matchMedia('(orientation: portrait)');
    mediaQuery.addEventListener('change', handleOrientationChange);

    return () => {
      mediaQuery.removeEventListener('change', handleOrientationChange);
    };
  }, []);

  return isPortrait;
};

export default useIsPortrait;
