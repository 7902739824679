// SvgRenderer.ts

import { CapTypeUtils } from '../geometry/CapType';
import { CornerTypeUtils } from '../geometry/CornerType';
import { ArtboardViewModel } from '../../viewmodel/ArtboardViewModel';
import { Path } from '../geometry/Path';
import { ImageObject } from '../object/ImageObject';

export class SvgRenderer {
  constructor(private artboardViewModel: ArtboardViewModel) {}

  public render(): string {
    const { w: width, h: height } = this.artboardViewModel.state.boardDimensions;

    // Start SVG string with xlink namespace
    let svg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">\n`;

    // Get objects from layers
    const objects = this.artboardViewModel.getObjects();

    // Sort objects by zIndex
    const sortedObjects = objects.sort((a, b) => a.zIndex - b.zIndex);

    for (const object of sortedObjects) {
      // TODO fix this...
      const layer = this.artboardViewModel.findLayerFromObject(object);
      if (!layer?.visible) continue;
      if (this.artboardViewModel.isPath(object)) {
        const path = object as Path;
        const {
          vertices,
          fill,
          strokeWidth,
          scaleOffset,
          strokeColor,
          closed,
          cap,
          corner,
          opacity,
          fillOpacity,
          strokeOpacity,
        } = path;
        // Begin path data
        let pathData = '';

        if (vertices.length > 0) {
          const firstVertex = vertices[0];
          pathData += `M ${firstVertex.base.x + scaleOffset.x} ${
            firstVertex.base.y + scaleOffset.y
          } `;

          for (let i = 1; i < vertices.length; i++) {
            const prevVertex = vertices[i - 1];
            const currVertex = vertices[i];

            // Use base point if control point is null
            const cp1 = prevVertex.controlRight || prevVertex.base;
            const cp2 = currVertex.controlLeft || currVertex.base;

            const cp1X = cp1.x + scaleOffset.x;
            const cp1Y = cp1.y + scaleOffset.y;
            const cp2X = cp2.x + scaleOffset.x;
            const cp2Y = cp2.y + scaleOffset.y;
            const baseX = currVertex.base.x + scaleOffset.x;
            const baseY = currVertex.base.y + scaleOffset.y;

            // Always use Cubic Bezier curve
            pathData += `C ${cp1X} ${cp1Y}, ${cp2X} ${cp2Y}, ${baseX} ${baseY} `;
          }

          // If the path is closed, add a segment back to the starting point
          if (closed) {
            const lastVertex = vertices[vertices.length - 1];
            const cp1 = lastVertex.controlRight || lastVertex.base;
            const cp2 = firstVertex.controlLeft || firstVertex.base;

            const cp1X = cp1.x + scaleOffset.x;
            const cp1Y = cp1.y + scaleOffset.y;
            const cp2X = cp2.x + scaleOffset.x;
            const cp2Y = cp2.y + scaleOffset.y;
            const baseX = firstVertex.base.x + scaleOffset.x;
            const baseY = firstVertex.base.y + scaleOffset.y;

            // Close the path using a Cubic Bezier curve
            pathData += `C ${cp1X} ${cp1Y}, ${cp2X} ${cp2Y}, ${baseX} ${baseY} `;
            pathData += 'Z'; // Close the path
          }
        }

        // Create path element
        svg += `  <path d="${pathData.trim()}" stroke="${strokeColor}" fill="${fill}" stroke-width="${strokeWidth}" stroke-linecap="${CapTypeUtils.toCanvasLineCap(
          cap,
        )}" stroke-linejoin="${CornerTypeUtils.toCanvasLineJoin(
          corner,
        )}" opacity="${opacity}" fill-opacity="${fillOpacity}" stroke-opacity="${strokeOpacity}"/>\n`;
      } else if (this.artboardViewModel.isImageObject(object)) {
        const imageObject = object as ImageObject;
        const { image, scaleOffset, opacity = 1 } = imageObject;

        // Convert image to data URI
        const dataUrl = this.getImageDataUrl(image);

        // Create image element using xlink:href
        svg += `  <image x="${scaleOffset.x}" y="${scaleOffset.y}" width="${image.width}" height="${image.height}" xlink:href="${dataUrl}" opacity="${opacity}" transform="scale(${scaleOffset.scaleX} ${scaleOffset.scaleY})"/>\n`;
      }
    }

    // Close SVG string
    svg += '</svg>';

    return svg;
  }

  private getImageDataUrl(image: HTMLImageElement): string {
    // Create a canvas to draw the image and get the data URL
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;

    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.drawImage(image, 0, 0);
      const dataUrl = canvas.toDataURL('image/png');
      return dataUrl;
    }
    return '';
  }
}
