export class Point {
  constructor(
    public readonly x: number,
    public readonly y: number,
  ) {}

  clone(): Point {
    return new Point(this.x, this.y);
  }

  distanceTo(other: Point): number {
    const dx = this.x - other.x;
    const dy = this.y - other.y;
    return Math.sqrt(dx * dx + dy * dy);
  }
}
