/* eslint-disable no-case-declarations */
// src/components/ColorPicker.tsx

import React, { useState } from 'react';
import chroma from 'chroma-js';
import GradientSelector from './GradientSelector';
import { ColorValues, ColorValueUtil } from '../../lib/color/ColorValues';

interface InputValues {
  H: string;
  S: string;
  B: string;
  R: string;
  G: string;
  B2: string;
  C: string;
  M: string;
  Y: string;
  K: string;
  hex: string;
}

type Props = {
  previousColor: ColorValues;
  onColorSelected: (color: ColorValues) => void;
};

const ColorPicker: React.FC<Props> = ({ previousColor, onColorSelected }) => {
  const [colorValues, setColorValues] = useState<ColorValues>(previousColor);
  const [focused, setFocused] = useState<string | null>(null);

  const [inputValues, setInputValues] = useState<InputValues>({
    H: `${previousColor.H}°`,
    S: `${Math.round(previousColor.S * 100)}%`,
    B: `${Math.round(previousColor.B * 100)}%`,
    R: previousColor.R.toString(),
    G: previousColor.G.toString(),
    B2: previousColor.B2.toString(),
    C: `${previousColor.C}%`,
    M: `${previousColor.M}%`,
    Y: `${previousColor.Y}%`,
    K: `${previousColor.K}%`,
    hex: previousColor.hex.replace('#', ''),
  });

  const [selectedProperty, setSelectedProperty] = useState<string>('H');

  // CMYK to RGB conversion function
  const cmykToRgb = (C: number, M: number, Y: number, K: number) => {
    const c = C / 100;
    const m = M / 100;
    const y = Y / 100;
    const k = K / 100;

    const r = Math.round(255 * (1 - c) * (1 - k));
    const g = Math.round(255 * (1 - m) * (1 - k));
    const b = Math.round(255 * (1 - y) * (1 - k));

    return { R: r, G: g, B2: b };
  };

  const handleColorChange = (newHex: string, newColorValues: ColorValues) => {
    const rgb = chroma(newHex).rgb();
    const cmyk = ColorValueUtil.rgbToCmyk(rgb[0], rgb[1], rgb[2]);

    // Round H to the nearest whole number
    newColorValues.H = Math.round(newColorValues.H);

    setColorValues({
      ...newColorValues,
      R: rgb[0],
      G: rgb[1],
      B2: rgb[2],
      C: cmyk.C,
      M: cmyk.M,
      Y: cmyk.Y,
      K: cmyk.K,
      hex: newHex,
    });

    setInputValues({
      H: `${newColorValues.H}°`,
      S: `${Math.round(newColorValues.S * 100)}%`,
      B: `${Math.round(newColorValues.B * 100)}%`,
      R: rgb[0].toString(),
      G: rgb[1].toString(),
      B2: rgb[2].toString(),
      C: `${cmyk.C}%`,
      M: `${cmyk.M}%`,
      Y: `${cmyk.Y}%`,
      K: `${cmyk.K}%`,
      hex: newHex.replace('#', ''), // Remove '#' from the input value
    });
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedProperty(e.target.value);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInputSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const { name, value } = e.currentTarget;
      let newColorValues = { ...colorValues };
      let newHex = colorValues.hex;
      let valid = true;

      const parsedValue = value.replace(/°|%/g, '').trim(); // Remove degree or percent symbols

      switch (name) {
        case 'H':
          let H = Math.round(parseFloat(parsedValue));
          if (isNaN(H)) H = 0;
          H = Math.max(0, Math.min(360, H));
          newColorValues.H = H;
          const rgbFromHsv = chroma.hsv(H, newColorValues.S, newColorValues.B).rgb();
          newColorValues.R = rgbFromHsv[0];
          newColorValues.G = rgbFromHsv[1];
          newColorValues.B2 = rgbFromHsv[2];
          newHex = chroma.hsv(H, newColorValues.S, newColorValues.B).hex().toUpperCase();
          newColorValues.hex = newHex;
          break;
        case 'S':
          let S = parseFloat(parsedValue);
          if (isNaN(S)) S = 0;
          S = Math.max(0, Math.min(100, S)) / 100;
          newColorValues.S = S;
          const rgbFromS = chroma.hsv(newColorValues.H, S, newColorValues.B).rgb();
          newColorValues.R = rgbFromS[0];
          newColorValues.G = rgbFromS[1];
          newColorValues.B2 = rgbFromS[2];
          newHex = chroma.hsv(newColorValues.H, S, newColorValues.B).hex().toUpperCase();
          newColorValues.hex = newHex;
          break;
        case 'B':
          let B = parseFloat(parsedValue);
          if (isNaN(B)) B = 0;
          B = Math.max(0, Math.min(100, B)) / 100;
          newColorValues.B = B;
          const rgbFromB = chroma.hsv(newColorValues.H, newColorValues.S, B).rgb();
          newColorValues.R = rgbFromB[0];
          newColorValues.G = rgbFromB[1];
          newColorValues.B2 = rgbFromB[2];
          newHex = chroma.hsv(newColorValues.H, newColorValues.S, B).hex().toUpperCase();
          newColorValues.hex = newHex;
          break;
        case 'R':
        case 'G':
        case 'B2':
          let R = parseInt(name === 'R' ? parsedValue : colorValues.R.toString());
          let G = parseInt(name === 'G' ? parsedValue : colorValues.G.toString());
          let B2 = parseInt(name === 'B2' ? parsedValue : colorValues.B2.toString());
          if (isNaN(R)) R = 0;
          if (isNaN(G)) G = 0;
          if (isNaN(B2)) B2 = 0;
          R = Math.max(0, Math.min(255, R));
          G = Math.max(0, Math.min(255, G));
          B2 = Math.max(0, Math.min(255, B2));
          newHex = chroma(R, G, B2).hex().toUpperCase();
          const hsvFromRgb = chroma(R, G, B2).hsv();
          const cmykFromRgb = ColorValueUtil.rgbToCmyk(R, G, B2);
          newColorValues = {
            H: Math.round(hsvFromRgb[0] || 0),
            S: hsvFromRgb[1] || 0,
            B: hsvFromRgb[2] || 0,
            R,
            G,
            B2,
            C: cmykFromRgb.C,
            M: cmykFromRgb.M,
            Y: cmykFromRgb.Y,
            K: cmykFromRgb.K,
            hex: newHex,
          };
          break;
        case 'C':
        case 'M':
        case 'Y':
        case 'K':
          let C = parseFloat(name === 'C' ? parsedValue : colorValues.C.toString());
          let M = parseFloat(name === 'M' ? parsedValue : colorValues.M.toString());
          let Y = parseFloat(name === 'Y' ? parsedValue : colorValues.Y.toString());
          let K = parseFloat(name === 'K' ? parsedValue : colorValues.K.toString());
          if (isNaN(C)) C = 0;
          if (isNaN(M)) M = 0;
          if (isNaN(Y)) Y = 0;
          if (isNaN(K)) K = 0;
          C = Math.max(0, Math.min(100, C));
          M = Math.max(0, Math.min(100, M));
          Y = Math.max(0, Math.min(100, Y));
          K = Math.max(0, Math.min(100, K));

          const rgbFromCmyk = cmykToRgb(C, M, Y, K);
          newHex = chroma(rgbFromCmyk.R, rgbFromCmyk.G, rgbFromCmyk.B2).hex().toUpperCase();
          const hsvFromCmyk = chroma(rgbFromCmyk.R, rgbFromCmyk.G, rgbFromCmyk.B2).hsv();
          newColorValues = {
            H: Math.round(hsvFromCmyk[0] || 0),
            S: hsvFromCmyk[1] || 0,
            B: hsvFromCmyk[2] || 0,
            R: rgbFromCmyk.R,
            G: rgbFromCmyk.G,
            B2: rgbFromCmyk.B2,
            C,
            M,
            Y,
            K,
            hex: newHex,
          };
          break;
        case 'hex':
          try {
            newHex = chroma('#' + parsedValue)
              .hex()
              .toUpperCase();
            const hsvFromHex = chroma(newHex).hsv();
            const rgbFromHex = chroma(newHex).rgb();
            const cmykFromHex = ColorValueUtil.rgbToCmyk(
              rgbFromHex[0],
              rgbFromHex[1],
              rgbFromHex[2],
            );
            newColorValues = {
              H: Math.round(hsvFromHex[0] || 0),
              S: hsvFromHex[1] || 0,
              B: hsvFromHex[2] || 0,
              R: rgbFromHex[0],
              G: rgbFromHex[1],
              B2: rgbFromHex[2],
              C: cmykFromHex.C,
              M: cmykFromHex.M,
              Y: cmykFromHex.Y,
              K: cmykFromHex.K,
              hex: newColorValues.hex.replace('#', ''), // Remove '#' from the input value
            };
          } catch {
            valid = false;
          }
          break;
        default:
          break;
      }

      if (valid) {
        // Round H to nearest whole number
        newColorValues.H = Math.round(newColorValues.H);

        setColorValues(newColorValues);
        setInputValues({
          H: `${newColorValues.H}°`,
          S: `${Math.round(newColorValues.S * 100)}%`,
          B: `${Math.round(newColorValues.B * 100)}%`,
          R: newColorValues.R.toString(),
          G: newColorValues.G.toString(),
          B2: newColorValues.B2.toString(),
          C: `${newColorValues.C}%`,
          M: `${newColorValues.M}%`,
          Y: `${newColorValues.Y}%`,
          K: `${newColorValues.K}%`,
          hex: newHex.replace('#', ''), // Remove '#' from the input value
        });
      } else {
        alert('Invalid Input');
        setInputValues((prev) => ({
          ...prev,
          [name]: prev[name as keyof typeof inputValues], // Keep the previous input value
        }));
      }

      // Blur the input field to lose focus
      e.currentTarget.blur();
    }
  };

  const properties = ['H', 'S', 'B', 'R', 'G', 'B2'];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        color: 'white',
        userSelect: 'none',
      }}
    >
      {/* Left Side: GradientSelector */}
      <div>
        <GradientSelector
          colorValues={colorValues}
          onChange={handleColorChange}
          selectedProperty={selectedProperty}
        />
      </div>

      {/* Right Side: Controls */}
      <div
        style={{
          marginLeft: '20px',
          display: 'flex',
          flexDirection: 'row',
          height: '300px',
        }}
      >
        {/* Left Column: Previous/Current Color Boxes, HSB/RGB Inputs, Hex Input */}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {/* Rectangles displaying the previous and current colors */}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                width: '60px',
                height: '30px',
                backgroundColor: colorValues.hex,
                borderLeft: '1px solid black',
                borderRight: '1px solid black',
                borderTop: '1px solid black',
              }}
            ></div>
            <div
              style={{
                width: '60px',
                height: '30px',
                backgroundColor: previousColor.hex,
                borderLeft: '1px solid black',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            ></div>
          </div>

          <div style={{ flex: 1 }}></div>

          {/* HSB/RGB Inputs */}
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              justifyContent: 'flex-end', // Align fields at the bottom
            }}
          >
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto auto auto',
                rowGap: '5px',
                marginTop: '10px',
              }}
            >
              {properties.map((prop) => (
                <React.Fragment key={prop}>
                  <input
                    type="radio"
                    value={prop}
                    checked={selectedProperty === prop}
                    onChange={handleRadioChange}
                    style={{ marginRight: '5px' }}
                  />
                  <label style={{ marginRight: '5px' }}>{prop === 'B2' ? 'B:' : `${prop}:`}</label>
                  <input
                    type="text"
                    name={prop}
                    value={inputValues[prop as keyof InputValues]}
                    onChange={handleInputChange}
                    onKeyDown={handleInputSubmit}
                    onFocus={() => setFocused(prop)}
                    onBlur={() => setFocused(null)}
                    style={{
                      width: '60px',
                      backgroundColor: focused === prop ? 'white' : '#666666',
                      border: focused === prop ? '1px solid blue' : '1px solid grey',
                      color: focused === prop ? 'black' : 'white',
                    }}
                  />
                </React.Fragment>
              ))}

              {/* Hex Input (without radio button) */}
              <div></div>
              <label style={{ marginRight: '5px' }}>#</label>
              <input
                type="text"
                name="hex"
                value={inputValues.hex}
                onChange={handleInputChange}
                onKeyDown={handleInputSubmit}
                onFocus={() => setFocused('hex')}
                onBlur={() => setFocused(null)}
                style={{
                  width: '60px',
                  backgroundColor: focused === 'hex' ? 'white' : '#666666',
                  border: focused === 'hex' ? '1px solid blue' : '1px solid grey',
                  color: focused === 'hex' ? 'black' : 'white',
                }}
              />
            </div>
          </div>
        </div>

        {/* Right Column: CMYK Inputs */}
        <div style={{ marginLeft: '20px', display: 'flex', flexDirection: 'column' }}>
          {/* OK Button at the top */}
          <button
            style={{
              alignSelf: 'flex-start',
              marginBottom: '10px',
              padding: '10px 20px', // More padding for a balanced look
              borderRadius: '20px', // Rounded button
              border: '2px solid white', // Outline with a primary color
              backgroundColor: 'transparent', // Transparent background for outline effect
              color: 'white', // Text color matching the border
              fontSize: '16px', // Larger font for better readability
              fontWeight: 'bold', // Bold text for emphasis
              cursor: 'pointer',
              transition: 'all 0.3s ease', // Smooth transition for hover effect
              width: '100px',
            }}
            onClick={() => {
              onColorSelected(colorValues);
            }}
          >
            OK
          </button>
          <div style={{ flexGrow: 1 }}></div> {/* Spacer to push inputs to bottom */}
          {/* CMYK Inputs */}
          <div style={{ display: 'grid', gridTemplateColumns: 'auto auto', rowGap: '5px' }}>
            {['C', 'M', 'Y', 'K'].map((prop) => (
              <React.Fragment key={prop}>
                <label style={{ marginRight: '5px' }}>{`${prop}:`}</label>
                <input
                  type="text"
                  name={prop}
                  value={inputValues[prop as keyof InputValues]}
                  onChange={handleInputChange}
                  onKeyDown={handleInputSubmit}
                  onFocus={() => setFocused(prop)}
                  onBlur={() => setFocused(null)}
                  style={{
                    width: '60px',
                    backgroundColor: focused === prop ? 'white' : '#666666',
                    border: focused === prop ? '1px solid blue' : '1px solid grey',
                    color: focused === prop ? 'black' : 'white',
                  }}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorPicker;
