// Toolbox.tsx

import React from 'react';
import ToolboxIconButton from './ToolboxIconButton';
import { ToolType } from '../../lib/tool/Tool';
import { ColorValues } from '../../lib/color/ColorValues';

interface ToolboxProps {
  selectedToolType: ToolType;
  strokeColor: ColorValues;
  fillColor: ColorValues;
  useStrokeColor: boolean;
  useFillColor: boolean;
  isStrokeSelected: boolean;
  switchTool: (toolType: ToolType) => void;
  onSwitchColorType: (isStroke: boolean) => void;
  onOpenColorSelector: (isStroke: boolean) => void;
  onSwitchColor: (isStroke: boolean, useColor: boolean) => void;
  onSwapColors: () => void;
  onDefault: () => void;
  isColumn: boolean;
}

const Toolbox: React.FC<ToolboxProps> = ({
  selectedToolType,
  strokeColor,
  fillColor,
  useStrokeColor,
  useFillColor,
  isStrokeSelected,
  switchTool,
  isColumn,
  onSwitchColorType,
  onOpenColorSelector,
  onSwitchColor,
  onSwapColors,
  onDefault,
}) => (
  <div
    style={{
      // width: '50px',
      backgroundColor: '#535353',
      padding: isColumn ? '4px' : '4px',
      boxSizing: 'border-box',
      borderRight: '2px solid #2d2d2d',
      borderTop: '2px solid #2d2d2d',
      display: 'flex',
      alignItems: 'center',
      flexDirection: isColumn ? 'column' : 'row',
      marginTop: isColumn ? '4px' : '0px',
    }}
  >
    <ToolboxIconButton
      marginBottom={isColumn ? '4px' : '0px'}
      marginRight={isColumn ? '0px' : '4px'}
      onClick={() => switchTool(ToolType.Selection)}
      selected={selectedToolType === ToolType.Selection}
      iconSrc="img/icons/selection.svg"
    />
    <ToolboxIconButton
      marginBottom={isColumn ? '4px' : '0px'}
      marginRight={isColumn ? '0px' : '4px'}
      onClick={() => switchTool(ToolType.DirectSelection)}
      selected={selectedToolType === ToolType.DirectSelection}
      iconSrc="img/icons/direct_select.svg"
    />
    <ToolboxIconButton
      marginBottom={isColumn ? '4px' : '0px'}
      marginRight={isColumn ? '0px' : '4px'}
      onClick={() => switchTool(ToolType.AnchorPoint)}
      selected={selectedToolType === ToolType.AnchorPoint}
      iconSrc="img/icons/anchor_point.svg"
    />
    <ToolboxIconButton
      marginBottom={isColumn ? '4px' : '0px'}
      marginRight={isColumn ? '0px' : '4px'}
      onClick={() => switchTool(ToolType.Pen)}
      selected={selectedToolType === ToolType.Pen}
      iconSrc="img/icons/pen.svg"
    />
    <ToolboxIconButton
      marginBottom={isColumn ? '4px' : '0px'}
      marginRight={isColumn ? '0px' : '4px'}
      onClick={() => switchTool(ToolType.Rectangle)}
      selected={selectedToolType === ToolType.Rectangle}
      iconSrc="img/icons/rectangle.svg"
    />
    <ToolboxIconButton
      marginBottom={isColumn ? '4px' : '0px'}
      marginRight={isColumn ? '0px' : '4px'}
      onClick={() => switchTool(ToolType.Ellipse)}
      selected={selectedToolType === ToolType.Ellipse}
      iconSrc="img/icons/ellipse.svg"
    />
    <ToolboxIconButton
      marginBottom={isColumn ? '4px' : '0px'}
      marginRight={isColumn ? '0px' : '4px'}
      onClick={() => switchTool(ToolType.Eyedropper)}
      selected={selectedToolType === ToolType.Eyedropper}
      padding="4px"
      iconSrc="img/icons/eyedropper.svg"
    />
    <ToolboxIconButton
      marginBottom={isColumn ? '4px' : '0px'}
      marginRight={isColumn ? '0px' : '4px'}
      onClick={() => switchTool(ToolType.Artboard)}
      selected={selectedToolType === ToolType.Artboard}
      iconSrc="img/icons/artboard.svg"
    />
    {/* Default and swap colors */}
    <div style={{ display: 'flex', flexDirection: 'row', marginTop: isColumn ? '4px' : '0px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: isColumn ? '4px' : '0px',
          marginRight: '4px',
          width: '12px',
          height: '12px',
          boxSizing: 'border-box',
        }}
        onClick={onDefault}
      >
        <img style={{ width: '12px', height: '12px' }} src="img/icons/default.svg" />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: isColumn ? '4px' : '0px',
          marginRight: isColumn ? '0px' : '4px',
          width: '12px',
          height: '12px',
          boxSizing: 'border-box',
        }}
        onClick={onSwapColors}
      >
        <img style={{ width: '12px', height: '12px' }} src="img/icons/swap.svg" />
      </div>
    </div>
    {/* Color picker for fill and stroke */}
    <div
      style={{
        position: 'relative',
        width: '36px',
        height: '36px',
        marginTop: isColumn ? '4px' : '0px',
        marginBottom: isColumn ? '4px' : '0px',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          marginBottom: isColumn ? '4px' : '0px',
          marginRight: isColumn ? '0px' : '4px',
          width: '20px',
          height: '20px',
          border: '1px solid white',
          backgroundColor: useFillColor ? fillColor.hex : 'white',
          zIndex: isStrokeSelected ? 0 : 1,
        }}
        onClick={() => onSwitchColorType(false)}
        onDoubleClick={() => onOpenColorSelector(false)}
      >
        {!useFillColor && (
          <img
            style={{ position: 'absolute', top: '0', left: '0', height: '100%' }}
            src="img/icons/no_color.svg"
          />
        )}
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '0',
          right: '0',
          marginBottom: isColumn ? '4px' : '0px',
          marginRight: isColumn ? '0px' : '4px',
          width: '20px',
          height: '20px',
          border: '1px solid black',
          backgroundColor: useStrokeColor ? strokeColor.hex : 'white',
          zIndex: isStrokeSelected ? 1 : 0,
        }}
        onClick={() => onSwitchColorType(true)}
        onDoubleClick={() => onOpenColorSelector(true)}
      >
        {!useStrokeColor && (
          <img
            style={{ position: 'absolute', top: '0', left: '0', height: '100%' }}
            src="img/icons/no_color.svg"
          />
        )}
      </div>
    </div>
    {/* Color vs No color selector */}
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div
        style={{
          marginBottom: isColumn ? '4px' : '0px',
          marginRight: isColumn ? '0px' : '4px',
          width: '12px',
          height: '12px',
          border: '1px solid black',
          boxSizing: 'border-box',
          backgroundColor: isStrokeSelected ? strokeColor?.hex : fillColor?.hex,
        }}
        onClick={() => onSwitchColor(isStrokeSelected, true)}
      ></div>
      <div
        style={{
          position: 'relative',
          marginBottom: isColumn ? '4px' : '0px',
          marginRight: isColumn ? '0px' : '4px',
          width: '12px',
          height: '12px',
          border: '1px solid black',
          boxSizing: 'border-box',
          background: 'white',
        }}
        onClick={() => onSwitchColor(isStrokeSelected, false)}
      >
        <img
          style={{ position: 'absolute', top: '0', left: '0', height: '100%' }}
          src="img/icons/no_color.svg"
        />
      </div>
    </div>
  </div>
);

export default Toolbox;
