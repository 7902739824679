// EyedropperTool.ts

import { Tool, ToolContext } from './Tool';
import { NormalizedTouchEvent } from '../../ui/input/MouseTouch';
import { ColorValueUtil } from '../color/ColorValues';

export class EyedropperTool implements Tool {
  private isMouseDown = false;
  private currentColorHex = '#FFFFFF';
  private currentScreenX: number | null = null;
  private currentScreenY: number | null = null;

  onMouseDown(event: NormalizedTouchEvent, context: ToolContext): void {
    this.isMouseDown = true;
    this.updateColorUnderCursor(event, context);
  }

  onMouseMove(event: NormalizedTouchEvent, context: ToolContext): boolean {
    if (!this.isMouseDown) return false;

    this.updateColorUnderCursor(event, context);

    // Redraw the overlay
    const overlayCanvas = context.canvas;
    const overlayCtx = overlayCanvas.getContext('2d');
    if (!overlayCtx) return false;

    // Adjust canvas size for device pixel ratio
    const dpr = window.devicePixelRatio || 1;
    const rect = overlayCanvas.getBoundingClientRect();
    overlayCanvas.width = rect.width * dpr;
    overlayCanvas.height = rect.height * dpr;
    overlayCtx.resetTransform();
    overlayCtx.scale(dpr, dpr);

    // Clear the overlay canvas
    overlayCtx.clearRect(0, 0, rect.width, rect.height);

    // Draw the overlay
    this.drawOverlay(context, overlayCtx);

    return true; // Overlay needs to re-render
  }

  onMouseUp(event: NormalizedTouchEvent, context: ToolContext): void {
    this.isMouseDown = false;

    // Set the selected color as the fill/stroke color
    if (this.currentColorHex.startsWith('#')) {
      if (context.artboardViewModel.state.editingStroke) {
        context.artboardViewModel.updateGlobalStrokeColor(
          ColorValueUtil.fromHex(this.currentColorHex),
        );
        context.artboardViewModel.updateStrokeColor(this.currentColorHex);
      } else {
        context.artboardViewModel.updateGlobalFillColor(
          ColorValueUtil.fromHex(this.currentColorHex),
        );
        context.artboardViewModel.updateFillColor(this.currentColorHex);
      }
    }

    // Clear the overlay
    const overlayCanvas = context.canvas;
    const overlayCtx = overlayCanvas.getContext('2d');
    if (overlayCtx) {
      overlayCtx.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
    }

    // Reset state
    this.currentScreenX = null;
    this.currentScreenY = null;
  }

  drawOverlay(context: ToolContext, ctx: CanvasRenderingContext2D): void {
    if (!this.isMouseDown || this.currentScreenX === null || this.currentScreenY === null) return;

    ctx.save();

    // Draw in screen coordinates (no need to adjust for transformations)
    // Context is already scaled for device pixel ratio

    // Prepare text and box dimensions
    const fontSize = 16; // Consistent font size
    ctx.font = `${fontSize}px Arial`;
    ctx.textBaseline = 'top';
    const textToDisplay = this.currentColorHex === 'transparent' ? '------' : this.currentColorHex;
    const textMetrics = ctx.measureText(textToDisplay);
    const padding = 8; // Consistent padding
    const boxWidth = textMetrics.width + padding * 2;
    const boxHeight = fontSize + padding * 2;

    // Calculate position above the cursor
    const x = this.currentScreenX;
    const y = this.currentScreenY;
    const boxX = x - boxWidth / 2;
    const boxY = y - boxHeight - 10; // 10 pixels above the cursor

    // Draw black box
    ctx.fillStyle = 'black';
    ctx.fillRect(boxX, boxY, boxWidth, boxHeight);

    // Draw hex color code text
    ctx.fillStyle = 'white';
    ctx.fillText(textToDisplay, boxX + padding, boxY + padding);

    ctx.restore();
  }

  private updateColorUnderCursor(event: NormalizedTouchEvent, context: ToolContext): void {
    const mainCanvas = context.mainCanvas;
    const mainCtx = mainCanvas.getContext('2d');
    if (!mainCtx) return;

    // Get the mouse position relative to the main canvas
    const mainCanvasRect = mainCanvas.getBoundingClientRect();

    // Adjust for device pixel ratio
    const dpr = window.devicePixelRatio || 1;

    // Convert screen coordinates to canvas coordinates
    const adjustedX = (event.x - mainCanvasRect.left) * dpr;
    const adjustedY = (event.y - mainCanvasRect.top) * dpr;

    // Get the pixel data at the canvas position
    let imageData;
    try {
      imageData = mainCtx.getImageData(Math.floor(adjustedX), Math.floor(adjustedY), 1, 1);
    } catch (e) {
      console.error('Error getting image data:', e);
      this.currentColorHex = 'transparent';
      return;
    }

    const [r, g, b, a] = imageData.data;
    if (a === 0) {
      this.currentColorHex = 'transparent';
    } else {
      this.currentColorHex = this.rgbToHex(r, g, b);
    }

    // Store current mouse position in screen coordinates relative to the overlay canvas
    const overlayRect = context.canvas.getBoundingClientRect();
    this.currentScreenX = event.x - overlayRect.left;
    this.currentScreenY = event.y - overlayRect.top;
  }

  private rgbToHex(r: number, g: number, b: number): string {
    return (
      '#' +
      [r, g, b]
        .map((x) => {
          const hex = x.toString(16).toUpperCase();
          return hex.length === 1 ? '0' + hex : hex;
        })
        .join('')
    );
  }
}
