// EllipseTool.ts

import { Tool, ToolContext } from './Tool';
import { Point } from '../math/Point';
import { Vertex } from '../math/Vertex';
import { Path } from '../geometry/Path';
import { CapType } from '../geometry/CapType';
import { CornerType } from '../geometry/CornerType';
import { ScaleOffset } from '../math/ScaleOffset';
import { NormalizedTouchEvent } from '../../ui/input/MouseTouch';

type DraggingState = null | {
  startX: number;
  startY: number;
  currentX: number;
  currentY: number;
  shiftKey: boolean;
};

export class EllipseTool implements Tool {
  private draggingState: DraggingState = null;

  onMouseDown(event: NormalizedTouchEvent, context: ToolContext): void {
    const { x, y } = this.getMousePos(event, context);

    this.draggingState = {
      startX: x,
      startY: y,
      currentX: x,
      currentY: y,
      shiftKey: event.shiftKey,
    };
  }

  onMouseMove(event: NormalizedTouchEvent, context: ToolContext): boolean {
    if (!this.draggingState) return false;

    const { x, y } = this.getMousePos(event, context);

    // Adjust currentX and currentY based on shiftKey to maintain square proportions
    let currentX = x;
    let currentY = y;

    if (event.shiftKey) {
      const dx = x - this.draggingState.startX;
      const dy = y - this.draggingState.startY;
      const absDx = Math.abs(dx);
      const absDy = Math.abs(dy);
      const delta = Math.min(absDx, absDy);

      currentX = this.draggingState.startX + (dx < 0 ? -delta : delta);
      currentY = this.draggingState.startY + (dy < 0 ? -delta : delta);
    }

    this.draggingState.currentX = currentX;
    this.draggingState.currentY = currentY;
    this.draggingState.shiftKey = event.shiftKey;

    // Redraw the overlay to show the ellipse outline
    const canvas = context.canvas;
    const ctx = canvas.getContext('2d');
    if (!ctx) return false;

    const rect = canvas.getBoundingClientRect();
    const dpr = window.devicePixelRatio || 1;
    canvas.width = rect.width * dpr;
    canvas.height = rect.height * dpr;
    ctx.scale(dpr, dpr);
    ctx.clearRect(0, 0, rect.width, rect.height);

    this.drawOverlay(context, ctx);

    return false;
  }

  onMouseUp(event: NormalizedTouchEvent, context: ToolContext): void {
    if (!this.draggingState) return;

    const { startX, startY, currentX, currentY } = this.draggingState;
    const { artboardViewModel } = context;

    // Create ellipse path
    const vertices = this.createEllipseVertices(startX, startY, currentX, currentY);
    const ellipsePath: Path = {
      vertices: vertices,
      fill: artboardViewModel.getFillColorHex(),
      strokeWidth: 1,
      scaleOffset: new ScaleOffset(),
      strokeColor: artboardViewModel.getStrokeColorHex(),
      closed: true,
      cap: CapType.BUTT,
      corner: CornerType.MITER,
      opacity: 1,
      fillOpacity: 1,
      strokeOpacity: 1,
      zIndex: 0, // zIndex will be set in addObject
      selected: true,
    };

    // Add the new ellipse to objects
    artboardViewModel.addObject(ellipsePath);

    // Clear dragging state
    this.draggingState = null;
  }

  drawOverlay(context: ToolContext, ctx: CanvasRenderingContext2D): void {
    if (!this.draggingState) return;

    const { startX, startY, currentX, currentY } = this.draggingState;

    const { canvas, artboardViewModel } = context;

    // Get scale and translation from artboardViewModel
    const scaleOffset = artboardViewModel.state.scaleOffset;
    const artboardDimensions = artboardViewModel.state.boardDimensions;

    // Apply the same transformations as in MainCanvas
    const rect = canvas.getBoundingClientRect();

    ctx.save();

    // Apply pan and zoom transformations
    ctx.translate(scaleOffset.x, scaleOffset.y);
    ctx.scale(scaleOffset.scaleX, scaleOffset.scaleX);

    // Center the artboard within the canvas
    const xOffset = (rect.width / scaleOffset.scaleX - artboardDimensions.w) / 2;
    const yOffset = (rect.height / scaleOffset.scaleX - artboardDimensions.h) / 2;

    ctx.translate(xOffset, yOffset);

    // Draw ellipse outline
    ctx.strokeStyle = '#0000FF'; // Blue outline
    ctx.lineWidth = 1 / scaleOffset.scaleX;
    ctx.setLineDash([5 / scaleOffset.scaleX, 5 / scaleOffset.scaleX]);

    const x = startX;
    const y = startY;
    const width = currentX - startX;
    const height = currentY - startY;

    ctx.beginPath();
    ctx.ellipse(
      x + width / 2,
      y + height / 2,
      Math.abs(width / 2),
      Math.abs(height / 2),
      0,
      0,
      2 * Math.PI,
    );
    ctx.stroke();

    ctx.restore();
  }

  private getMousePos(event: NormalizedTouchEvent, context: ToolContext): { x: number; y: number } {
    const rect = context.canvas.getBoundingClientRect();
    const scaleOffset = context.artboardViewModel.state.scaleOffset;
    const artboardDimensions = context.artboardViewModel.state.boardDimensions;

    const point = ScaleOffset.screenToCanvasPoint(
      event.x,
      event.y,
      rect,
      scaleOffset,
      artboardDimensions,
    );

    return { x: point.x, y: point.y };
  }

  private createEllipseVertices(
    startX: number,
    startY: number,
    endX: number,
    endY: number,
  ): Vertex[] {
    const cx = (startX + endX) / 2;
    const cy = (startY + endY) / 2;
    const rx = Math.abs(endX - startX) / 2;
    const ry = Math.abs(endY - startY) / 2;

    const kappa = 0.5522847498307936; // Approximation for circles/ellipses

    const cpOffsetX = rx * kappa;
    const cpOffsetY = ry * kappa;

    // Anchor points at cardinal directions
    const p0 = new Point(cx + rx, cy);
    const p1 = new Point(cx, cy + ry);
    const p2 = new Point(cx - rx, cy);
    const p3 = new Point(cx, cy - ry);

    // Vertices with control points
    const vertices: Vertex[] = [];

    // Vertex at p0
    vertices.push(
      new Vertex(
        p0,
        new Point(cx + rx, cy - cpOffsetY), // controlLeft
        new Point(cx + rx, cy + cpOffsetY), // controlRight
        true,
      ),
    );

    // Vertex at p1
    vertices.push(
      new Vertex(
        p1,
        new Point(cx + cpOffsetX, cy + ry), // controlLeft
        new Point(cx - cpOffsetX, cy + ry), // controlRight
        true,
      ),
    );

    // Vertex at p2
    vertices.push(
      new Vertex(
        p2,
        new Point(cx - rx, cy + cpOffsetY), // controlLeft
        new Point(cx - rx, cy - cpOffsetY), // controlRight
        true,
      ),
    );

    // Vertex at p3
    vertices.push(
      new Vertex(
        p3,
        new Point(cx - cpOffsetX, cy - ry), // controlLeft
        new Point(cx + cpOffsetX, cy - ry), // controlRight
        true,
      ),
    );

    return vertices;
  }
}
