import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

// Utility Function to Format Number to Up to 6 Decimal Places
function formatValue(num: number): string {
  const formatted = num.toFixed(6);
  // Remove trailing zeros and possible trailing decimal point
  return formatted.replace(/\.?0+$/, '');
}

interface NumberEntryWidgetProps {
  initialValue?: number;
  onChange: (value: number) => void;
}

const NumberEntryWidget: React.FC<NumberEntryWidgetProps> = ({ initialValue = 0, onChange }) => {
  // State Variables
  const [value, setValue] = useState<number>(initialValue);
  const [inputValue, setInputValue] = useState<string>(formatValue(initialValue));
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  // Refs for Click Outside Detection
  const wrapperRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // Ref to Store Last Valid Value
  const lastValidValueRef = useRef<number>(initialValue);

  // Update inputValue when value changes
  useEffect(() => {
    setInputValue(formatValue(value));
    lastValidValueRef.current = value;
  }, [value]);

  // Click Outside Handler to Close Popup
  useEffect(() => {
    if (!isPopupOpen) return;

    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(target) &&
        popupRef.current &&
        !popupRef.current.contains(target)
      ) {
        setIsPopupOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPopupOpen]);

  // Parse Input with Strict Validation for Up to 6 Decimal Places
  function parseInput(input: string): number | null {
    const regex = /^-?\d+(\.\d{1,6})?$/;
    const match = input.trim().match(regex);
    if (!match) return null;
    const num = parseFloat(match[0]);
    if (isNaN(num)) return null;
    return num;
  }

  // Event Handlers

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputBlur = () => {
    const parsedValue = parseInput(inputValue);
    if (parsedValue !== null) {
      setValue(parsedValue);
      onChange(parsedValue);
    } else {
      // Revert to last valid value
      setInputValue(formatValue(lastValidValueRef.current));
    }
    setIsInputFocused(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const parsedValue = parseInput(inputValue);
      if (parsedValue !== null) {
        setValue(parsedValue);
        onChange(parsedValue);
      } else {
        // Revert to last valid value
        setInputValue(formatValue(lastValidValueRef.current));
      }
      setIsPopupOpen(false);
      // Remove focus from input field
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  const togglePopup = () => {
    setIsPopupOpen((prev) => !prev);
  };

  // Handle Dropdown Option Selection
  const handleOptionSelect = (option: number) => {
    setValue(option);
    onChange(option);
    setIsPopupOpen(false);
    // Remove focus from input field
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  // Handle Input Focus to Close Popup and Change Background
  const handleInputFocus = () => {
    if (isPopupOpen) {
      setIsPopupOpen(false);
    }
    setIsInputFocused(true);
  };

  // Handle Increment
  const handleIncrement = () => {
    const newValue = value + 1;
    setValue(newValue);
    onChange(newValue);
  };

  // Handle Decrement
  const handleDecrement = () => {
    const newValue = value - 1;
    setValue(newValue);
    onChange(newValue);
  };

  // Calculate Popup Position
  const [popupStyles, setPopupStyles] = useState<React.CSSProperties>({});

  useEffect(() => {
    if (isPopupOpen && inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      setPopupStyles({
        position: 'absolute',
        top: rect.bottom + window.scrollY + 2, // 2px below the input
        left: rect.left + window.scrollX,
        padding: '8px',
        boxSizing: 'border-box',
        background: '#666666',
        border: '1px solid #333',
        boxShadow: '0px 4px 8px rgba(0,0,0,0.2)',
        zIndex: 1000,
        borderRadius: '4px',
        width: '100px',
      });
    }
  }, [isPopupOpen]);

  // Render the Popup using React Portal
  const popup = isPopupOpen
    ? ReactDOM.createPortal(
        <div ref={popupRef} style={popupStyles}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {Array.from({ length: 20 }, (_, i) => i + 1).map((option) => (
              <div
                key={option}
                onClick={() => handleOptionSelect(option)}
                style={{
                  padding: '4px 8px',
                  cursor: 'pointer',
                  backgroundColor: '#666666',
                  color: 'white',
                  borderRadius: '2px',
                }}
                onMouseEnter={(e) => {
                  (e.currentTarget as HTMLDivElement).style.backgroundColor = '#555555';
                }}
                onMouseLeave={(e) => {
                  (e.currentTarget as HTMLDivElement).style.backgroundColor = '#666666';
                }}
              >
                {option}
              </div>
            ))}
          </div>
        </div>,
        document.body,
      )
    : null;

  return (
    <div ref={wrapperRef} style={{ position: 'relative', display: 'inline-block' }}>
      <div style={{ display: 'flex', alignItems: 'center', height: '24px' }}>
        <div style={{ borderRight: '1px solid grey' }}>
          {/* Increment Button */}
          <div
            onClick={handleIncrement}
            style={{
              height: '12px',
              width: '24px',
              background: '#666666',
              borderTop: '1px solid grey',
              borderLeft: '1px solid grey',
              boxSizing: 'border-box',
              color: 'white',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '12px',
              userSelect: 'none',
            }}
            aria-label="Increment"
          >
            &#9650;
          </div>
          {/* Decrement Button */}
          <div
            onClick={handleDecrement}
            style={{
              height: '12px',
              width: '24px',
              background: '#666666',
              borderBottom: '1px solid grey',
              borderLeft: '1px solid grey',
              boxSizing: 'border-box',
              color: 'white',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '12px',
              userSelect: 'none',
            }}
            aria-label="Decrement"
          >
            &#9660;
          </div>
        </div>

        {/* Input Field */}
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyDown}
          onFocus={handleInputFocus}
          style={{
            height: '24px',
            width: '52px',
            boxSizing: 'border-box',
            backgroundColor: isInputFocused ? 'white' : '#666666',
            border: '1px solid grey',
            borderLeft: 'none',
            borderRight: 'none',
            color: isInputFocused ? 'black' : 'white',
            textAlign: 'center',
            fontSize: '12px',
            borderRadius: '0', // Already rounded in buttons
            outline: 'none',
          }}
        />

        {/* Dropdown Toggle Button */}
        <div
          onClick={togglePopup}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '24px',
            width: '24px',
            fontSize: '1.05rem',
            background: isPopupOpen ? '#333333' : '#666666',
            border: '1px solid grey',
            cursor: 'pointer',
            boxSizing: 'border-box',
            padding: '2px 4px',
            color: 'white',
            userSelect: 'none',
          }}
          aria-label="Open dropdown"
        >
          &#8594;
        </div>
      </div>
      {popup}
    </div>
  );
};

export default NumberEntryWidget;
