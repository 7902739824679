/* eslint-disable no-case-declarations */
// src/components/GradientSelector.tsx

import React, { useRef, useEffect, useState } from 'react';
import chroma from 'chroma-js';
import Slider from './Slider'; // Make sure to import the Slider component

interface GradientSelectorProps {
  colorValues: any;
  onChange: (newColor: string, newColorValues: any) => void;
  selectedProperty: string;
}

const GradientSelector: React.FC<GradientSelectorProps> = ({
  colorValues,
  onChange,
  selectedProperty,
}) => {
  const gradientCanvasRef = useRef<HTMLCanvasElement>(null);
  const [isDraggingGradient, setIsDraggingGradient] = useState<boolean>(false);

  useEffect(() => {
    if (colorValues && colorValues.hex) {
      drawGradient();
    }
  }, [colorValues, selectedProperty]);

  const drawGradient = () => {
    const canvas = gradientCanvasRef.current;
    if (!canvas || !colorValues) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const width = canvas.width;
    const height = canvas.height;

    // Create image data for the gradient
    const imageData = ctx.createImageData(width, height);
    const data = imageData.data;

    for (let y = 0; y < height; y++) {
      const ratioY = y / (height - 1);
      for (let x = 0; x < width; x++) {
        const ratioX = x / (width - 1);
        let r = 0,
          g = 0,
          b = 0;

        switch (selectedProperty) {
          case 'H':
          case 'S':
          case 'B':
            const hsv = { h: 0, s: 0, v: 0 };
            if (selectedProperty === 'H') {
              hsv.h = colorValues.H;
              hsv.s = ratioX;
              hsv.v = 1 - ratioY;
            } else if (selectedProperty === 'S') {
              hsv.h = ratioX * 360;
              hsv.s = colorValues.S;
              hsv.v = 1 - ratioY;
            } else if (selectedProperty === 'B') {
              hsv.h = ratioX * 360;
              hsv.s = 1 - ratioY;
              hsv.v = colorValues.B;
            }
            [r, g, b] = chroma.hsv(hsv.h, hsv.s, hsv.v).rgb();
            break;
          case 'R':
          case 'G':
          case 'B2':
            r = selectedProperty === 'R' ? colorValues.R : ratioX * 255;
            g = selectedProperty === 'G' ? colorValues.G : ratioX * 255;
            b = selectedProperty === 'B2' ? colorValues.B2 : ratioX * 255;
            if (selectedProperty === 'R') {
              b = ratioX * 255;
              g = (1 - ratioY) * 255;
            } else if (selectedProperty === 'G') {
              b = ratioX * 255;
              r = (1 - ratioY) * 255;
            } else if (selectedProperty === 'B2') {
              r = ratioX * 255;
              g = (1 - ratioY) * 255;
            }
            break;
          default:
            break;
        }

        // Clamp values to [0, 255]
        r = Math.max(0, Math.min(255, r));
        g = Math.max(0, Math.min(255, g));
        b = Math.max(0, Math.min(255, b));

        const index = (y * width + x) * 4;
        data[index] = r;
        data[index + 1] = g;
        data[index + 2] = b;
        data[index + 3] = 255; // Full opacity
      }
    }

    ctx.putImageData(imageData, 0, 0);

    // Draw cursor
    drawGradientCursor(ctx);
  };

  const drawGradientCursor = (ctx: CanvasRenderingContext2D) => {
    const canvas = gradientCanvasRef.current;
    if (!canvas || !colorValues) return;
    const width = canvas.width;
    const height = canvas.height;

    let x = 0;
    let y = 0;

    switch (selectedProperty) {
      case 'H':
        x = colorValues.S * width;
        y = (1 - colorValues.B) * height;
        break;
      case 'S':
        x = (colorValues.H / 360) * width;
        y = (1 - colorValues.B) * height;
        break;
      case 'B':
        x = (colorValues.H / 360) * width;
        y = (1 - colorValues.S) * height;
        break;
      case 'R':
        x = (colorValues.B2 / 255) * width;
        y = (1 - colorValues.G / 255) * height;
        break;
      case 'G':
        x = (colorValues.B2 / 255) * width;
        y = (1 - colorValues.R / 255) * height;
        break;
      case 'B2':
        x = (colorValues.R / 255) * width;
        y = (1 - colorValues.G / 255) * height;
        break;
      default:
        break;
    }

    // Draw cursor circle
    ctx.beginPath();
    ctx.arc(x, y, 5, 0, Math.PI * 2);
    ctx.strokeStyle = chroma(colorValues.hex).luminance() > 0.5 ? 'black' : 'white';
    ctx.lineWidth = 2;
    ctx.stroke();
  };

  const handleGradientMouseDown = (e: React.MouseEvent) => {
    setIsDraggingGradient(true);
    handleGradientMouseMove(e);
  };

  const handleGradientMouseMove = (e: React.MouseEvent) => {
    if (!isDraggingGradient && e.type !== 'mousedown') return;
    const canvas = gradientCanvasRef.current;
    if (!canvas || !colorValues) return;

    const rect = canvas.getBoundingClientRect();
    const width = canvas.width;
    const height = canvas.height;
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;

    x = Math.max(0, Math.min(x, width - 1));
    y = Math.max(0, Math.min(y, height - 1));

    const ratioX = x / (width - 1);
    const ratioY = y / (height - 1);

    const newColorValues = { ...colorValues };

    switch (selectedProperty) {
      case 'H':
        newColorValues.S = ratioX;
        newColorValues.B = 1 - ratioY;
        break;
      case 'S':
        newColorValues.H = ratioX * 360;
        newColorValues.B = 1 - ratioY;
        break;
      case 'B':
        newColorValues.H = ratioX * 360;
        newColorValues.S = 1 - ratioY;
        break;
      case 'R':
        newColorValues.B2 = ratioX * 255;
        newColorValues.G = (1 - ratioY) * 255;
        break;
      case 'G':
        newColorValues.B2 = ratioX * 255;
        newColorValues.R = (1 - ratioY) * 255;
        break;
      case 'B2':
        newColorValues.R = ratioX * 255;
        newColorValues.G = (1 - ratioY) * 255;
        break;
      default:
        break;
    }

    // Clamp values
    newColorValues.H = Math.max(0, Math.min(360, newColorValues.H || 0));
    newColorValues.S = Math.max(0, Math.min(1, newColorValues.S || 0));
    newColorValues.B = Math.max(0, Math.min(1, newColorValues.B || 0));
    newColorValues.R = Math.max(0, Math.min(255, newColorValues.R || 0));
    newColorValues.G = Math.max(0, Math.min(255, newColorValues.G || 0));
    newColorValues.B2 = Math.max(0, Math.min(255, newColorValues.B2 || 0));

    // Update hex and HSB/RGB values
    let newColor: chroma.Color;
    if (['H', 'S', 'B'].includes(selectedProperty)) {
      newColor = chroma.hsv(newColorValues.H, newColorValues.S, newColorValues.B);
      const rgb = newColor.rgb();
      newColorValues.R = rgb[0];
      newColorValues.G = rgb[1];
      newColorValues.B2 = rgb[2];
    } else {
      newColor = chroma(newColorValues.R, newColorValues.G, newColorValues.B2);
      const hsv = newColor.hsv();
      newColorValues.H = hsv[0] || 0;
      newColorValues.S = hsv[1] || 0;
      newColorValues.B = hsv[2] || 0;
    }
    newColorValues.hex = newColor.hex().toUpperCase();

    onChange(newColorValues.hex, newColorValues);
  };

  const handleGradientMouseUp = () => {
    setIsDraggingGradient(false);
  };

  const handleMouseLeave = () => {
    setIsDraggingGradient(false);
  };

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <canvas
          ref={gradientCanvasRef}
          width={300}
          height={300}
          style={{ boxSizing: 'border-box', cursor: 'crosshair' }}
          onMouseDown={handleGradientMouseDown}
          onMouseMove={handleGradientMouseMove}
          onMouseUp={handleGradientMouseUp}
          onMouseLeave={handleMouseLeave}
        ></canvas>
      </div>
      <div>
        <Slider colorValues={colorValues} onChange={onChange} selectedProperty={selectedProperty} />
      </div>
    </div>
  );
};

export default GradientSelector;
