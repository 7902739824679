import { BuildConfig } from './BuildConfig';

export class AnalyticsUtil {
  /**
   * Track a page view.
   * @param url - The URL path of the page.
   */
  static trackPage(url: string) {
    if (BuildConfig.isDebug()) return;
    const gtag = (window as any).gtag;
    if (typeof gtag === 'function') {
      gtag('config', 'G-KDYJ3GP3NE', {
        page_path: url,
      });
    }
  }

  /**
   * Track a custom event.
   * @param eventName - The name of the event (e.g., 'button_click').
   * @param eventParams - Additional parameters for the event.
   */
  // Use 'category' and 'label' for this
  static trackEvent(eventName: string, eventParams?: Record<string, any>) {
    if (BuildConfig.isDebug()) return;
    const gtag = (window as any).gtag;
    if (typeof gtag === 'function') {
      gtag('event', eventName, eventParams || {});
    }
  }
}
