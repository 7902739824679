import { NormalizedTouchEvent } from '../../ui/input/MouseTouch';
import { ArtboardViewModel } from '../../viewmodel/ArtboardViewModel';
import { Path } from '../geometry/Path';

export interface ToolContext {
  canvas: HTMLCanvasElement;
  mainCanvas: HTMLCanvasElement;
  artboardViewModel: ArtboardViewModel;
  currentPath?: Path | null;
  setCurrentPath?: React.Dispatch<React.SetStateAction<Path | null>>;
}

export interface Tool {
  onMouseDown(event: NormalizedTouchEvent, context: ToolContext): void;
  // Returns whether or not the overlay needs to re-render
  onMouseMove(event: NormalizedTouchEvent, context: ToolContext): boolean;
  onMouseUp(event: NormalizedTouchEvent, context: ToolContext): void;
  drawOverlay(context: ToolContext, canvasContext: CanvasRenderingContext2D): void;
}

export enum ToolType {
  Selection,
  DirectSelection,
  AnchorPoint,
  Pen,
  Rectangle,
  Ellipse,
  Artboard,
  Eyedropper,
}
