// PropertiesPanel.tsx

import React from 'react';
import { ArtboardViewModel } from '../../viewmodel/ArtboardViewModel';
import { Path } from '../../lib/geometry/Path';
import PathProperties from './PathProperties';
import ObjectProperties from './ObjectProperties';

interface PropertiesPanelProps {
  artboardViewModel: ArtboardViewModel;
  onOpenColorSelector: (isStroke: boolean) => void;
}

const PropertiesPanel: React.FC<PropertiesPanelProps> = ({
  artboardViewModel,
  onOpenColorSelector,
}) => {
  const selectedObjects = artboardViewModel.getSelectedObjects();

  if (selectedObjects.length == 0) {
    return (
      <div
        style={{
          width: '100%',
          backgroundColor: '#535353',
          padding: '10px',
          boxSizing: 'border-box',
          userSelect: 'none',
        }}
      >
        <h3 style={{ color: 'white' }}>Properties</h3>
        <div style={{ color: 'white' }}>No object selected</div>
      </div>
    );
  }

  const selectedObject = selectedObjects[0];

  return (
    <div
      style={{
        width: '100%',
        backgroundColor: '#535353',
        padding: '10px',
        boxSizing: 'border-box',
      }}
    >
      <span
        style={{
          color: 'white',
          userSelect: 'none',
          fontSize: '1rem',
          fontWeight: 'bold',
        }}
      >
        Properties
      </span>
      <div style={{ height: '8px' }}></div>
      {/* Object Properties */}
      <ObjectProperties artboardViewModel={artboardViewModel} selectedObject={selectedObject} />

      {/* Path Properties */}
      {artboardViewModel.isPath(selectedObject) && (
        <PathProperties
          artboardViewModel={artboardViewModel}
          selectedPath={selectedObject as Path}
          onOpenColorSelector={onOpenColorSelector}
        />
      )}
    </div>
  );
};

export default PropertiesPanel;
