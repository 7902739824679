// ArtboardViewModel.ts

import { useState } from 'react';
import { Path } from '../lib/geometry/Path';
import { CapType } from '../lib/geometry/CapType';
import { CornerType } from '../lib/geometry/CornerType';
import { Dimensions } from '../lib/math/Dimensions';
import { ArtboardObject } from '../lib/ArtboardObject';
import { ImageObject } from '../lib/object/ImageObject';
import { ScaleOffset } from '../lib/math/ScaleOffset';
import { AnalyticsUtil } from '../util/AnalyticsUtil';
import { Layer, PrimitiveObjectLayer, GroupLayer } from '../lib/layer/Layer';
import { ColorValues, ColorValueUtil } from '../lib/color/ColorValues';

export interface ArtboardState {
  boardDimensions: Dimensions;
  scaleOffset: ScaleOffset;
  layers: Layer[];
  selectedVertexIndex: number | null;
  hoveredObjectIndex: number | null;
  hoveredVertexIndex: number | null;
  strokeColor: ColorValues;
  fillColor: ColorValues;
  useStrokeColor: boolean;
  useFillColor: boolean;
  editingStroke: boolean;
}

export class ArtboardViewModel {
  private _state: ArtboardState;
  private setState: React.Dispatch<React.SetStateAction<ArtboardState>>;

  constructor(
    initialState: ArtboardState,
    setState: React.Dispatch<React.SetStateAction<ArtboardState>>,
  ) {
    this._state = initialState;
    this.setState = setState;
  }

  get state() {
    return this._state;
  }

  getObjects = (): ArtboardObject[] => {
    const objectsWithZIndex: { obj: ArtboardObject; zIndex: number }[] = [];

    this.state.layers.forEach((layer, layerIndex) => {
      layer.getObjects().forEach((obj, objectIndex) => {
        objectsWithZIndex.push({
          obj,
          zIndex: layerIndex * 1000 + objectIndex, // Assuming each layer can have up to 1000 objects
        });
      });
    });

    // Sort objects by zIndex
    objectsWithZIndex.sort((a, b) => a.zIndex - b.zIndex);

    // Return the sorted objects
    return objectsWithZIndex.map((item) => item.obj);
  };

  getSelectedObjects(): ArtboardObject[] {
    return this.getObjects().filter((object) => object.selected);
  }

  getSelectedLayers(): Layer[] {
    return this.state.layers.filter((layer) => layer.selected);
  }

  static getStateSelectedObjects(state: ArtboardState): ArtboardObject[] {
    return state.layers.flatMap((layer) => layer.getObjects()).filter((object) => object.selected);
  }

  public copySelectedObject = () => {
    const selected = this.getSelectedObjects();
    if (selected.length == 0) {
      return;
    }
    const selectedObject = selected[0];
    const serializedData = JSON.stringify(selectedObject);

    // Write to clipboard
    if ('clipboard' in navigator && 'writeText' in navigator.clipboard) {
      navigator.clipboard.writeText(serializedData).then(
        () => {
          // Nothing
        },
        (err) => {
          console.error('Failed to copy: ', err);
        },
      );
    } else {
      console.warn('Clipboard API not supported');
    }
  };

  public pasteFromClipboard = async () => {
    try {
      if ('clipboard' in navigator && 'read' in navigator.clipboard) {
        const clipboardItems = await navigator.clipboard.read();
        for (const item of clipboardItems) {
          for (const type of item.types) {
            if (type.startsWith('image/')) {
              const blob = await item.getType(type);
              const img = new Image();
              const url = URL.createObjectURL(blob);
              img.onload = () => {
                URL.revokeObjectURL(url);
                const imageObject: ImageObject = {
                  image: img,
                  scaleOffset: new ScaleOffset(
                    (this.state.boardDimensions.w - img.width) / 2,
                    (this.state.boardDimensions.h - img.height) / 2,
                  ),
                  opacity: 1,
                  zIndex: this.getObjects().length,
                  selected: true,
                };
                this.addObject(imageObject);
                AnalyticsUtil.trackEvent('paste_img', { category: 'Usage', label: 'paste_img' });
              };
              img.src = url;
              return;
            }
          }

          // Try to read text data
          const textBlob = await item.getType('text/plain');
          const textData = await textBlob.text();
          if (textData) {
            try {
              const parsedObject = JSON.parse(textData) as ArtboardObject;
              parsedObject.scaleOffset = new ScaleOffset(
                (this.state.boardDimensions.w - 100) / 2,
                (this.state.boardDimensions.h - 100) / 2,
              );
              parsedObject.zIndex = this.getObjects().length;
              this.addObject(parsedObject);
              AnalyticsUtil.trackEvent('paste_obj', { category: 'Usage', label: 'paste_obj1' });
            } catch (err) {
              console.error('Failed to parse clipboard text: ', err);
            }
          }
        }
      } else if ('clipboard' in navigator && 'readText' in navigator.clipboard) {
        // Fallback to readText
        const textData = await navigator.clipboard.readText();
        if (textData) {
          try {
            const parsedObject = JSON.parse(textData) as ArtboardObject;
            parsedObject.scaleOffset = new ScaleOffset(
              (this.state.boardDimensions.w - 100) / 2,
              (this.state.boardDimensions.h - 100) / 2,
            );
            parsedObject.zIndex = this.getObjects().length;
            this.addObject(parsedObject);
            AnalyticsUtil.trackEvent('paste_obj', { category: 'Usage', label: 'paste_obj2' });
          } catch (err) {
            console.error('Failed to parse clipboard text: ', err);
          }
        }
      } else {
        console.warn('Clipboard API not supported');
      }
    } catch (err) {
      console.error('Failed to read from clipboard: ', err);
    }
  };

  addObject = (newObject: ArtboardObject) => {
    this.clearAllSelected();
    this.setState((prevState) => {
      // Assign the new object a zIndex higher than any existing object
      const highestZIndex = prevState.layers
        .flatMap((layer) => layer.getObjects())
        .reduce((max, obj) => Math.max(max, obj.zIndex), 0);
      newObject.zIndex = highestZIndex + 1;

      const newLayer = new PrimitiveObjectLayer(newObject, false, true, false);

      return {
        ...prevState,
        layers: [...prevState.layers, newLayer],
      };
    });
  };

  // Helper method to check if an object is a Path
  isPath = (object: ArtboardObject): object is Path => {
    return (object as Path).vertices !== undefined;
  };

  // Helper method to check if an object is an ImageObject
  isImageObject = (object: ArtboardObject): object is ImageObject => {
    return (object as ImageObject).image !== undefined;
  };

  resetColors = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        strokeColor: ColorValueUtil.BLACK,
        fillColor: ColorValueUtil.WHITE,
        useStrokeColor: true,
        useFillColor: true,
      };
    });
  };

  // Methods for updating objects
  updateFillColor = (color: string) => {
    this.setState((prevState) => {
      const updatedLayers = prevState.layers.map((layer) =>
        layer.updateObjects((obj) => {
          if (!layer.locked && obj.selected) {
            return { ...obj, fill: color };
          }
          return obj;
        }),
      );

      return {
        ...prevState,
        layers: updatedLayers,
      };
    });
  };

  updateStrokeColor = (color: string) => {
    this.setState((prevState) => {
      const updatedLayers = prevState.layers.map((layer) =>
        layer.updateObjects((obj) => {
          if (!layer.locked && obj.selected) {
            return { ...obj, strokeColor: color };
          }
          return obj;
        }),
      );

      return {
        ...prevState,
        layers: updatedLayers,
      };
    });
  };

  updateStrokeWidth = (value: number) => {
    if (isNaN(value)) return;
    this.setState((prevState) => {
      const updatedLayers = prevState.layers.map((layer) =>
        layer.updateObjects((obj) => {
          if (!layer.locked && obj.selected) {
            return { ...obj, strokeWidth: Math.max(value, 0) };
          }
          return obj;
        }),
      );

      return {
        ...prevState,
        layers: updatedLayers,
      };
    });
  };

  updateCapType = (cap: CapType) => {
    this.setState((prevState) => {
      const updatedLayers = prevState.layers.map((layer) =>
        layer.updateObjects((obj) => {
          if (!layer.locked && obj.selected) {
            return { ...obj, cap: cap };
          }
          return obj;
        }),
      );

      return {
        ...prevState,
        layers: updatedLayers,
      };
    });
  };

  updateCornerType = (corner: CornerType) => {
    this.setState((prevState) => {
      const updatedLayers = prevState.layers.map((layer) =>
        layer.updateObjects((obj) => {
          if (!layer.locked && obj.selected) {
            return { ...obj, corner: corner };
          }
          return obj;
        }),
      );

      return {
        ...prevState,
        layers: updatedLayers,
      };
    });
  };

  updateObjectVisibility = (visible: boolean) => {
    this.setState((prevState) => {
      const updatedLayers = prevState.layers.map((layer) =>
        layer.updateObjects((obj) => {
          if (obj.selected) {
            return { ...obj, visible: visible };
          }
          return obj;
        }),
      );

      return {
        ...prevState,
        layers: updatedLayers,
      };
    });
  };

  updateObjectVisibilityAtIndex = (index: number, visible: boolean) => {
    this.setState((prevState) => {
      let currentIndex = 0;
      const updatedLayers = prevState.layers.map((layer) => {
        const updatedLayer = layer.updateObjects((obj) => {
          if (currentIndex === index) {
            currentIndex++;
            return { ...obj, visible: visible };
          }
          currentIndex++;
          return obj;
        });
        return updatedLayer;
      });

      return {
        ...prevState,
        layers: updatedLayers,
      };
    });
  };

  updateObjectLocked = (locked: boolean) => {
    this.setState((prevState) => {
      const updatedLayers = prevState.layers.map((layer) =>
        layer.updateObjects((obj) => {
          if (obj.selected) {
            return { ...obj, locked: locked };
          }
          return obj;
        }),
      );

      return {
        ...prevState,
        layers: updatedLayers,
      };
    });
  };

  updateObjectLockedAtIndex = (index: number, locked: boolean) => {
    this.setState((prevState) => {
      let currentIndex = 0;
      const updatedLayers = prevState.layers.map((layer) => {
        const updatedLayer = layer.updateObjects((obj) => {
          if (currentIndex === index) {
            currentIndex++;
            return { ...obj, locked: locked };
          }
          currentIndex++;
          return obj;
        });
        return updatedLayer;
      });

      return {
        ...prevState,
        layers: updatedLayers,
      };
    });
  };

  updateOpacity = (opacity: number) => {
    this.setState((prevState) => {
      const updatedLayers = prevState.layers.map((layer) =>
        layer.updateObjects((obj) => {
          if (!layer.locked && obj.selected) {
            return { ...obj, opacity: opacity };
          }
          return obj;
        }),
      );

      return {
        ...prevState,
        layers: updatedLayers,
      };
    });
  };

  updateFillOpacity = (opacity: number) => {
    this.setState((prevState) => {
      const updatedLayers = prevState.layers.map((layer) =>
        layer.updateObjects((obj) => {
          if (!layer.locked && obj.selected) {
            return { ...obj, fillOpacity: opacity };
          }
          return obj;
        }),
      );

      return {
        ...prevState,
        layers: updatedLayers,
      };
    });
  };

  updateStrokeOpacity = (opacity: number) => {
    this.setState((prevState) => {
      const updatedLayers = prevState.layers.map((layer) =>
        layer.updateObjects((obj) => {
          if (!layer.locked && obj.selected) {
            return { ...obj, strokeOpacity: opacity };
          }
          return obj;
        }),
      );

      return {
        ...prevState,
        layers: updatedLayers,
      };
    });
  };

  deleteSelectedObjects = () => {
    this.setState((prevState) => {
      const updatedLayers = prevState.layers
        .map((layer) => this.deleteSelectedFromLayer(layer))
        .filter((layer) => layer !== null) as Layer[];

      return {
        ...prevState,
        layers: updatedLayers,
      };
    });
  };

  deleteSelectedLayers = () => {
    this.setState((prevState) => {
      const updatedLayers = prevState.layers
        .map((layer) => (layer.selected ? null : layer))
        .filter((layer) => layer !== null) as Layer[];

      return {
        ...prevState,
        layers: updatedLayers,
      };
    });
  };

  findLayerFromObject(obj: ArtboardObject): Layer | null {
    for (const layer of this.state.layers) {
      for (const o of layer.getObjects()) {
        if (obj === o) {
          return layer;
        }
      }
    }
    return null;
  }

  updateLayerSelected = (layer: Layer | null, selected: boolean) => {
    this.setState((prevState) => {
      const updatedLayers = prevState.layers.map((obj) => {
        return layer === obj ? obj.setSelected(selected) : obj.setSelected(false);
      });
      return { ...prevState, layers: updatedLayers };
    });
  };

  toggleSelectedForObjectsInLayer = (layer: Layer) => {
    this.setState((prevState) => {
      const foundLayer = prevState.layers.find((l) => layer === l);
      const foundIndex = prevState.layers.indexOf(layer);
      if (foundLayer) {
        const objects = foundLayer.getObjects();
        const selected = objects.filter((item) => item.selected).length;
        const unselected = objects.length - selected;
        const shouldBeSelected = unselected >= selected;
        const updatedLayer = foundLayer.updateObjects((obj) => {
          return { ...obj, selected: shouldBeSelected };
        });
        const updatedLayers = prevState.layers.map((l, index) =>
          index == foundIndex
            ? updatedLayer
            : l.updateObjects((obj) => {
                if (obj.selected) {
                  return { ...obj, selected: false };
                }
                return obj;
              }),
        );
        return { ...prevState, layers: updatedLayers };
      } else return prevState;
    });
  };

  updateLayerVisible = (layer: Layer, visible: boolean) => {
    this.setState((prevState) => {
      const updatedLayers = prevState.layers.map((obj) => {
        return layer === obj ? obj.setVisible(visible) : obj;
      });
      return { ...prevState, layers: updatedLayers };
    });
  };

  updateLayerLocked = (layer: Layer, locked: boolean) => {
    this.setState((prevState) => {
      const updatedLayers = prevState.layers.map((obj) => {
        return layer === obj ? obj.setLocked(locked) : obj;
      });
      return { ...prevState, layers: updatedLayers };
    });
  };

  private deleteSelectedFromLayer(layer: Layer): Layer | null {
    const objects = layer.getObjects();
    const hasSelected = objects.some((obj) => obj.selected);

    if (!hasSelected) {
      return layer;
    }

    // For PrimitiveObjectLayer
    if (layer instanceof PrimitiveObjectLayer) {
      const obj = objects[0];
      if (obj.selected) {
        return null; // Delete this layer
      }
      return layer;
    }

    // For GroupLayer
    if (layer instanceof GroupLayer) {
      const updatedLayers = layer
        .getLayers()
        .map((childLayer) => this.deleteSelectedFromLayer(childLayer))
        .filter((childLayer) => childLayer !== null) as Layer[];

      if (updatedLayers.length === 0) {
        return null; // GroupLayer is empty after deletion
      }
      return new GroupLayer(updatedLayers, layer.selected, layer.visible, layer.locked);
    }

    return layer;
  }

  // Z-index methods
  moveObjectUp = () => {
    this.setState((prevState) => {
      const selectedObjects = ArtboardViewModel.getStateSelectedObjects(prevState);
      if (selectedObjects.length === 0) return prevState;

      // For simplicity, we adjust only top-level layers
      const layers = prevState.layers.slice();

      // Find the index of the layer containing the selected object
      const layerIndex = layers.findIndex((layer) =>
        layer.getObjects().some((obj) => obj.selected && !layer.locked),
      );

      if (layerIndex <= 0) return prevState; // Cannot move up if at the top

      // Swap the selected layer with the one above it
      [layers[layerIndex - 1], layers[layerIndex]] = [layers[layerIndex], layers[layerIndex - 1]];

      return {
        ...prevState,
        layers: layers,
      };
    });
  };

  moveObjectDown = () => {
    this.setState((prevState) => {
      const selectedObjects = ArtboardViewModel.getStateSelectedObjects(prevState);
      if (selectedObjects.length === 0) return prevState;

      // For simplicity, we adjust only top-level layers
      const layers = prevState.layers.slice();

      // Find the index of the layer containing the selected object
      const layerIndex = layers.findIndex((layer) =>
        layer.getObjects().some((obj) => obj.selected && !layer.locked),
      );

      if (layerIndex === -1 || layerIndex >= layers.length - 1) return prevState; // Cannot move down if at the bottom

      // Swap the selected layer with the one below it
      [layers[layerIndex], layers[layerIndex + 1]] = [layers[layerIndex + 1], layers[layerIndex]];

      return {
        ...prevState,
        layers: layers,
      };
    });
  };

  // Move Layer Up
  moveLayerUp = (layerIndex: number): void => {
    if (layerIndex < this.state.layers.length - 1) {
      const layers = [...this.state.layers];
      [layers[layerIndex], layers[layerIndex + 1]] = [layers[layerIndex + 1], layers[layerIndex]];
      this.setState({ ...this.state, layers });
    }
  };

  // Move Layer Down
  moveLayerDown = (layerIndex: number): void => {
    if (layerIndex > 0) {
      const layers = [...this.state.layers];
      [layers[layerIndex], layers[layerIndex - 1]] = [layers[layerIndex - 1], layers[layerIndex]];
      this.setState({ ...this.state, layers });
    }
  };

  // Bring Object Forward
  bringForward = (layerIndex: number, objectIndex: number): void => {
    const layer = this.state.layers[layerIndex];
    if (objectIndex < layer.getObjects().length - 1) {
      const objects = [...layer.getObjects()];
      [objects[objectIndex], objects[objectIndex + 1]] = [
        objects[objectIndex + 1],
        objects[objectIndex],
      ];
      const updatedLayer = { ...layer, objects };
      const layers = [...this.state.layers];
      layers[layerIndex] = updatedLayer;
      this.setState({ ...this.state, layers });
    }
  };

  // Send Object Backward
  sendBackward = (layerIndex: number, objectIndex: number): void => {
    const layer = this.state.layers[layerIndex];
    if (objectIndex > 0) {
      const objects = [...layer.getObjects()];
      [objects[objectIndex], objects[objectIndex - 1]] = [
        objects[objectIndex - 1],
        objects[objectIndex],
      ];
      const updatedLayer = { ...layer, objects };
      const layers = [...this.state.layers];
      layers[layerIndex] = updatedLayer;
      this.setState({ ...this.state, layers });
    }
  };

  updateObjectAtIndex = (index: number, updatedObj: ArtboardObject) => {
    this.setState((prevState) => {
      let currentIndex = 0;
      const updatedLayers = prevState.layers.map((layer) =>
        layer.updateObjects((obj) => {
          if (currentIndex === index) {
            currentIndex++;
            return updatedObj;
          }
          currentIndex++;
          return obj;
        }),
      );

      return {
        ...prevState,
        layers: updatedLayers,
      };
    });
  };

  // Update methods for selection and hovering
  updateSelected = (index: number, selected: boolean) => {
    this.setState((prevState) => {
      let currentIndex = 0;
      const updatedLayers = prevState.layers.map((layer) => {
        const updatedLayer = layer.updateObjects((obj) => {
          if (currentIndex === index) {
            currentIndex++;
            return { ...obj, selected: selected };
          }
          currentIndex++;
          return obj;
        });
        return updatedLayer;
      });

      return {
        ...prevState,
        layers: updatedLayers,
      };
    });
  };

  clearAllSelected = () => {
    this.setState((prevState) => {
      const updatedLayers = prevState.layers.map((layer) =>
        layer.updateObjects((obj) => {
          if (obj.selected) {
            return { ...obj, selected: false };
          }
          return obj;
        }),
      );

      return {
        ...prevState,
        layers: updatedLayers,
      };
    });
  };

  updateSelectedVertexIndex = (index: number | null) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        selectedVertexIndex: index,
      };
    });
  };

  updateHoveredObjectIndex = (index: number | null) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        hoveredObjectIndex: index,
      };
    });
  };

  updateHoveredVertexIndex = (index: number | null) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        hoveredVertexIndex: index,
      };
    });
  };

  updateZoomScale = (scale: number) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        scaleOffset: { ...prevState.scaleOffset, scaleX: scale, scaleY: scale },
      };
    });
  };

  updateArtboardSize = (dimensions: Dimensions) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        boardDimensions: dimensions,
      };
    });
  };

  updatePanX = (x: number) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        scaleOffset: { ...prevState.scaleOffset, x: x },
      };
    });
  };

  updatePanY = (y: number) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        scaleOffset: { ...prevState.scaleOffset, y: y },
      };
    });
  };

  updateGlobalStrokeColor = (newColor: ColorValues) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        strokeColor: newColor,
      };
    });
  };

  updateGlobalFillColor = (newColor: ColorValues) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        fillColor: newColor,
      };
    });
  };

  updateGlobalUseStrokeColor = (useColor: boolean) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        useStrokeColor: useColor,
      };
    });
  };

  updateGlobalUseFillColor = (useColor: boolean) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        useFillColor: useColor,
      };
    });
  };

  updateGlobalColorEditingType = (isStroke: boolean) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        editingStroke: isStroke,
      };
    });
  };

  swapStrokeAndFill = () => {
    this.setState((prevState) => {
      const stroke = prevState.fillColor;
      const fill = prevState.strokeColor;
      const useStroke = prevState.useFillColor;
      const useFill = prevState.useStrokeColor;
      return {
        ...prevState,
        strokeColor: stroke,
        fillColor: fill,
        useStrokeColor: useStroke,
        useFillColor: useFill,
      };
    });
  };

  getStrokeColorHex(): string {
    return this.state.useStrokeColor ? this.state.strokeColor.hex : 'transparent';
  }

  getFillColorHex(): string {
    return this.state.useFillColor ? this.state.fillColor.hex : 'transparent';
  }
}

export const useArtboardViewModel = (initialDimensions: Dimensions) => {
  const [artboardState, setArtboardState] = useState<ArtboardState>({
    boardDimensions: new Dimensions(initialDimensions.w, initialDimensions.h),
    scaleOffset: new ScaleOffset(0, 0, 1, 1),
    layers: [],
    selectedVertexIndex: null,
    hoveredObjectIndex: null,
    hoveredVertexIndex: null,
    strokeColor: ColorValueUtil.BLACK,
    fillColor: ColorValueUtil.WHITE,
    useStrokeColor: true,
    useFillColor: true,
    editingStroke: false,
  });

  return new ArtboardViewModel(artboardState, setArtboardState);
};
