// Path.ts

import { ArtboardObject } from '../ArtboardObject';
import { ScaleOffset } from '../math/ScaleOffset';
import { Vertex } from '../math/Vertex';
import { CapType } from './CapType';
import { CornerType } from './CornerType';

export interface Path extends ArtboardObject {
  vertices: Vertex[];
  fill: string;
  strokeWidth: number;
  strokeColor: string;
  closed: boolean;
  cap: CapType;
  corner: CornerType;
  fillOpacity: number;
  strokeOpacity: number;
}

export class PathUtils {
  static createInstance(vertices: Vertex[], zIndex = 0): Path {
    return {
      vertices: vertices,
      fill: 'transparent',
      strokeWidth: 1,
      scaleOffset: new ScaleOffset(),
      strokeColor: '#000000',
      closed: false,
      cap: CapType.BUTT,
      corner: CornerType.MITER,
      opacity: 1,
      fillOpacity: 1,
      strokeOpacity: 1,
      zIndex: zIndex,
      selected: false,
    };
  }
}
