export enum CornerType {
  MITER,
  ROUND,
  BEVEL,
}

export const CornerTypeUtils = {
  toCanvasLineJoin(cornerType: CornerType): CanvasLineJoin {
    switch (cornerType) {
      case CornerType.MITER:
        return 'miter';
      case CornerType.ROUND:
        return 'round';
      case CornerType.BEVEL:
        return 'bevel';
      default:
        throw new Error('Invalid CornerType');
    }
  },
};
