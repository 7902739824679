// AnchorPointTool.ts

import { Tool, ToolContext } from './Tool';
import { Point } from '../math/Point';
import { Vertex } from '../math/Vertex';
import { Path } from '../geometry/Path';
import { ScaleOffset } from '../math/ScaleOffset';
import { NormalizedTouchEvent } from '../../ui/input/MouseTouch';

type DraggingState = null | {
  type: 'vertex' | 'controlHandle';
  objectIndex: number;
  vertexIndex: number;
  controlHandle?: 'left' | 'right';
  initialMousePos: Point;
  initialVertex: Vertex;
  hasMoved: boolean;
};

export class AnchorPointTool implements Tool {
  private draggingState: DraggingState = null;
  private readonly dragThreshold = 5; // pixels

  onMouseDown(event: NormalizedTouchEvent, context: ToolContext): void {
    const { x, y } = this.getMousePos(event, context);
    const { artboardViewModel } = context;

    const mousePoint = new Point(x, y);

    let selectedObject = artboardViewModel.getSelectedObjects()[0] ?? null;

    // If no object is selected, try to select one
    if (selectedObject == null) {
      const objects = artboardViewModel.getObjects();
      for (let i = objects.length - 1; i >= 0; i--) {
        const obj = objects[i];
        // TODO fix this...
        const layer = artboardViewModel.findLayerFromObject(obj);
        if (layer?.locked) continue;
        if (!artboardViewModel.isPath(obj)) continue;
        const path = obj as Path;
        if (this.isPointOnPath(x, y, path, context)) {
          artboardViewModel.updateSelected(i, true);
          selectedObject = obj;
          // First click selects the path, do not proceed further
          return;
        }
      }
      // No path under mouse, clear selection
      artboardViewModel.clearAllSelected();
      return;
    }

    // If an object is already selected, check if user clicked on a vertex or control handle
    const obj = selectedObject;
    // TODO fix
    const layer = artboardViewModel.findLayerFromObject(obj);
    const selectedObjectIndex = artboardViewModel.getObjects().indexOf(obj);
    if (layer?.locked || selectedObjectIndex == -1) return;
    if (!artboardViewModel.isPath(obj)) return;
    const path = obj as Path;
    const hitResult = this.hitTest(mousePoint, path, selectedObjectIndex, context);
    if (hitResult) {
      const { objectIndex, vertexIndex, hitType } = hitResult;
      const vertex = path.vertices[vertexIndex];

      // Start dragging state but do not modify the vertex or control point yet
      this.draggingState = {
        type: hitType === 'vertex' ? 'vertex' : 'controlHandle',
        objectIndex,
        vertexIndex,
        controlHandle: hitType === 'controlLeft' ? 'left' : 'right',
        initialMousePos: mousePoint,
        initialVertex: vertex,
        hasMoved: false,
      };
    } else {
      // Clicked elsewhere on the selected path but not on a vertex/control handle
      // For now, do nothing
    }
  }

  onMouseMove(event: NormalizedTouchEvent, context: ToolContext): boolean {
    if (!this.draggingState) return false;

    const { x, y } = this.getMousePos(event, context);
    const { artboardViewModel } = context;

    const mousePoint = new Point(x, y);

    const { objectIndex, initialMousePos } = this.draggingState;

    const obj = artboardViewModel.getObjects()[objectIndex];
    if (!artboardViewModel.isPath(obj)) return false;
    const path = obj as Path;

    const dx = x - initialMousePos.x;
    const dy = y - initialMousePos.y;
    const distanceSquared = dx * dx + dy * dy;

    if (!this.draggingState.hasMoved && distanceSquared > this.dragThreshold * this.dragThreshold) {
      this.draggingState.hasMoved = true;
    }

    if (this.draggingState.hasMoved) {
      const { vertexIndex, initialVertex } = this.draggingState;
      const vertex = path.vertices[vertexIndex];

      if (this.draggingState.type === 'vertex') {
        // User is dragging the vertex to adjust control handles
        const controlLeft = new Point(initialVertex.base.x - dx, initialVertex.base.y - dy);
        const controlRight = new Point(initialVertex.base.x + dx, initialVertex.base.y + dy);

        const newVertex = new Vertex(vertex.base, controlLeft, controlRight, false);

        const newVertices = [...path.vertices];
        newVertices[vertexIndex] = newVertex;

        const newPath: Path = {
          ...path,
          vertices: newVertices,
        };

        // Update the object in the artboard
        artboardViewModel.updateObjectAtIndex(objectIndex, newPath);
      } else if (this.draggingState.type === 'controlHandle') {
        // User is dragging a control handle
        const controlHandle = this.draggingState.controlHandle!;
        let newControlLeft = vertex.controlLeft;
        let newControlRight = vertex.controlRight;

        const adjustedMousePoint = new Point(
          mousePoint.x - path.scaleOffset.x,
          mousePoint.y - path.scaleOffset.y,
        );

        if (controlHandle === 'left') {
          newControlLeft = adjustedMousePoint;

          if (vertex.linkedHandles && vertex.controlRight) {
            // Adjust the other handle symmetrically
            const baseToLeft = new Point(
              newControlLeft.x - vertex.base.x,
              newControlLeft.y - vertex.base.y,
            );
            newControlRight = new Point(vertex.base.x - baseToLeft.x, vertex.base.y - baseToLeft.y);
          }
        } else {
          newControlRight = adjustedMousePoint;

          if (vertex.linkedHandles && vertex.controlLeft) {
            // Adjust the other handle symmetrically
            const baseToRight = new Point(
              newControlRight.x - vertex.base.x,
              newControlRight.y - vertex.base.y,
            );
            newControlLeft = new Point(
              vertex.base.x - baseToRight.x,
              vertex.base.y - baseToRight.y,
            );
          }
        }

        const newVertex = new Vertex(
          vertex.base,
          newControlLeft,
          newControlRight,
          vertex.linkedHandles,
        );

        const newVertices = [...path.vertices];
        newVertices[vertexIndex] = newVertex;

        const newPath: Path = {
          ...path,
          vertices: newVertices,
        };

        // Update the object in the artboard
        artboardViewModel.updateObjectAtIndex(objectIndex, newPath);
      }
    }
    return false;
  }

  onMouseUp(event: NormalizedTouchEvent, context: ToolContext): void {
    if (!this.draggingState) return;

    const { hasMoved, objectIndex, vertexIndex } = this.draggingState;
    const { artboardViewModel } = context;

    const obj = artboardViewModel.getObjects()[objectIndex];
    if (!artboardViewModel.isPath(obj)) {
      this.draggingState = null;
      return;
    }
    const path = obj as Path;
    const vertex = path.vertices[vertexIndex];

    if (!hasMoved) {
      // User performed a click without significant movement
      if (this.draggingState.type === 'vertex') {
        // Clicking on a vertex: remove control handles
        const newVertex = new Vertex(vertex.base, null, null, false);

        const newVertices = [...path.vertices];
        newVertices[vertexIndex] = newVertex;

        const newPath: Path = {
          ...path,
          vertices: newVertices,
        };

        artboardViewModel.updateObjectAtIndex(objectIndex, newPath);
      } else if (this.draggingState.type === 'controlHandle') {
        // Clicking on a control handle: remove it
        const controlHandle = this.draggingState.controlHandle!;
        let newControlLeft = vertex.controlLeft;
        let newControlRight = vertex.controlRight;

        if (controlHandle === 'left') {
          newControlLeft = null;
        } else {
          newControlRight = null;
        }

        const newVertex = new Vertex(vertex.base, newControlLeft, newControlRight, false);

        const newVertices = [...path.vertices];
        newVertices[vertexIndex] = newVertex;

        const newPath: Path = {
          ...path,
          vertices: newVertices,
        };

        artboardViewModel.updateObjectAtIndex(objectIndex, newPath);
      }
    }

    this.draggingState = null;
  }

  drawOverlay(context: ToolContext, ctx: CanvasRenderingContext2D): void {
    const { canvas, artboardViewModel } = context;

    // Get scale and translation from artboardViewModel
    const scaleOffset = artboardViewModel.state.scaleOffset;
    const artboardDimensions = artboardViewModel.state.boardDimensions;

    // Apply the same transformations as in MainCanvas
    const rect = canvas.getBoundingClientRect();

    ctx.save();

    // Apply pan and zoom transformations
    ctx.translate(scaleOffset.x, scaleOffset.y);
    ctx.scale(scaleOffset.scaleX, scaleOffset.scaleX);

    // Center the artboard within the canvas
    const xOffset = (rect.width / scaleOffset.scaleX - artboardDimensions.w) / 2;
    const yOffset = (rect.height / scaleOffset.scaleX - artboardDimensions.h) / 2;

    ctx.translate(xOffset, yOffset);

    const selectedObject = artboardViewModel.getSelectedObjects()[0] ?? null;

    // Draw overlays only for the selected path
    if (selectedObject != null) {
      const obj = selectedObject;
      if (!artboardViewModel.isPath(obj)) return;
      const path = obj as Path;
      const vertices = path.vertices;

      // Draw control handles and control points
      ctx.strokeStyle = '#1E90FF'; // Dodger blue for handles
      ctx.fillStyle = '#1E90FF';

      vertices.forEach((vertex) => {
        const base = vertex.base;
        const controlLeft = vertex.controlLeft || base;
        const controlRight = vertex.controlRight || base;

        const offsetX = path.scaleOffset.x;
        const offsetY = path.scaleOffset.y;

        // Draw lines from base to control points
        if (controlLeft !== base && (controlLeft.x !== base.x || controlLeft.y !== base.y)) {
          ctx.beginPath();
          ctx.moveTo(base.x + offsetX, base.y + offsetY);
          ctx.lineTo(controlLeft.x + offsetX, controlLeft.y + offsetY);
          ctx.lineWidth = 1 / scaleOffset.scaleX;
          ctx.stroke();

          // Draw control left point
          ctx.beginPath();
          ctx.arc(
            controlLeft.x + offsetX,
            controlLeft.y + offsetY,
            3 / scaleOffset.scaleX,
            0,
            Math.PI * 2,
          );
          ctx.fill();
        }

        if (controlRight !== base && (controlRight.x !== base.x || controlRight.y !== base.y)) {
          ctx.beginPath();
          ctx.moveTo(base.x + offsetX, base.y + offsetY);
          ctx.lineTo(controlRight.x + offsetX, controlRight.y + offsetY);
          ctx.lineWidth = 1 / scaleOffset.scaleX;
          ctx.stroke();

          // Draw control right point
          ctx.beginPath();
          ctx.arc(
            controlRight.x + offsetX,
            controlRight.y + offsetY,
            3 / scaleOffset.scaleX,
            0,
            Math.PI * 2,
          );
          ctx.fill();
        }
      });

      // Draw vertices (anchor points)
      ctx.beginPath();
      vertices.forEach((vertex) => {
        ctx.moveTo(
          vertex.base.x + path.scaleOffset.x + 4 / scaleOffset.scaleX,
          vertex.base.y + path.scaleOffset.y,
        );
        ctx.arc(
          vertex.base.x + path.scaleOffset.x,
          vertex.base.y + path.scaleOffset.y,
          4 / scaleOffset.scaleX,
          0,
          Math.PI * 2,
        );
      });
      ctx.fillStyle = '#FFFFFF'; // White fill for anchor points
      ctx.fill();
      ctx.strokeStyle = '#1E90FF';
      ctx.lineWidth = 1 / scaleOffset.scaleX;
      ctx.stroke();
    }

    ctx.restore();
  }

  private getMousePos(event: NormalizedTouchEvent, context: ToolContext): { x: number; y: number } {
    const rect = context.canvas.getBoundingClientRect();
    const scaleOffset = context.artboardViewModel.state.scaleOffset;
    const artboardDimensions = context.artboardViewModel.state.boardDimensions;

    const point = ScaleOffset.screenToCanvasPoint(
      event.x,
      event.y,
      rect,
      scaleOffset,
      artboardDimensions,
    );

    return { x: point.x, y: point.y };
  }

  private hitTest(
    point: Point,
    path: Path,
    objectIndex: number,
    context: ToolContext,
  ): {
    objectIndex: number;
    vertexIndex: number;
    hitType: 'vertex' | 'controlLeft' | 'controlRight';
  } | null {
    const scale = context.artboardViewModel.state.scaleOffset.scaleX;
    const hitRadius = 5 / scale;

    const offsetX = path.scaleOffset.x;
    const offsetY = path.scaleOffset.y;

    for (let vertexIndex = 0; vertexIndex < path.vertices.length; vertexIndex++) {
      const vertex = path.vertices[vertexIndex];

      // Adjust points by offset
      const base = new Point(vertex.base.x + offsetX, vertex.base.y + offsetY);
      const controlLeft = vertex.controlLeft
        ? new Point(vertex.controlLeft.x + offsetX, vertex.controlLeft.y + offsetY)
        : null;
      const controlRight = vertex.controlRight
        ? new Point(vertex.controlRight.x + offsetX, vertex.controlRight.y + offsetY)
        : null;

      // Check controlLeft
      if (controlLeft && this.isPointNear(point, controlLeft, hitRadius)) {
        return { objectIndex, vertexIndex, hitType: 'controlLeft' };
      }

      // Check controlRight
      if (controlRight && this.isPointNear(point, controlRight, hitRadius)) {
        return { objectIndex, vertexIndex, hitType: 'controlRight' };
      }

      // Check vertex
      if (this.isPointNear(point, base, hitRadius)) {
        return { objectIndex, vertexIndex, hitType: 'vertex' };
      }
    }

    return null;
  }

  private isPointNear(p1: Point, p2: Point, radius: number): boolean {
    const dx = p1.x - p2.x;
    const dy = p1.y - p2.y;
    return dx * dx + dy * dy <= radius * radius;
  }

  private isPointOnPath(x: number, y: number, path: Path, context: ToolContext): boolean {
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = 1;
    tempCanvas.height = 1;
    const ctx = tempCanvas.getContext('2d');
    if (!ctx) return false;

    const scale = context.artboardViewModel.state.scaleOffset.scaleX;

    ctx.beginPath();

    const offsetX = path.scaleOffset.x;
    const offsetY = path.scaleOffset.y;
    const vertices = path.vertices;

    if (vertices.length > 0) {
      const firstVertex = vertices[0];
      ctx.moveTo(firstVertex.base.x + offsetX, firstVertex.base.y + offsetY);

      for (let i = 1; i < vertices.length; i++) {
        const prevVertex = vertices[i - 1];
        const currVertex = vertices[i];

        // Use base point if control point is null
        const cp1 = prevVertex.controlRight || prevVertex.base;
        const cp2 = currVertex.controlLeft || currVertex.base;

        ctx.bezierCurveTo(
          cp1.x + offsetX,
          cp1.y + offsetY,
          cp2.x + offsetX,
          cp2.y + offsetY,
          currVertex.base.x + offsetX,
          currVertex.base.y + offsetY,
        );
      }

      if (path.closed) {
        const lastVertex = vertices[vertices.length - 1];
        const cp1 = lastVertex.controlRight || lastVertex.base;
        const cp2 = firstVertex.controlLeft || firstVertex.base;

        ctx.bezierCurveTo(
          cp1.x + offsetX,
          cp1.y + offsetY,
          cp2.x + offsetX,
          cp2.y + offsetY,
          firstVertex.base.x + offsetX,
          firstVertex.base.y + offsetY,
        );
        ctx.closePath();
      }

      // Increase line width for easier hit detection
      ctx.lineWidth = ((path.strokeWidth || 1) + 5) / scale;

      return ctx.isPointInStroke(x, y) || ctx.isPointInPath(x, y);
    }

    return false;
  }
}
