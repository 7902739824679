import React from 'react';
import { ArtboardViewModel } from '../../viewmodel/ArtboardViewModel';
import { ArtboardObject } from '../../lib/ArtboardObject';
import PercentEntryWidget from '../components/PercentEntryWidget';

interface ObjectPropertiesProps {
  artboardViewModel: ArtboardViewModel;
  selectedObject: ArtboardObject;
}

const ObjectProperties: React.FC<ObjectPropertiesProps> = ({
  artboardViewModel,
  selectedObject,
}) => {
  return (
    <div
      style={{
        userSelect: 'none',
        fontSize: '12px',
        color: 'white',
        width: '100%', // Ensure it fits the container
        maxWidth: '100%', // Prevent any overflow
        boxSizing: 'border-box',
        overflow: 'hidden',
      }}
    >
      {/* Move Up and Move Down Buttons */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          marginBottom: '10px',
          paddingLeft: '5px',
          gap: '5px',
        }}
      >
        <span style={{ width: '50px', textAlign: 'right' }}>Z-Index: </span>
        <div
          onClick={artboardViewModel.moveObjectUp}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'none',
            color: 'white',
            fontSize: '1.05rem',
            cursor: 'pointer',
            width: '32px',
            height: '32px',
            border: '1px solid grey',
            boxSizing: 'border-box',
          }}
        >
          ▲
        </div>
        <div
          onClick={artboardViewModel.moveObjectDown}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'none',
            color: 'white',
            fontSize: '1.05rem',
            cursor: 'pointer',
            width: '32px',
            height: '32px',
            border: '1px solid grey',
            boxSizing: 'border-box',
          }}
        >
          ▼
        </div>
      </div>

      {/* Opacity */}
      <div style={{ marginBottom: '10px', display: 'flex', gap: '10px', alignItems: 'center' }}>
        <label
          style={{
            display: 'block',
            marginBottom: '5px',
            whiteSpace: 'nowrap',
            width: '50px',
            textAlign: 'right',
          }}
        >
          Opacity:
        </label>
        <PercentEntryWidget
          initialValue={selectedObject.opacity * 100}
          onChange={(n) => artboardViewModel.updateOpacity(n / 100)}
        />
      </div>
    </div>
  );
};

export default ObjectProperties;
