// DraggableWindow.tsx
import React, { useState, useEffect, useRef } from 'react';

type DraggableWindowProps = {
  title: string;
  content: React.ReactNode;
  onClose: (data: string | null) => void;
};

const DraggableWindow: React.FC<DraggableWindowProps> = ({ title, content, onClose }) => {
  const windowRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: window.innerWidth / 4,
    y: window.innerHeight / 4,
  });
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [offset, setOffset] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [isFlashing, setIsFlashing] = useState<boolean>(false);

  const headerHeight = 40; // Height of the header in pixels

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation(); // Prevent triggering parent events
    setIsDragging(true);
    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isDragging) {
      let newX = e.clientX - offset.x;
      let newY = e.clientY - offset.y;

      // Prevent the top bar from going off-screen on the y-axis (top and bottom)
      newY = Math.max(0, Math.min(newY, window.innerHeight - headerHeight));

      // Ensure at least 100 pixels of the window remain visible on the x-axis
      const windowWidth = windowRef.current ? windowRef.current.offsetWidth : 0;
      if (windowWidth) {
        newX = Math.max(100 - windowWidth, Math.min(newX, window.innerWidth - 100));
      }

      setPosition({
        x: newX,
        y: newY,
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  // Function to handle overlay click and flash the header
  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation(); // Prevent click-through
    setIsFlashing(true);
  };

  const handleAnimationEnd = () => {
    setIsFlashing(false);
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'transparent', // No tint
        zIndex: 9999,
      }}
      onClick={handleOverlayClick}
    >
      {/* Define keyframes inside a style tag */}
      <style>
        {`
          @keyframes flash {
            0% { background-color: #f1f1f1; }
            25% { background-color: orange; }
            50% { background-color: #f1f1f1; }
            75% { background-color: orange; }
            100% { background-color: #f1f1f1; }
          }
        `}
      </style>
      <div
        ref={windowRef}
        style={{
          position: 'absolute',
          backgroundColor: '#535353',
          boxShadow: '0 2px 10px rgba(0,0,0,0.5)',
          borderRadius: '8px',
          overflow: 'hidden',
          transform: `translate(${position.x}px, ${position.y}px)`,
        }}
        onClick={(e) => {
          e.stopPropagation(); // Prevent clicks inside the window from triggering the overlay click
        }}
      >
        <div
          style={{
            height: `${headerHeight}px`,
            backgroundColor: 'orange',
            cursor: 'move',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 10px',
            userSelect: 'none',
            animation: isFlashing ? 'flash 0.3s ease-in-out 2' : 'none',
          }}
          onMouseDown={handleMouseDown}
          onAnimationEnd={handleAnimationEnd}
        >
          <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</span>
          <button
            style={{
              background: 'none',
              border: 'none',
              fontSize: '24px',
              cursor: 'pointer',
              lineHeight: '1',
            }}
            onClick={() => onClose(null)}
          >
            ×
          </button>
        </div>
        <div style={{ padding: '10px' }}>{content}</div>
      </div>
    </div>
  );
};

export default DraggableWindow;
