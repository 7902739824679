// DirectSelectionTool.ts

import { Path } from '../geometry/Path';
import { Point } from '../math/Point';
import { Vertex } from '../math/Vertex';
import { Tool, ToolContext } from './Tool';
import { ScaleOffset } from '../math/ScaleOffset';
import { NormalizedTouchEvent } from '../../ui/input/MouseTouch';

type DraggingType = null | {
  type: 'vertex' | 'controlLeft' | 'controlRight' | 'prevControlRight' | 'nextControlLeft';
  objectIndex: number;
  vertexIndex: number;
  initialMousePos: { x: number; y: number };
  initialVertex: Vertex;
  initialOffset: Point;
  altKeyPressed: boolean;
};

export class DirectSelectionTool implements Tool {
  private dragging: DraggingType = null;

  onMouseDown(event: NormalizedTouchEvent, context: ToolContext): void {
    const { x, y } = this.getMousePos(event, context);
    const { artboardViewModel } = context;

    const clickThreshold = 10;

    // Collect control points from all path objects
    const allControlPoints = this.getAllControlPoints(artboardViewModel);

    // Sort control points by zIndex in descending order
    allControlPoints.sort((a, b) => b.zIndex - a.zIndex);

    // Check if any control point is clicked
    for (const control of allControlPoints) {
      const obj = artboardViewModel.getObjects()[control.objectIndex];
      if (!artboardViewModel.isPath(obj)) continue;
      const path = obj as Path;
      const offset = path.scaleOffset;

      const dx = x - (control.point.x + offset.x);
      const dy = y - (control.point.y + offset.y);
      const distance = Math.sqrt(dx * dx + dy * dy);

      if (distance <= clickThreshold) {
        // Start dragging control point
        const isAltPressed = event.altKey || event.metaKey;

        const vertex = path.vertices[control.vertexIndex];

        // Break handles if Alt/Option key is pressed
        if (isAltPressed && vertex.linkedHandles) {
          const updatedVertices = [...path.vertices];

          const updatedVertex = new Vertex(
            vertex.base,
            vertex.controlLeft,
            vertex.controlRight,
            false, // Break the handles
          );

          updatedVertices[control.vertexIndex] = updatedVertex;
          const updatedPath: Path = {
            ...path,
            vertices: updatedVertices,
          };

          artboardViewModel.updateObjectAtIndex(control.objectIndex, updatedPath);
        }

        // Update selected object and vertex
        artboardViewModel.updateSelected(control.objectIndex, true);
        artboardViewModel.updateSelectedVertexIndex(control.vertexIndex);

        this.dragging = {
          type: control.type,
          objectIndex: control.objectIndex,
          vertexIndex: control.vertexIndex,
          initialMousePos: { x, y },
          initialVertex: vertex,
          initialOffset: new Point(path.scaleOffset.x, path.scaleOffset.y),
          altKeyPressed: isAltPressed,
        };

        return;
      }
    }

    // Try to find a vertex under the mouse, considering zIndex
    const result = this.findVertexAtPosition(x, y, artboardViewModel);

    if (result) {
      const { objectIndex, vertexIndex } = result;
      artboardViewModel.updateSelected(objectIndex, true);
      artboardViewModel.updateSelectedVertexIndex(vertexIndex);

      const obj = artboardViewModel.getObjects()[objectIndex];
      if (!artboardViewModel.isPath(obj)) return;
      const path = obj as Path;
      const vertex = path.vertices[vertexIndex];

      // Check if base vertex is clicked
      const dx = x - (vertex.base.x + path.scaleOffset.x);
      const dy = y - (vertex.base.y + path.scaleOffset.y);
      const distance = Math.sqrt(dx * dx + dy * dy);

      if (distance <= clickThreshold) {
        // Start dragging vertex
        this.dragging = {
          type: 'vertex',
          objectIndex,
          vertexIndex,
          initialMousePos: { x, y },
          initialVertex: vertex,
          initialOffset: new Point(path.scaleOffset.x, path.scaleOffset.y),
          altKeyPressed: false,
        };
      }

      // If not clicking directly on the vertex, selection is updated but no dragging occurs
    } else {
      // No vertex under mouse, check if point is on any path (considering zIndex)
      const objectIndex = this.findPathAtPosition(x, y, artboardViewModel);

      if (objectIndex !== null) {
        artboardViewModel.clearAllSelected();
        artboardViewModel.updateSelected(objectIndex, true);
        artboardViewModel.updateSelectedVertexIndex(null);
        return;
      }

      // No path under mouse, clear selection
      artboardViewModel.clearAllSelected();
      artboardViewModel.updateSelectedVertexIndex(null);
      this.dragging = null;
    }
  }

  onMouseMove(event: NormalizedTouchEvent, context: ToolContext): boolean {
    if (!this.dragging) return false;

    const { x, y } = this.getMousePos(event, context);
    const { artboardViewModel } = context;

    const dx = x - this.dragging.initialMousePos.x;
    const dy = y - this.dragging.initialMousePos.y;

    const objectIndex = this.dragging.objectIndex;
    const vertexIndex = this.dragging.vertexIndex;
    const obj = artboardViewModel.getObjects()[objectIndex];
    if (!artboardViewModel.isPath(obj)) return false;
    const path = obj as Path;
    const vertices = [...path.vertices];
    const vertex = this.dragging.initialVertex;
    let updatedVertex = vertex;

    if (this.dragging.type === 'vertex') {
      // Move base point and control points
      const newBase = new Point(vertex.base.x + dx, vertex.base.y + dy);

      const newControlLeft = vertex.controlLeft
        ? new Point(vertex.controlLeft.x + dx, vertex.controlLeft.y + dy)
        : null;
      const newControlRight = vertex.controlRight
        ? new Point(vertex.controlRight.x + dx, vertex.controlRight.y + dy)
        : null;

      updatedVertex = new Vertex(newBase, newControlLeft, newControlRight, vertex.linkedHandles);
    } else if (
      (this.dragging.type === 'controlLeft' || this.dragging.type === 'nextControlLeft') &&
      vertex.controlLeft
    ) {
      const newControlLeft = new Point(vertex.controlLeft.x + dx, vertex.controlLeft.y + dy);
      let newControlRight = vertex.controlRight;

      if (vertex.linkedHandles && vertex.controlRight && !this.dragging.altKeyPressed) {
        // Move controlRight in opposite direction
        const baseX = vertex.base.x;
        const baseY = vertex.base.y;

        const deltaX = newControlLeft.x - baseX;
        const deltaY = newControlLeft.y - baseY;

        newControlRight = new Point(baseX - deltaX, baseY - deltaY);
      }

      updatedVertex = new Vertex(
        vertex.base,
        newControlLeft,
        newControlRight,
        vertex.linkedHandles,
      );
    } else if (
      (this.dragging.type === 'controlRight' || this.dragging.type === 'prevControlRight') &&
      vertex.controlRight
    ) {
      const newControlRight = new Point(vertex.controlRight.x + dx, vertex.controlRight.y + dy);
      let newControlLeft = vertex.controlLeft;

      if (vertex.linkedHandles && vertex.controlLeft && !this.dragging.altKeyPressed) {
        // Move controlLeft in opposite direction
        const baseX = vertex.base.x;
        const baseY = vertex.base.y;

        const deltaX = newControlRight.x - baseX;
        const deltaY = newControlRight.y - baseY;

        newControlLeft = new Point(baseX - deltaX, baseY - deltaY);
      }

      updatedVertex = new Vertex(
        vertex.base,
        newControlLeft,
        newControlRight,
        vertex.linkedHandles,
      );
    }

    vertices[vertexIndex] = updatedVertex;
    const updatedPath: Path = {
      ...path,
      vertices: vertices,
    };
    artboardViewModel.updateObjectAtIndex(objectIndex, updatedPath);
    return true; // Return true to indicate canvas needs to rerender
  }

  onMouseUp(event: NormalizedTouchEvent, context: ToolContext): void {
    this.dragging = null;
  }

  drawOverlay(context: ToolContext, ctx: CanvasRenderingContext2D): void {
    const { canvas, artboardViewModel } = context;

    const selectedItems = artboardViewModel.getSelectedObjects();
    if (selectedItems.length == 0) return;

    const obj = selectedItems[0];
    if (!artboardViewModel.isPath(obj)) return;
    const path = obj as Path;
    const offset = path.scaleOffset;
    const vertices = path.vertices;

    // Get scale and translation from artboardViewModel
    const scaleOffset = artboardViewModel.state.scaleOffset;
    const artboardDimensions = artboardViewModel.state.boardDimensions;

    // Apply the same transformations as in MainCanvas
    const rect = canvas.getBoundingClientRect();

    ctx.save();

    // Apply pan and zoom transformations
    ctx.translate(scaleOffset.x, scaleOffset.y);
    ctx.scale(scaleOffset.scaleX, scaleOffset.scaleX);

    // Center the artboard within the canvas
    const xOffset = (rect.width / scaleOffset.scaleX - artboardDimensions.w) / 2;
    const yOffset = (rect.height / scaleOffset.scaleX - artboardDimensions.h) / 2;

    ctx.translate(xOffset + offset.x, yOffset + offset.y);

    // Draw squares over each vertex
    const baseSquareSize = 8 / scaleOffset.scaleX;
    const enlargedSquareSize = 12 / scaleOffset.scaleX;
    vertices.forEach((vertex, index) => {
      const { x: vx, y: vy } = vertex.base;

      // Determine if this vertex is selected or hovered
      const isHovered =
        artboardViewModel.state.hoveredObjectIndex != null &&
        artboardViewModel.getObjects()[artboardViewModel.state.hoveredObjectIndex].selected &&
        index === artboardViewModel.state.hoveredVertexIndex;
      const isSelected = index === artboardViewModel.state.selectedVertexIndex;

      // Adjust square size
      const squareSize = isHovered ? enlargedSquareSize : baseSquareSize;

      // Calculate the top-left corner of the square
      const squareX = vx - squareSize / 2;
      const squareY = vy - squareSize / 2;

      // Draw the square
      ctx.fillStyle = isSelected ? '#000000' : '#FFFFFF';
      ctx.fillRect(squareX, squareY, squareSize, squareSize);

      ctx.strokeStyle = '#000000';
      ctx.lineWidth = 1 / scaleOffset.scaleX;
      ctx.strokeRect(squareX, squareY, squareSize, squareSize);
    });

    // Draw control points and handles only if a vertex is selected
    if (
      artboardViewModel.state.selectedVertexIndex !== null &&
      artboardViewModel.state.selectedVertexIndex !== undefined
    ) {
      const selectedVertex = vertices[artboardViewModel.state.selectedVertexIndex];
      const { x: vx, y: vy } = selectedVertex.base;

      // Function to draw control point and handle
      const drawControlPoint = (fromX: number, fromY: number, controlPoint: Point) => {
        // Draw line from base to control point
        ctx.beginPath();
        ctx.moveTo(fromX, fromY);
        ctx.lineTo(controlPoint.x, controlPoint.y);
        ctx.strokeStyle = '#000000';
        ctx.lineWidth = 1 / scaleOffset.scaleX;
        ctx.stroke();

        // Draw control point (small black circle)
        ctx.beginPath();
        ctx.arc(controlPoint.x, controlPoint.y, 4 / scaleOffset.scaleX, 0, Math.PI * 2);
        ctx.fillStyle = '#000000';
        ctx.fill();
      };

      // Draw control points of the selected vertex
      if (selectedVertex.controlLeft) {
        drawControlPoint(vx, vy, selectedVertex.controlLeft);
      }
      if (selectedVertex.controlRight) {
        drawControlPoint(vx, vy, selectedVertex.controlRight);
      }

      // Draw adjacent control points
      const verticesCount = vertices.length;

      // Previous vertex
      const prevVertexIndex =
        artboardViewModel.state.selectedVertexIndex - 1 >= 0
          ? artboardViewModel.state.selectedVertexIndex - 1
          : path.closed
          ? verticesCount - 1
          : null;

      if (prevVertexIndex !== null) {
        const prevVertex = vertices[prevVertexIndex];
        if (prevVertex.controlRight) {
          drawControlPoint(prevVertex.base.x, prevVertex.base.y, prevVertex.controlRight);
        }
      }

      // Next vertex
      const nextVertexIndex =
        artboardViewModel.state.selectedVertexIndex + 1 < verticesCount
          ? artboardViewModel.state.selectedVertexIndex + 1
          : path.closed
          ? 0
          : null;

      if (nextVertexIndex !== null) {
        const nextVertex = vertices[nextVertexIndex];
        if (nextVertex.controlLeft) {
          drawControlPoint(nextVertex.base.x, nextVertex.base.y, nextVertex.controlLeft);
        }
      }
    }

    ctx.restore();
  }

  private getMousePos(event: NormalizedTouchEvent, context: ToolContext): { x: number; y: number } {
    const rect = context.canvas.getBoundingClientRect();
    const scaleOffset = context.artboardViewModel.state.scaleOffset;
    const artboardDimensions = context.artboardViewModel.state.boardDimensions;

    const point = ScaleOffset.screenToCanvasPoint(
      event.x,
      event.y,
      rect,
      scaleOffset,
      artboardDimensions,
    );

    return { x: point.x, y: point.y };
  }

  private findVertexAtPosition(
    x: number,
    y: number,
    artboardViewModel: ToolContext['artboardViewModel'],
  ): { objectIndex: number; vertexIndex: number } | null {
    const threshold = 10;

    const objects = artboardViewModel.getObjects();

    // Create an array of all vertices with their objectIndex and zIndex
    const allVertices: {
      objectIndex: number;
      vertexIndex: number;
      zIndex: number;
      vertex: Vertex;
      offset: Point;
    }[] = [];

    for (let objectIndex = 0; objectIndex < objects.length; objectIndex++) {
      const obj = objects[objectIndex];
      // TODO fix
      const layer = artboardViewModel.findLayerFromObject(obj);
      if (layer?.locked) continue;
      if (!artboardViewModel.isPath(obj)) continue;
      const path = obj as Path;
      const offset = path.scaleOffset;
      const zIndex = path.zIndex;

      for (let vertexIndex = 0; vertexIndex < path.vertices.length; vertexIndex++) {
        const vertex = path.vertices[vertexIndex];
        allVertices.push({
          objectIndex,
          vertexIndex,
          zIndex,
          vertex,
          offset: new Point(offset.x, offset.y),
        });
      }
    }

    // Sort vertices by zIndex in descending order
    allVertices.sort((a, b) => b.zIndex - a.zIndex);

    for (const item of allVertices) {
      const { vertex, offset } = item;
      const dx = x - (vertex.base.x + offset.x);
      const dy = y - (vertex.base.y + offset.y);
      const distance = Math.sqrt(dx * dx + dy * dy);
      if (distance <= threshold) {
        return { objectIndex: item.objectIndex, vertexIndex: item.vertexIndex };
      }
    }
    return null;
  }

  private getAllControlPoints(artboardViewModel: ToolContext['artboardViewModel']) {
    const controlPoints = [];
    const objects = artboardViewModel.getObjects();

    for (let objectIndex = 0; objectIndex < objects.length; objectIndex++) {
      const obj = objects[objectIndex];
      if (!artboardViewModel.isPath(obj)) continue;
      const path = obj as Path;
      const vertices = path.vertices;
      const offset = path.scaleOffset;

      for (let vertexIndex = 0; vertexIndex < vertices.length; vertexIndex++) {
        const vertex = vertices[vertexIndex];

        if (vertex.controlLeft) {
          controlPoints.push({
            type: 'controlLeft' as const,
            point: vertex.controlLeft,
            objectIndex,
            vertexIndex,
            zIndex: path.zIndex,
            offset,
          });
        }
        if (vertex.controlRight) {
          controlPoints.push({
            type: 'controlRight' as const,
            point: vertex.controlRight,
            objectIndex,
            vertexIndex,
            zIndex: path.zIndex,
            offset,
          });
        }
      }
    }

    return controlPoints;
  }

  private isPointOnPath(x: number, y: number, path: Path, offset: Point): boolean {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return false;

    ctx.beginPath();

    const vertices = path.vertices;
    if (vertices.length > 0) {
      const firstVertex = vertices[0];
      ctx.moveTo(firstVertex.base.x + offset.x, firstVertex.base.y + offset.y);

      for (let i = 1; i < vertices.length; i++) {
        const prevVertex = vertices[i - 1];
        const currVertex = vertices[i];

        const cp1 = prevVertex.controlRight;
        const cp2 = currVertex.controlLeft;

        if (cp1 && cp2) {
          ctx.bezierCurveTo(
            cp1.x + offset.x,
            cp1.y + offset.y,
            cp2.x + offset.x,
            cp2.y + offset.y,
            currVertex.base.x + offset.x,
            currVertex.base.y + offset.y,
          );
        } else {
          ctx.lineTo(currVertex.base.x + offset.x, currVertex.base.y + offset.y);
        }
      }

      // If the path is closed, add the closing segment
      if (path.closed) {
        const lastVertex = vertices[vertices.length - 1];
        const firstVertex = vertices[0];
        const cp1 = lastVertex.controlRight;
        const cp2 = firstVertex.controlLeft;

        if (cp1 && cp2) {
          ctx.bezierCurveTo(
            cp1.x + offset.x,
            cp1.y + offset.y,
            cp2.x + offset.x,
            cp2.y + offset.y,
            firstVertex.base.x + offset.x,
            firstVertex.base.y + offset.y,
          );
        } else {
          ctx.lineTo(firstVertex.base.x + offset.x, firstVertex.base.y + offset.y);
        }
      }
    }

    ctx.lineWidth = 10; // Increase line width for easier hit detection
    return ctx.isPointInStroke(x, y) || ctx.isPointInPath(x, y);
  }

  private findPathAtPosition(
    x: number,
    y: number,
    artboardViewModel: ToolContext['artboardViewModel'],
  ): number | null {
    const objects = artboardViewModel.getObjects();

    // Create an array of paths with their indices and zIndex
    const objectData = objects
      .map((obj, index) => {
        // TODO fix
        const layer = artboardViewModel.findLayerFromObject(obj);
        if (layer?.locked) return null;
        if (!artboardViewModel.isPath(obj)) return null;
        return {
          path: obj as Path,
          index,
          zIndex: obj.zIndex,
        };
      })
      .filter(Boolean) as { path: Path; index: number; zIndex: number }[];

    // Sort paths by zIndex in descending order
    objectData.sort((a, b) => b.zIndex - a.zIndex);

    for (const { path, index } of objectData) {
      if (this.isPointOnPath(x, y, path, new Point(path.scaleOffset.x, path.scaleOffset.y))) {
        return index;
      }
    }

    return null;
  }
}
