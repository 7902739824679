// LayersPanel.tsx

import React, { useEffect, useRef } from 'react';
import { ArtboardViewModel } from '../../viewmodel/ArtboardViewModel';
import { Path } from '../../lib/geometry/Path';
import { ImageObject } from '../../lib/object/ImageObject';
import { CapTypeUtils } from '../../lib/geometry/CapType';
import { CornerTypeUtils } from '../../lib/geometry/CornerType';
import VisibilityToggle from '../components/VisibilityToggle';
import { Layer, PrimitiveObjectLayer } from '../../lib/layer/Layer';

interface LayersPanelProps {
  artboardViewModel: ArtboardViewModel;
}

const PREVIEW_SIZE = 20;

const LayersPanel: React.FC<LayersPanelProps> = ({ artboardViewModel }) => {
  const layers = [...artboardViewModel.state.layers];

  const handleVisibilityChange = (layer: Layer, visible: boolean) => {
    artboardViewModel.updateLayerVisible(layer, visible);
  };

  const handleLockedChange = (layer: Layer, locked: boolean) => {
    artboardViewModel.updateLayerLocked(layer, locked);
  };

  // Function to flatten layers into a list of objects with their corresponding indices
  const flattenLayers = (layers: Layer[]) => {
    const result: { obj: any; index: number }[] = [];
    let currentIndex = 0;

    const traverseLayers = (layers: Layer[]) => {
      layers
        .slice()
        .reverse()
        .forEach((layer) => {
          const objects = layer.getObjects();
          objects.forEach((obj) => {
            result.push({ obj, index: currentIndex });
            currentIndex++;
          });
        });
    };

    traverseLayers(layers);
    return result;
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: '#535353',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        userSelect: 'none',
      }}
    >
      <span
        style={{
          color: 'white',
          userSelect: 'none',
          fontSize: '1rem',
          marginLeft: '8px',
          marginRight: '8px',
          marginTop: '8px',
          fontWeight: 'bold',
        }}
      >
        Layers
      </span>
      <div
        style={{
          margin: '10px',
          marginBottom: '2px',
          border: '2px solid #666666',
          boxSizing: 'border-box',
          flex: '1 1 0',
          overflowY: 'auto',
        }}
        onClick={() => artboardViewModel.updateLayerSelected(null, true)}
      >
        {layers.map((layer, index) => {
          const obj = layer.getObjects()[0];
          return (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid #666666',
                backgroundColor: layer.selected ? '#50667c' : 'transparent',
              }}
              onClick={(e) => {
                e.stopPropagation();
                artboardViewModel.updateLayerSelected(layer, !layer.selected);
              }}
            >
              <div style={{ borderRight: '2px solid #666666', padding: '2px' }}>
                {/* Visible Checkbox */}
                <VisibilityToggle
                  icon={'eye'}
                  alt={'Visible'}
                  visible={layer.visible}
                  handleVisibilityChange={(e) => {
                    handleVisibilityChange(layer, e);
                  }}
                />
              </div>

              {/* Locked Checkbox */}
              <div style={{ borderRight: '2px solid #666666', padding: '2px' }}>
                <VisibilityToggle
                  icon={'lock'}
                  alt={'Lock'}
                  visible={layer.locked}
                  handleVisibilityChange={(e) => {
                    handleLockedChange(layer, e);
                  }}
                />
              </div>

              {/* Preview Canvas */}
              <div
                style={{
                  width: `${PREVIEW_SIZE}px`,
                  height: `${PREVIEW_SIZE}px`,
                  marginRight: '8px',
                  marginLeft: '8px',
                  border: '2px solid #000',
                  backgroundColor: '#fff',
                  flexShrink: 0,
                }}
              >
                <PreviewCanvas obj={obj} />
              </div>

              {/* Object Type Label */}
              <div
                style={{
                  color: 'white',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  flexGrow: 1,
                  userSelect: 'none', // Make text non-selectable
                  fontSize: '0.75rem',
                }}
              >
                {layer.getName()}
              </div>

              {/* Selection widget */}
              <div
                style={{
                  height: `24px`,
                  padding: '2px',
                  display: 'flex',
                  alignItems: 'center',
                  boxSizing: 'border-box',
                  justifyContent: 'center',
                  borderLeft: '2px solid #666666',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  artboardViewModel.toggleSelectedForObjectsInLayer(layer);
                }}
              >
                <img
                  style={{ height: '100%', marginLeft: '4px', marginRight: '2px' }}
                  src={`img/icons/layer_${
                    layer.getObjects().every((item) => item.selected) ? '' : 'un'
                  }selected.svg`}
                />
              </div>
            </div>
          );
        })}
      </div>
      {/* Bottom action button row */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '24px',
          flexShrink: 0,
          boxSizing: 'border-box',
          padding: '4px',
        }}
      >
        <span style={{ color: '#d1d3d4', fontSize: '0.75rem' }}>{`${
          artboardViewModel.state.layers.length
        } Layer${artboardViewModel.state.layers.length === 1 ? '' : 's'}`}</span>
        <div style={{ flex: 1 }}></div>
        <img
          onClick={() => {
            const selectedObjects = artboardViewModel.getSelectedObjects();
            if (selectedObjects.length > 0) {
              const layer = artboardViewModel.findLayerFromObject(selectedObjects[0]);
              if (layer) {
                artboardViewModel.updateLayerSelected(layer, true);
              }
            }
          }}
          src="img/icons/search.svg"
          style={{
            height: '100%',
            marginRight: '12px',
            opacity: artboardViewModel.getSelectedObjects().length > 0 ? '1' : '0.4',
          }}
        />
        <img
          onClick={artboardViewModel.deleteSelectedLayers}
          src="img/icons/trash.svg"
          style={{
            height: '100%',
            marginRight: '12px',
            opacity: artboardViewModel.getSelectedLayers().length > 0 ? '1' : '0.4',
          }}
        />
      </div>
    </div>
  );
};

export default LayersPanel;

// PreviewCanvas Component
interface PreviewCanvasProps {
  obj: any; // The object can be a Path or an ImageObject
}

const PreviewCanvas: React.FC<PreviewCanvasProps> = ({ obj }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Clear the canvas
    ctx.clearRect(0, 0, PREVIEW_SIZE, PREVIEW_SIZE);

    // Determine the bounding box of the object
    let minX = Infinity,
      minY = Infinity,
      maxX = -Infinity,
      maxY = -Infinity;

    if ('vertices' in obj) {
      // It's a Path
      const path = obj as Path;
      const { vertices, scaleOffset } = path;

      vertices.forEach((vertex) => {
        const x = vertex.base.x + scaleOffset.x;
        const y = vertex.base.y + scaleOffset.y;

        minX = Math.min(minX, x);
        minY = Math.min(minY, y);
        maxX = Math.max(maxX, x);
        maxY = Math.max(maxY, y);

        if (vertex.controlLeft) {
          const clx = vertex.controlLeft.x + scaleOffset.x;
          const cly = vertex.controlLeft.y + scaleOffset.y;
          minX = Math.min(minX, clx);
          minY = Math.min(minY, cly);
          maxX = Math.max(maxX, clx);
          maxY = Math.max(maxY, cly);
        }

        if (vertex.controlRight) {
          const crx = vertex.controlRight.x + scaleOffset.x;
          const cry = vertex.controlRight.y + scaleOffset.y;
          minX = Math.min(minX, crx);
          minY = Math.min(minY, cry);
          maxX = Math.max(maxX, crx);
          maxY = Math.max(maxY, cry);
        }
      });

      // Calculate scale and translation to fit the path within the canvas
      const width = maxX - minX || 1;
      const height = maxY - minY || 1;
      const scale = Math.min(PREVIEW_SIZE / width, PREVIEW_SIZE / height);

      const translateX = -minX;
      const translateY = -minY;

      ctx.save();
      ctx.scale(scale, scale);
      ctx.translate(translateX, translateY);

      // Draw the path
      ctx.beginPath();
      const firstVertex = vertices[0];
      ctx.moveTo(firstVertex.base.x + path.scaleOffset.x, firstVertex.base.y + path.scaleOffset.y);

      for (let i = 1; i < vertices.length; i++) {
        const prevVertex = vertices[i - 1];
        const currVertex = vertices[i];

        const cp1 = prevVertex.controlRight || prevVertex.base;
        const cp2 = currVertex.controlLeft || currVertex.base;

        ctx.bezierCurveTo(
          cp1.x + path.scaleOffset.x,
          cp1.y + path.scaleOffset.y,
          cp2.x + path.scaleOffset.x,
          cp2.y + path.scaleOffset.y,
          currVertex.base.x + path.scaleOffset.x,
          currVertex.base.y + path.scaleOffset.y,
        );
      }

      if (path.closed) {
        const lastVertex = vertices[vertices.length - 1];
        const cp1 = lastVertex.controlRight || lastVertex.base;
        const cp2 = firstVertex.controlLeft || firstVertex.base;

        ctx.bezierCurveTo(
          cp1.x + path.scaleOffset.x,
          cp1.y + path.scaleOffset.y,
          cp2.x + path.scaleOffset.x,
          cp2.y + path.scaleOffset.y,
          firstVertex.base.x + path.scaleOffset.x,
          firstVertex.base.y + path.scaleOffset.y,
        );
        ctx.closePath();
      }

      ctx.fillStyle = path.fill || 'transparent';
      ctx.strokeStyle = path.strokeColor || '#000000';
      ctx.lineWidth = (path.strokeWidth || 1) / scale; // Adjust for scale
      ctx.lineCap = CapTypeUtils.toCanvasLineCap(path.cap);
      ctx.lineJoin = CornerTypeUtils.toCanvasLineJoin(path.corner);

      ctx.globalAlpha = path.opacity * path.fillOpacity;
      ctx.fill();
      ctx.globalAlpha = path.opacity * path.strokeOpacity;
      ctx.stroke();
      ctx.globalAlpha = 1;

      ctx.restore();
    } else if ('image' in obj) {
      // It's an ImageObject
      const imageObject = obj as ImageObject;
      const { image, scaleOffset } = imageObject;

      minX = scaleOffset.x;
      minY = scaleOffset.y;
      maxX = scaleOffset.x + image.width;
      maxY = scaleOffset.y + image.height;

      // Calculate scale and translation to fit the image within the canvas
      const width = maxX - minX || 1;
      const height = maxY - minY || 1;
      const scale = Math.min(PREVIEW_SIZE / width, PREVIEW_SIZE / height);

      const translateX = -minX;
      const translateY = -minY;

      ctx.save();
      ctx.scale(scale, scale);
      ctx.translate(translateX, translateY);

      // Draw the image
      ctx.globalAlpha = imageObject.opacity;
      ctx.drawImage(image, scaleOffset.x, scaleOffset.y);
      ctx.globalAlpha = 1;

      ctx.restore();
    }
  }, [obj]);

  return <canvas ref={canvasRef} width={PREVIEW_SIZE} height={PREVIEW_SIZE} />;
};
