// IconButton.tsx

import React from 'react';

interface ToolboxIconButtonProps {
  onClick: () => void;
  selected: boolean;
  iconSrc: string;
  altText?: string;
  marginBottom?: string;
  marginRight?: string;
  padding?: string;
}

const ToolboxIconButton: React.FC<ToolboxIconButtonProps> = ({
  onClick,
  selected,
  iconSrc,
  altText,
  marginBottom,
  marginRight,
  padding,
}) => (
  <button
    onClick={onClick}
    style={{
      width: '32px',
      height: '32px',
      marginBottom: marginBottom ?? '0px',
      marginRight: marginRight ?? '0px',
      backgroundColor: selected ? '#303030' : '#535353',
      color: 'white',
      cursor: 'pointer',
      border: 'none', // Remove border
      boxShadow: 'none', // Remove shadow
      padding: padding ?? '4px', // Remove padding for a flat appearance
      outline: 'none', // Remove focus outline
      display: 'flex', // Align image centrally
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
    }}
  >
    <img src={iconSrc} style={{ height: '100%' }} alt={altText} />
  </button>
);

export default ToolboxIconButton;
