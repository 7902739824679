// PenTool.ts

import { Tool, ToolContext } from './Tool';
import { Point } from '../math/Point';
import { Vertex } from '../math/Vertex';
import { Path, PathUtils } from '../geometry/Path';
import { ScaleOffset } from '../math/ScaleOffset';
import { NormalizedTouchEvent } from '../../ui/input/MouseTouch';

type DraggingState = null | {
  type: 'vertex' | 'closing';
  vertexIndex: number;
  initialMousePos: Point;
  initialVertex: Vertex;
  hasMoved: boolean;
};

export class PenTool implements Tool {
  private draggingState: DraggingState = null;
  private readonly dragThreshold = 5; // Minimum drag distance in pixels before adding control points
  private currentMousePos: Point | null = null; // Current mouse position for projected path

  onMouseDown(event: NormalizedTouchEvent, context: ToolContext): void {
    const { x, y } = this.getMousePos(event, context);
    const { artboardViewModel, currentPath, setCurrentPath } = context;

    let path = currentPath;

    if (!path) {
      path = PathUtils.createInstance([], artboardViewModel.state.layers.length);
      path.strokeColor = context.artboardViewModel.getStrokeColorHex();
      path.fill = context.artboardViewModel.getFillColorHex();
    }

    // Check if clicking on the first vertex to close the path
    if (path.vertices.length > 2) {
      const firstVertex = path.vertices[0];
      const dx = x - firstVertex.base.x;
      const dy = y - firstVertex.base.y;
      const distance = Math.sqrt(dx * dx + dy * dy);

      if (distance <= 10) {
        // Close the path
        path.closed = true;

        // Start dragging to adjust the control points of the start vertex
        const firstVertexIndex = 0;
        const firstVertexCopy = path.vertices[firstVertexIndex];

        this.draggingState = {
          type: 'closing',
          vertexIndex: firstVertexIndex,
          initialMousePos: new Point(x, y),
          initialVertex: firstVertexCopy,
          hasMoved: false,
        };

        setCurrentPath!(path);
        return;
      }
    }

    // Add a new vertex
    const newVertex = new Vertex(new Point(x, y), null, null, true);
    const newVertices = [...path.vertices, newVertex];
    const newPath: Path = {
      ...path,
      vertices: newVertices,
    };

    setCurrentPath!(newPath);

    this.draggingState = {
      type: 'vertex',
      vertexIndex: newVertices.length - 1,
      initialMousePos: new Point(x, y),
      initialVertex: newVertex,
      hasMoved: false,
    };
  }

  onMouseMove(event: NormalizedTouchEvent, context: ToolContext): boolean {
    const { x, y } = this.getMousePos(event, context);
    const { currentPath, setCurrentPath } = context;

    // Update current mouse position
    this.currentMousePos = new Point(x, y);

    if (this.draggingState && currentPath) {
      const { type, vertexIndex, initialMousePos, initialVertex } = this.draggingState;
      const dx = x - initialMousePos.x;
      const dy = y - initialMousePos.y;
      const distanceSquared = dx * dx + dy * dy;

      // Check if the mouse has moved beyond the drag threshold
      if (
        !this.draggingState.hasMoved &&
        distanceSquared > this.dragThreshold * this.dragThreshold
      ) {
        this.draggingState.hasMoved = true;
      }

      if (this.draggingState.hasMoved) {
        if (type === 'vertex') {
          // Dragging from a new vertex to set control handles
          const controlPoint = new Point(initialVertex.base.x + dx, initialVertex.base.y + dy);

          // Set controlLeft and controlRight symmetric about the base point
          const controlLeft = new Point(initialVertex.base.x - dx, initialVertex.base.y - dy);
          const controlRight = controlPoint;

          const updatedVertex = new Vertex(initialVertex.base, controlLeft, controlRight, true);

          const newVertices = [...currentPath.vertices];
          newVertices[vertexIndex] = updatedVertex;

          const newPath = {
            ...currentPath,
            vertices: newVertices,
          };

          setCurrentPath!(newPath);
        } else if (type === 'closing') {
          // Adjusting the control points of the first vertex when closing the path
          const firstVertexIndex = vertexIndex;
          const firstVertex = initialVertex;

          const controlLeft = new Point(firstVertex.base.x - dx, firstVertex.base.y - dy);
          const controlRight = new Point(firstVertex.base.x + dx, firstVertex.base.y + dy);

          const updatedVertex = new Vertex(firstVertex.base, controlLeft, controlRight, true);

          const newVertices = [...currentPath.vertices];
          newVertices[firstVertexIndex] = updatedVertex;

          const newPath = {
            ...currentPath,
            vertices: newVertices,
          };

          setCurrentPath!(newPath);
        }
      }
    }

    // Return true to indicate that the canvas needs to rerender
    return true;
  }

  onMouseUp(event: NormalizedTouchEvent, context: ToolContext): void {
    if (this.draggingState) {
      const { hasMoved, type } = this.draggingState;

      if (type === 'closing') {
        // Finalize the path when closing
        this.finalizePath(context);
      } else if (!hasMoved && type === 'vertex') {
        // If the mouse didn't move beyond the threshold, ensure control points remain null
        const { currentPath, setCurrentPath } = context;
        if (currentPath) {
          const { vertexIndex } = this.draggingState;
          const vertex = currentPath.vertices[vertexIndex];

          // Ensure control points are null
          const updatedVertex = new Vertex(vertex.base, null, null, true);

          const newVertices = [...currentPath.vertices];
          newVertices[vertexIndex] = updatedVertex;

          const newPath = {
            ...currentPath,
            vertices: newVertices,
          };

          setCurrentPath!(newPath);
        }
      }
    }

    this.draggingState = null;
  }

  drawOverlay(context: ToolContext, ctx: CanvasRenderingContext2D): void {
    const { canvas, artboardViewModel, currentPath } = context;

    if (!currentPath) return;

    // Get scale and translation from artboardViewModel
    const scaleOffset = artboardViewModel.state.scaleOffset;
    const artboardDimensions = artboardViewModel.state.boardDimensions;

    // Apply the same transformations as in MainCanvas
    const rect = canvas.getBoundingClientRect();

    ctx.save();

    // Apply pan and zoom transformations
    ctx.translate(scaleOffset.x, scaleOffset.y);
    ctx.scale(scaleOffset.scaleX, scaleOffset.scaleX);

    // Center the artboard within the canvas
    const xOffset = (rect.width / scaleOffset.scaleX - artboardDimensions.w) / 2;
    const yOffset = (rect.height / scaleOffset.scaleX - artboardDimensions.h) / 2;

    ctx.translate(xOffset, yOffset);

    const path = currentPath;
    const vertices = path.vertices;

    // Draw the constructing path
    if (vertices.length > 0) {
      ctx.beginPath();
      const firstVertex = vertices[0];
      ctx.moveTo(firstVertex.base.x, firstVertex.base.y);

      for (let i = 1; i < vertices.length; i++) {
        const prevVertex = vertices[i - 1];
        const currVertex = vertices[i];

        // Use base point if control point is null
        const cp1 = prevVertex.controlRight || prevVertex.base;
        const cp2 = currVertex.controlLeft || currVertex.base;

        // Always use bezierCurveTo
        ctx.bezierCurveTo(cp1.x, cp1.y, cp2.x, cp2.y, currVertex.base.x, currVertex.base.y);
      }

      // If the path is closed, draw a curve back to the first vertex
      if (path.closed) {
        const lastVertex = vertices[vertices.length - 1];
        const cp1 = lastVertex.controlRight || lastVertex.base;
        const cp2 = firstVertex.controlLeft || firstVertex.base;

        ctx.bezierCurveTo(cp1.x, cp1.y, cp2.x, cp2.y, firstVertex.base.x, firstVertex.base.y);
      } else if (this.currentMousePos && !this.draggingState) {
        // Draw projected path to current mouse position
        const lastVertex = vertices[vertices.length - 1];

        // Use base point if control point is null
        const cp1 = lastVertex.controlRight || lastVertex.base;

        // For the projected point, assume control points are at the mouse position
        const projectedCP = this.currentMousePos;

        ctx.bezierCurveTo(
          cp1.x,
          cp1.y,
          projectedCP.x,
          projectedCP.y,
          this.currentMousePos.x,
          this.currentMousePos.y,
        );
      }

      // Set the stroke style to dark blue
      ctx.strokeStyle = '#1E90FF'; // Dark blue color
      ctx.lineWidth = 1 / scaleOffset.scaleX; // Adjust line width based on scale
      ctx.stroke();
    }

    // Draw control handles and control points
    ctx.strokeStyle = '#1E90FF'; // Dodger blue for handles
    ctx.fillStyle = '#1E90FF';

    vertices.forEach((vertex) => {
      const base = vertex.base;
      const controlLeft = vertex.controlLeft || base;
      const controlRight = vertex.controlRight || base;

      // Draw lines from base to control points if they differ
      if (controlLeft !== base && (controlLeft.x !== base.x || controlLeft.y !== base.y)) {
        ctx.beginPath();
        ctx.moveTo(base.x, base.y);
        ctx.lineTo(controlLeft.x, controlLeft.y);
        ctx.lineWidth = 1 / scaleOffset.scaleX;
        ctx.stroke();

        // Draw control left point
        ctx.beginPath();
        ctx.arc(controlLeft.x, controlLeft.y, 3 / scaleOffset.scaleX, 0, Math.PI * 2);
        ctx.fill();
      }

      if (controlRight !== base && (controlRight.x !== base.x || controlRight.y !== base.y)) {
        ctx.beginPath();
        ctx.moveTo(base.x, base.y);
        ctx.lineTo(controlRight.x, controlRight.y);
        ctx.lineWidth = 1 / scaleOffset.scaleX;
        ctx.stroke();

        // Draw control right point
        ctx.beginPath();
        ctx.arc(controlRight.x, controlRight.y, 3 / scaleOffset.scaleX, 0, Math.PI * 2);
        ctx.fill();
      }
    });

    // Draw vertices (anchor points)
    vertices.forEach((vertex) => {
      ctx.beginPath();
      ctx.arc(vertex.base.x, vertex.base.y, 4 / scaleOffset.scaleX, 0, Math.PI * 2);
      ctx.fillStyle = '#FFFFFF'; // White fill for anchor points
      ctx.fill();
      ctx.strokeStyle = '#1E90FF';
      ctx.lineWidth = 1 / scaleOffset.scaleX;
      ctx.stroke();
    });

    ctx.restore();
  }

  private finalizePath(context: ToolContext) {
    const { currentPath, artboardViewModel, setCurrentPath } = context;
    if (currentPath) {
      currentPath.strokeColor = context.artboardViewModel.getStrokeColorHex();
      currentPath.fill = context.artboardViewModel.getFillColorHex();
      currentPath.selected = true;
      artboardViewModel.addObject(currentPath);
      setCurrentPath!(null);
      this.currentMousePos = null; // Reset current mouse position
    }
  }

  private getMousePos(event: NormalizedTouchEvent, context: ToolContext): { x: number; y: number } {
    const rect = context.canvas.getBoundingClientRect();
    const scaleOffset = context.artboardViewModel.state.scaleOffset;
    const artboardDimensions = context.artboardViewModel.state.boardDimensions;

    const point = ScaleOffset.screenToCanvasPoint(
      event.x,
      event.y,
      rect,
      scaleOffset,
      artboardDimensions,
    );

    return { x: point.x, y: point.y };
  }

  // Handle tool switch to finalize the path
  public finalize(context: ToolContext) {
    this.finalizePath(context);
  }
}
