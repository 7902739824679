// App.tsx

import React, { useState } from 'react';
import Editor from './ui/Editor';
import SplashScreen from './ui/SplashScreen';
import { Dimensions } from './lib/math/Dimensions';
import { BuildConfig } from './util/BuildConfig';

const MAJOR = 0;
const MINOR = 2;
const PATCH = 0;

const version = () => {
  return `${MAJOR}.${MINOR}.${PATCH}`;
};

const App = () => {
  const [showingSplash, setShowingSplash] = useState(BuildConfig.isProduction());
  const [initialDimensions, setInitialDimensions] = useState<Dimensions | null>(null);

  return showingSplash ? (
    <SplashScreen
      version={version()}
      onCreate={(dimensions) => {
        setInitialDimensions(dimensions);
        setShowingSplash(false);
      }}
    />
  ) : (
    <Editor initialDimensions={initialDimensions} />
  );
};

export default App;
