import chroma from 'chroma-js';

export interface ColorValues {
  H: number;
  S: number; // Between 0 and 1
  B: number; // Between 0 and 1
  R: number;
  G: number;
  B2: number; // Using B2 to distinguish from Brightness 'B'
  C: number;
  M: number;
  Y: number;
  K: number;
  hex: string;
}

export class ColorValueUtil {
  static BLACK = {
    H: 0,
    S: 0,
    B: 0,
    R: 0,
    G: 0,
    B2: 0,
    C: 0,
    M: 0,
    Y: 0,
    K: 100,
    hex: '#000000',
  } as ColorValues;

  static WHITE = {
    H: 0,
    S: 0,
    B: 1,
    R: 255,
    G: 255,
    B2: 255,
    C: 0,
    M: 0,
    Y: 0,
    K: 0,
    hex: '#FFFFFF',
  } as ColorValues;

  static fromHex(newHex: string): ColorValues {
    const color = chroma(newHex);
    const rgb = color.rgb();
    const hsv = color.hsv();
    const cmyk = ColorValueUtil.rgbToCmyk(rgb[0], rgb[1], rgb[2]);
    return {
      H: hsv[0],
      S: hsv[1],
      B: hsv[2],
      R: rgb[0],
      G: rgb[1],
      B2: rgb[2],
      C: cmyk.C,
      M: cmyk.M,
      Y: cmyk.Y,
      K: cmyk.K,
      hex: newHex,
    } as ColorValues;
  }

  // RGB to CMYK conversion function
  static rgbToCmyk = (r: number, g: number, b: number) => {
    let c = 0,
      m = 0,
      y = 0,
      k = 0;

    const rPrime = r / 255;
    const gPrime = g / 255;
    const bPrime = b / 255;

    k = 1 - Math.max(rPrime, gPrime, bPrime);

    if (k < 1) {
      c = (1 - rPrime - k) / (1 - k);
      m = (1 - gPrime - k) / (1 - k);
      y = (1 - bPrime - k) / (1 - k);
    } else {
      c = 0;
      m = 0;
      y = 0;
    }

    return {
      C: Math.round(c * 100),
      M: Math.round(m * 100),
      Y: Math.round(y * 100),
      K: Math.round(k * 100),
    };
  };
}
