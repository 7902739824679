import { Dimensions } from './Dimensions';
import { Point } from './Point';

export class ScaleOffset {
  constructor(
    public readonly x: number = 0,
    public readonly y: number = 0,
    public readonly scaleX: number = 1,
    public readonly scaleY: number = 1,
    public readonly rotation: number = 0,
  ) {}

  public static screenToCanvasPoint(
    screenX: number,
    screenY: number,
    canvasRect: DOMRect,
    scaleOffset: ScaleOffset,
    artboardDimensions: Dimensions,
  ): Point {
    // Adjust for canvas position in the page
    const canvasX = screenX - canvasRect.left;
    const canvasY = screenY - canvasRect.top;

    // Undo the initial translation (translateX, translateY)
    let x = canvasX - scaleOffset.x;
    let y = canvasY - scaleOffset.y;

    // Undo the scaling
    x /= scaleOffset.scaleX;
    y /= scaleOffset.scaleY;

    // Undo the translation (xOffset, yOffset)
    const xOffset = (canvasRect.width / scaleOffset.scaleX - artboardDimensions.w) / 2;
    const yOffset = (canvasRect.height / scaleOffset.scaleY - artboardDimensions.h) / 2;

    x -= xOffset;
    y -= yOffset;

    return new Point(x, y);
  }
}
