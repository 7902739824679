// Layer.ts

import { ArtboardObject } from '../ArtboardObject';
import { Path } from '../geometry/Path';

export interface Layer {
  getName(): string;
  getObjects(): ArtboardObject[];
  updateObjects(updater: (obj: ArtboardObject) => ArtboardObject): Layer;
  selected: boolean;
  setSelected(selected: boolean): Layer;
  visible: boolean;
  setVisible(visible: boolean): Layer;
  locked: boolean;
  setLocked(locked: boolean): Layer;
}

export class PrimitiveObjectLayer implements Layer {
  private obj: ArtboardObject;
  private _selected: boolean;
  private _visible: boolean;
  private _locked: boolean;

  constructor(object: ArtboardObject, selected: boolean, visible: boolean, locked: boolean) {
    this.obj = object;
    this._selected = selected;
    this._visible = visible;
    this._locked = locked;
  }

  get selected(): boolean {
    return this._selected;
  }

  get visible(): boolean {
    return this._visible;
  }

  get locked(): boolean {
    return this._locked;
  }

  getName(): string {
    const isPath = (this.obj as Path).vertices !== undefined;
    return isPath ? '<Path>' : '<Image>';
  }

  getObjects(): ArtboardObject[] {
    return [this.obj];
  }

  setSelected(selected: boolean): Layer {
    this._selected = selected;
    return this.clone();
  }

  setVisible(visible: boolean): Layer {
    this._visible = visible;
    return this.clone();
  }

  setLocked(locked: boolean): Layer {
    this._locked = locked;
    return this.clone();
  }

  updateObjects(updater: (obj: ArtboardObject) => ArtboardObject): PrimitiveObjectLayer {
    const updatedObj = updater(this.obj);
    return new PrimitiveObjectLayer(updatedObj, this.selected, this.visible, this.locked);
  }

  clone() {
    return new PrimitiveObjectLayer(this.obj, this.selected, this.visible, this.locked);
  }
}

export class GroupLayer implements Layer {
  private layers: Layer[];
  private _selected: boolean;
  private _visible: boolean;
  private _locked: boolean;

  constructor(layers: Layer[], selected: boolean, visible: boolean, locked: boolean) {
    this.layers = layers;
    this._selected = selected;
    this._visible = visible;
    this._locked = locked;
  }

  get selected(): boolean {
    return this._selected;
  }

  get visible(): boolean {
    return this._visible;
  }

  get locked(): boolean {
    return this._locked;
  }

  setSelected(selected: boolean): Layer {
    this._selected = selected;
    return this.clone();
  }

  setVisible(visible: boolean): Layer {
    this._visible = visible;
    return this.clone();
  }

  setLocked(locked: boolean): Layer {
    this._locked = locked;
    return this.clone();
  }

  getName(): string {
    return 'Group';
  }

  getLayers(): Layer[] {
    return this.layers;
  }

  getObjects(): ArtboardObject[] {
    return this.layers.flatMap((layer) => layer.getObjects());
  }

  updateObjects(updater: (obj: ArtboardObject) => ArtboardObject): GroupLayer {
    const updatedLayers = this.layers.map((layer) => layer.updateObjects(updater));
    return new GroupLayer(updatedLayers, this.selected, this.visible, this.locked);
  }

  clone() {
    return new GroupLayer(this.layers, this.selected, this.visible, this.locked);
  }
}
